<template>
  <section>
    <v-container>
      <v-row>
        <div class="wrapper">
          <v-img src="@/assets/logo-header.png" class="logo"></v-img>
          <h3>Selamat Datang</h3>
          <p class="prompt">Masukkan akun anda untuk melanjutkan.</p>
          <div class="input-contain">
            <p>Email</p>
            <v-text-field
              class="input-template"
              v-model="email"
              placeholder="Email"
              density="compact"
              variant="outlined"
              base-color="#818A91"
              bg-color="#fff"
              color="#363636"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
          </div>
          <div class="input-contain">
            <p>Password</p>
            <v-text-field
              class="input-template"
              placeholder="Password"
              density="compact"
              variant="outlined"
              base-color="#818A91"
              bg-color="#fff"
              color="#363636"
              :append-inner-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show4 ? 'text' : 'password'"
              v-model="password"
              @click:append-inner="show4 = !show4"
              :rules="[rules.required, rules.min]"
            ></v-text-field>
          </div>

          <p v-if="errorField" class="err-msg">{{ errorField.message }}</p>
          <v-btn class="login-btn" variant="flat" block @click="login()"
            >Masuk</v-btn
          >
        </div>
      </v-row>
    </v-container>
  </section>

  <div
    class="warning"
    v-if="loginClicked && (isEmpty(email) || isEmpty(password))"
  >
    <div class="wrapper">
      <v-img
        class="close-btn"
        src="@/assets/close-btn.png"
        @click="loginClicked = false"
      ></v-img>
      <v-img class="warning-icon" src="@/assets/warning.png"></v-img>
      <h4>Isi Form</h4>
      <p class="prompt">
        Silahkan melakukan pengisian form activity untuk<br />mendapatkan akses
        dashboard admin
      </p>
      <v-btn class="back" variant="flat" @click="loginClicked = false"
        >Lanjutkan untuk isi form</v-btn
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    email: "",
    password: "",
    show4: false,
    loginClicked: false,
    errorField: null,
    rules: {
      required: (value) => !!value || "Field is required",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
  }),
  methods: {
    handleLogin() {
      this.loginClicked = true; // Set loginClicked to true when the button is clicked
      // Add any other login logic here
    },
    isEmpty(value) {
      return value.trim() === ""; // Helper function to check if a value is empty
    },
    async login() {
      try {
        if (!this.email || !this.password) {
          // Display an error message for empty fields
          this.errorField = { message: "Please enter both email and password" };
          this.loginClicked = true;
          return;
        }

        const res = await axios.post(`${this.$api}/auth/login`, {
          email: this.email,
          password: this.password,
        });

        if (res.status === 200) {
          localStorage.setItem("data", JSON.stringify(res.data));
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${res.data.token}`;
          this.$router.push({ name: "properties" });
        }
      } catch (error) {
        console.log(error.response.data);
        const errorField = error.response.data;
        this.errorField = errorField;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .wrapper {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .err-msg {
      font-size: 14px;
      color: red;
    }

    .logo {
      width: 110px;
      margin-bottom: 4rem;
    }

    h3 {
      color: $font-color;
      text-align: center;
      font-weight: $font-weight-semibold;
      font-size: 35px;
    }

    .prompt {
      font-size: 20px;
      color: $font-color;
      text-align: center;
      margin-bottom: 3rem;
    }

    .input-contain {
      width: 100%;
      p {
        margin-bottom: 5px;
        font-weight: $font-weight-medium;
      }
    }

    .login-btn {
      background-color: $button-color;
      text-transform: capitalize;
      margin-top: 15px;
    }
  }
}

.warning {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;

  .wrapper {
    background-color: $white;
    padding: 40px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .prompt {
      font-size: 20px;
      color: $font-color;
      text-align: center;
      margin-bottom: 3rem;
    }

    .close-btn {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    .warning-icon {
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
    }

    h4 {
      font-weight: $font-weight-bold;
      color: $primary-color;
    }

    .prompt {
      font-size: 14px;
      margin-bottom: 30px;
    }

    .back {
      background-color: $primary-color;
      text-transform: unset;
      font-size: 14px;
      padding: 0 30px;
    }
  }
}
</style>
