<template>
  <v-app>
    <router-view />
    <CustFooter />
  </v-app>
</template>

<script>
import CustFooter from "../component/custFooter.vue";

export default {
  components: {
    CustFooter,
  },
};
</script>

<style></style>
