<template>
  <section class="company">
    <div class="top-section">
      <div class="above">
        <h4>Akun Saya</h4>
        <profile />
      </div>
    </div>

    <div class="user-data">
      <div class="user-picture">
        <div class="img-bg">
          <v-img
            src="@/assets/user-big.png"
            class="user-photo"
            cover
            v-if="userDetail.image_url == 'default/profile.png'"
          ></v-img>
          <v-img
            :src="`${this.$apiImg}${userDetail.image_url}`"
            class="user-photo"
            cover
            v-else
          ></v-img>
        </div>
        <v-img
          src="@/assets/camera.png"
          class="edit-photo"
          contain
          @click="popUpProfile = true"
        ></v-img>
      </div>
      <div class="user-all-data">
        <div class="name-role">
          <p class="name">{{ userDetail.name }}</p>
          <p class="role">{{ roles }}</p>
        </div>
        <div class="agent-prop">
          <div class="prop-data">
            <p class="title">Jumlah Listing</p>
            <p class="amount">{{ userListings.listing }}</p>
          </div>
          <div class="prop-data">
            <p class="title">Dijual</p>
            <p class="amount">{{ userListings.dijual }}</p>
          </div>
          <div class="prop-data">
            <p class="title">Disewakan</p>
            <p class="amount">{{ userListings.disewa }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper">
      <div class="input-contain">
        <p>Nama</p>
        <v-text-field
          class="input-template"
          v-model="name"
          placeholder="Nama"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          :disabled="disableUser"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>Email</p>
        <v-text-field
          class="input-template"
          v-model="email"
          placeholder="Email"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          :rules="[rules.required, rules.email]"
          :disabled="disableUser"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>No Whatsapp</p>
        <v-text-field
          class="input-template"
          v-model="phoneNumber"
          placeholder="08XXXXXXXXXX"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          :disabled="disableUser"
        ></v-text-field>
      </div>
      <div class="buttons">
        <v-btn
          class="save-button"
          variant="flat"
          prepend-icon="mdi-pencil"
          @click="editAcc"
          v-if="!editUser"
          >Edit</v-btn
        >
        <div class="btn-onedit" v-else>
          <div class="cancel" @click="editAcc">Cancel</div>
          <v-btn
            class="save-button"
            variant="flat"
            prepend-icon="mdi-content-save"
            @click="updateProfile"
            >Simpan</v-btn
          >
        </div>
      </div>
    </div>

    <!-- <div class="wrapper">
      <div class="input-contain">
        <p>Password Lama</p>
        <v-text-field
          class="input-template"
          placeholder="Password lama"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          :append-inner-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show5 ? 'text' : 'password'"
          v-model="passwordOld"
          @click:append-inner="show4 = !show4"
          :rules="[rules.required, rules.min]"
          :disabled="disablePass"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>Password Baru</p>
        <v-text-field
          class="input-template"
          placeholder="Password baru"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          :append-inner-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show4 ? 'text' : 'password'"
          v-model="password"
          @click:append-inner="show4 = !show4"
          :rules="[rules.required, rules.min]"
          :disabled="disablePass"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>Konfirmasi Password</p>
        <v-text-field
          :append-inner-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show3 ? 'text' : 'password'"
          v-model="confirmPassword"
          @click:append-inner="show3 = !show3"
          class="input-template"
          placeholder="Konfirmasi password"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          :rules="[rules.required, matchingPasswords]"
          :disabled="disablePass"
        ></v-text-field>
      </div>

      <div class="buttons">
        <v-btn
          class="save-button"
          variant="flat"
          prepend-icon="mdi-pencil"
          @click="editPass"
          v-if="!editPw"
          >Edit</v-btn
        >
        <div class="btn-onedit" v-else>
          <div class="cancel" @click="editPass">Cancel</div>
          <v-btn
            class="save-button"
            variant="flat"
            prepend-icon="mdi-content-save"
            >Simpan</v-btn
          >
        </div>
      </div>
    </div> -->
  </section>
  <section class="pop-out-profile" v-if="popUpProfile">
    <div class="contain">
      <v-img
        src="@/assets/close-btn.png"
        class="close-btn"
        min-width="25px"
        min-height="25px"
        width="25px"
        height="25px"
        contain
        @click="popUpProfile = false"
      ></v-img>

      <v-img
        src="@/assets/user-big.png"
        class="user-photo"
        contain
        v-if="imgToUp == 'default/profile.png'"
      ></v-img>
      <v-img :src="imgToUp" class="user-photo" cover v-else></v-img>

      <v-file-input
        v-model="file"
        accept="image/*"
        ref="fileInput"
        style="display: none"
        @change="handleFileChange"
      ></v-file-input>

      <div class="choose-img" @click="triggerFileInput">
        <p>Choose File</p>
      </div>
      <p class="warning">JPG or PNG only.</p>

      <div class="upload-img" @click="updateProfile">
        <p v-if="!loading">Save</p>
        <v-progress-circular indeterminate v-else></v-progress-circular>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import profile from "@/components/component/ProfileNav.vue";

export default {
  data: () => ({
    menuNav: false,
    name: "",
    email: "",
    phoneNumber: "",
    show4: false,
    show3: false,
    show5: false,
    passwordOld: "",
    password: "",
    confirmPassword: "",
    roles: "",
    disableUser: true,
    disablePass: true,
    editPw: false,
    editUser: false,
    rules: {
      required: (value) => !!value || "Field is required",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
    confirmLogout: false,
    userDetail: {},
    userListings: {},
    login: false,
    imgToUp: null,
    file: null,
    popUpProfile: false,
    loading: false,
    selected: null,
  }),
  methods: {
    toggleMenu() {
      this.menuNav = !this.menuNav;
    },
    matchingPasswords: function () {
      if (this.password === this.confirmPassword) {
        return true;
      } else {
        return "Password tidak cocok";
      }
    },
    editPass() {
      this.disablePass = !this.disablePass;
      this.editPw = !this.editUser;
    },
    editAcc() {
      this.disableUser = !this.disableUser;
      this.editUser = !this.editUser;
    },
    toggleLogout() {
      this.confirmLogout = !this.confirmLogout;
    },
    logout() {
      console.log("Logging out");
      localStorage.clear();

      const isDataCleared = localStorage.getItem("data") === null;

      if (isDataCleared) {
        console.log("Local Storage Cleared");
        this.$router.push({ name: "login" });
        console.log("Router navigated to login");
      } else {
        console.log("Unable to clear local storage");
      }
    },
    async getUserDetail() {
      // this.loading = true;
      try {
        const res = await axios.get(`${this.$api}/profile/detail`);
        if (res.status === 200 || res.status === 201) {
          this.userDetail = res.data.data;
          this.imgToUp = this.userDetail.image_url;
          this.userListings = res.data;
          this.name = this.userDetail.name;
          this.email = this.userDetail.email;
          this.phoneNumber = this.userDetail.phone_number;
          // this.password = this.userDetail.name;
          // this.name = this.userDetail.name;
          this.roles = res.data.data.roles[0].name;
          this.loading = false;
          console.log(res.data.data.roles[0].name);
          console.log(this.userDetail);
          console.log(this.userListings);
        }
      } catch (error) {
        console.log(error);
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        try {
          this.imgToUp = URL.createObjectURL(file);
          console.log(file);
        } catch (e) {
          console.error("Error creating object URL:", e);
        }
      }
    },
    async updateProfile() {
      try {
        // this.loading = true;

        const formData = new FormData();
        formData.append("name", this.name);
        if (this.email != this.userDetail.email) {
          formData.append("email", this.email);
        }
        if (this.phoneNumber != this.userDetail.phone_number) {
          formData.append("phone_number", this.phoneNumber);
        }
        if (this.file != null) {
          formData.append("profile_image", this.file[0]);
        }

        const res = await axios.post(`${this.$api}/profile/update`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log(res);
        if (res.status == 201 || res.status == 200) {
          this.popUpProfile = false;
          this.getUserDetail();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false; // Set loading to false whether the request is successful or not
      }
    },
  },
  components: {
    profile,
  },
  async mounted() {
    await this.getUserDetail();
  },
};
</script>

<style lang="scss" scoped>
section {
  &.company {
    width: 80%;
    max-height: 100vh;
    padding: 30px;
    overflow: scroll;
    background-color: $anti-flash;

    .top-section {
      .above {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.2rem;
        align-items: center;
        padding-bottom: 1.2rem;
        border-bottom: 2px solid $roman-silver;

        .profile {
          position: relative;
          .preview {
            display: flex;
            gap: 10px;
            align-items: center;
            position: relative;
            cursor: pointer;

            .name {
              font-size: 14px;
              font-weight: $font-weight-semibold;
              margin-bottom: 5px;
            }
          }

          .role {
            font-size: 12px;
            color: $roman-silver;
          }

          .collapsible-menu {
            border-radius: 5px;
            border: 1.5px solid $gainsboro;
            background-color: $white;
            position: absolute;
            top: 60px;
            width: 150%;
            z-index: 999;
            right: 0;

            .menu {
              display: flex;
              padding: 15px 25px;
              align-items: center;
              gap: 15px;
              text-decoration: none;
              cursor: pointer;

              .menu-icon {
                max-width: 16px;
                min-width: 16px;
                min-height: 16px;
                max-height: 16px;
              }

              p {
                color: $roman-silver;
                font-size: 14px;
              }

              &:hover {
                background-color: #ffefd1;
              }
            }
          }
        }
      }
      h4 {
        width: fit-content;
        font-weight: $font-weight-semibold;
      }
    }

    .user-data {
      display: flex;
      margin-top: 3rem;
      align-items: center;
      .user-picture {
        min-width: 20%;
        position: relative;
        display: flex;

        .img-bg {
          background-color: $white;
          border-radius: 300px;
          .user-photo {
            border-radius: 300px;
            min-height: 155px;
            min-width: 155px;
            height: 155px;
            width: 155px;
            z-index: 1;
          }
        }
        .edit-photo {
          max-width: 20%;
          width: 20%;
          position: absolute;
          z-index: 2;
          left: 120px;
          bottom: 4%;
          cursor: pointer;
        }
      }
      .user-all-data {
        display: flex;
        width: 80%;
        flex-direction: column;

        .name-role {
          display: flex;
          gap: 1.5rem;
          align-items: center;

          .name {
            font-size: 1.6rem;
            font-weight: $font-weight-semibold;
          }

          .role {
            font-size: 14px;
            color: #1580ff;
            padding: 2px 15px;
            background-color: $white;
            border-radius: 3px;
          }
        }

        .agent-prop {
          display: flex;
          gap: 30px;
          margin-top: 30px;

          .prop-data {
            min-width: 15%;
            padding: 20px 50px 20px 23px;
            background-color: $papaya-whip;
            border-left: 12px solid $button-color;
            border-radius: 3px 3px 3px 0px;
          }
        }
      }
    }

    .wrapper {
      width: 70%;
      background-color: $white;
      border-radius: 7px;
      height: fit-content;
      padding: 20px;
      margin-left: 20%;
      margin-top: 3rem;

      .input-contain {
        width: 100%;
        p {
          margin-bottom: 5px;
          span {
            color: #ff2828;
          }
        }

        .horizontal {
          width: 100%;
          flex-wrap: wrap;
          flex: unset;
        }

        .radios {
          padding: 15px;
          .radio {
            width: 30%;
            color: $font-color;
            font-weight: $font-weight-medium;
            flex: unset;
          }
        }
      }

      .buttons {
        display: flex;
        gap: 1rem;
        margin-top: 1rem;
        align-items: center;
        .btn-onedit {
          display: flex;
          gap: 1rem;
          align-items: center;
        }

        .cancel {
          height: 100%;
          padding: 7px 15px;
          color: $font-color;
          cursor: pointer;
          background-color: $gainsboro;
          border-radius: 3px;
          text-decoration: none;
          font-size: 15px;
          min-height: fit-content !important;
        }

        .save-button {
          color: $font-color;
          background-color: $button-color;
          font-size: 15px;
          min-height: fit-content !important;
          text-transform: capitalize;
        }
      }
    }
  }

  &.pop-out-profile {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    .contain {
      width: 30%;
      padding: 20px;
      background-color: white;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      .close-btn {
        margin-left: auto;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
      }

      .user-photo {
        width: 130px;
        min-width: 130px;
        max-width: 130px;
        height: 130px;
        min-height: 130px;
        max-height: 130px;
        aspect-ratio: 1/1;
        border-radius: 300px;
        border: 1px solid $gainsboro;
      }

      .choose-img {
        width: 200px;
        padding: 7px;
        border-radius: 5px;
        background-color: $papaya-whip;
        cursor: pointer;
        display: flex;
        justify-content: center;
        margin-top: 2rem;

        p {
          font-size: 14px;
          font-weight: $font-weight-medium;
        }
      }

      .warning {
        text-align: center;
        font-weight: 14px;
        color: $roman-silver;
        margin-top: 1.5rem;
      }

      .upload-img {
        width: 200px;
        padding: 7px;
        border-radius: 5px;
        background-color: $primary-color;
        cursor: pointer;
        display: flex;
        justify-content: center;
        margin-top: 2rem;

        p {
          font-size: 14px;
          font-weight: $font-weight-medium;
        }
      }
    }
  }
}
</style>
