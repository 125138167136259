import { createRouter, createWebHistory } from "vue-router";
// import { onMounted } from "vue";
// Views
// Customer Side View
import HomeView from "../views/cust/company-profile/HomeView.vue";
import AboutView from "../views/cust/company-profile/AboutView.vue";
import ContactView from "../views/cust/company-profile/ContactView.vue";
import BestPropView from "../views/cust/company-profile/BestPropView.vue";
import FilteredPropView from "../views/cust/company-profile/FilteredPropView.vue";
import CustSell from "../views/cust/sell-property/sellPropView.vue";
import CustRent from "../views/cust/rent-property/rentPropView.vue";
import RentPropDetail from "../views/cust/rent-property/RentPropDetailView.vue";
import SellPropDetail from "../views/cust/sell-property/SellPropDetailView.vue";
import ArticleView from "../views/cust/article/ArticleView.vue";
import ArticleDetailView from "../views/cust/article/ArticleDetailView.vue";
import AgentsView from "../views/cust/agents/AgentsView.vue";
import AgentDetailView from "../views/cust/agents/AgentDetailView.vue";

// Company Side View
import DashboardView from "../views/company/DashboardView.vue";
import CompPropertyView from "../views/company/property/CompPropertyView.vue";
import UnggulanPropView from "../views/company/property/UnggulanPropView.vue";
import AddPropertyView from "../views/company/property/AddPropertyView.vue";
import EditPropertyView from "../views/company/property/EditPropertyView.vue";
import CompPropDetail from "../views/company/property/CompPropDetail.vue";
import AddUserView from "../views/company/user/AddUserView.vue";
import EditUserView from "../views/company/user/EditUserView.vue";
import UserListView from "../views/company/user/UserListView.vue";
import MyAccountView from "../views/company/user/MyAccountView.vue";
import LoginView from "../views/company/LoginView.vue";
import AddArticleView from "../views/company/aricle/AddArticleView.vue";
import AllArticleView from "../views/company/aricle/AllArticleView.vue";
import EditArticleView from "../views/company/aricle/EditArticleView.vue";

// Layout
import DefaultLayout from "../components/layouts/custDefaultLayout.vue";
import CompanyLayout from "../components/layouts/CompanyLayout.vue";
import CompDetailPropLayout from "../components/layouts/CompDetailPropLayout.vue";

const routes = [
  {
    path: "/admin/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/",
    name: "",
    component: DefaultLayout,
    children: [
      {
        path: "/",
        name: "home",
        component: HomeView,
      },
      {
        path: "/property-for-sell",
        name: "property-for-sell",
        component: CustSell,
      },
      {
        path: "/property-for-rent",
        name: "property-for-rent",
        component: CustRent,
      },
      {
        path: "/property/detail/slug/:slug",
        name: "property-for-sell-detail",
        component: SellPropDetail,
      },
      {
        path: "/property-for-rent/1",
        name: "property-for-rent/1",
        component: RentPropDetail,
      },
      {
        path: "/article",
        name: "article",
        component: ArticleView,
      },
      {
        path: "/best-properties",
        name: "best-properties",
        component: BestPropView,
      },
      {
        path: "/article/:slug",
        name: "article-detail",
        component: ArticleDetailView,
      },
      {
        path: "/agents",
        name: "agents",
        component: AgentsView,
      },
      {
        path: "/agents/:id",
        name: "agents-detail",
        component: AgentDetailView,
      },
      {
        path: "/about",
        name: "about",
        component: AboutView,
      },
      {
        path: "/contact-us",
        name: "contact-us",
        component: ContactView,
      },
      {
        path: "/filtered",
        name: "filtered",
        component: FilteredPropView,
      },
    ],
  },

  {
    path: "/admin",
    redirect: "/admin/properties",
    meta: { requiresAuth: true },
  },
  {
    path: "/admin",
    name: "",
    component: CompDetailPropLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/property/detail/slug/:slug/preview",
        name: "detail-property-company",
        component: CompPropDetail,
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    component: CompanyLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: DashboardView,
      },
      {
        path: "properties",
        name: "properties",
        component: CompPropertyView,
      },
      {
        path: "properti-unggulan",
        name: "properti-unggulan",
        component: UnggulanPropView,
      },
      {
        path: "add-properties",
        name: "add-properties",
        component: AddPropertyView,
      },
      {
        path: "edit-properties/slug/:slug",
        name: "edit-properties",
        component: EditPropertyView,
      },
      {
        path: "add-user",
        name: "add-user",
        component: AddUserView,
      },
      {
        path: "edit-user/:id",
        name: "edit-user",
        component: EditUserView,
      },
      {
        path: "user-list",
        name: "user-list",
        component: UserListView,
      },
      {
        path: "my-account",
        name: "my-account",
        component: MyAccountView,
      },
      {
        path: "add-new-article",
        name: "add-new-article",
        component: AddArticleView,
      },
      {
        path: "articles",
        name: "articles-admin",
        component: AllArticleView,
      },
      {
        path: "edit-article/:slug",
        name: "edit-article",
        component: EditArticleView,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

// Function to check if the user is authenticated
function isAuthenticated() {
  const data = JSON.parse(localStorage.getItem("data"));
  const token = data ? data.token : null;
  return !!token;
}

// Navigation guard to handle authentication
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next({ name: "login" }); // Redirect to login if not authenticated
    } else {
      next(); // Continue navigating
    }
  } else if (to.path == "/admin/login" && isAuthenticated()) {
    next({ name: "properties" }); // Redirect if trying to access login while authenticated
  } else {
    next(); // No authentication required, proceed to the route
  }
  window.scrollTo(0, 0);
});

export default router;
