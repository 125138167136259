<template>
  <v-app>
    <section class="about-us-jumbotron">
      <v-container>
        <v-row>
          <div class="wrapper">
            <h1>Tentang Kami</h1>
            <h3>Hepi Property</h3>
          </div>
        </v-row>
      </v-container>
    </section>

    <section class="our-story">
      <v-container>
        <v-row>
          <div class="wrapper">
            <h2>Our Story</h2>
            <p>
              Hepi Property adalah sebuah Kantor Broker Properti yang berdiri di
              Semarang pada 1 November 2019 di bawah PT Hidup Else Properti
              Indonesia. Berawal memakai nama merk Else Property, namun sejak 1
              Oktober 2020 berganti nama menjadi Hepi Property. <br /><br />Hepi
              Property telah menjadi anggota Asosiasi Real Estate Broker
              Indonesia (AREBI) dengan nomor anggota 2019.000197.A pada tanggal
              6 April 2020.
            </p>
          </div>
        </v-row>
      </v-container>
    </section>

    <section class="vision-mission">
      <div class="bg-house"></div>
      <div class="bg-beige">
        <v-container>
          <v-row>
            <p>
              Hepi Property berkomitmen untuk memberikan layanan terbaik bagi
              pemilik property maupun pembeli atau penyewa. Selain itu, Hepi
              Property juga memberikan pelatihan dan bimbingan kepada team untuk
              menjadi Agen Property yang profesional, berkarakter dan beretika.
            </p>
          </v-row>
        </v-container>
      </div>

      <div class="vision">
        <v-container>
          <v-row>
            <div class="wrapper">
              <h2>Visi Kami</h2>
              <p>
                Saat ini layanan Hepi Property fokus di kota Semarang dan
                sekitarnya. Hepi Property memiliki visi untuk menjadi Kantor
                Broker Property yang terbaik, terbesar dan menjadi pilihan utama
                di kota Semarang.
              </p>
              <v-img src="@/assets/vision.png" cover class="vision-img"></v-img>
            </div>
          </v-row>
        </v-container>
      </div>
    </section>

    <section class="our-team">
      <v-container>
        <v-row>
          <div class="wrapper">
            <h2>Team Kami</h2>

            <div class="team-list">
              <div class="group" v-for="i in 3" :key="i">
                <v-img class="team-photo" src="@/assets/agent-raw.png"></v-img>

                <p class="name">TEAM 1</p>
                <p class="position">Founder</p>
              </div>
            </div>
          </div>
        </v-row>
      </v-container>
    </section>
  </v-app>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
section {
  padding: 50px 0;

  &.about-us-jumbotron {
    padding: 12rem;
    background-image: url("@/assets/about-img.png");
    background-size: cover;

    .wrapper {
      width: 100%;

      h1 {
        text-align: center;
        color: $white;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 20px;
      }

      h3 {
        text-align: center;
        font-weight: $font-weight-medium;
        color: $white;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }

  &.our-story {
    .wrapper {
      display: flex;
      gap: 2rem;

      h2 {
        color: $primary-color;
        width: 30%;
      }

      p {
        font-size: 20px;
        width: 70%;
        text-align: justify;
      }
    }
  }

  &.vision-mission {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .bg-house {
      height: 350px;
      background-image: url("@/assets/property-galery.png");
      background-size: cover;
      background-position: center;
    }

    .bg-beige {
      height: 350px;
      background-color: $secondary-color;
      display: flex;
      align-items: center;

      p {
        width: 55%;
        text-align: justify;
        font-size: 18px;
      }
    }

    .vision {
      position: absolute;
      z-index: 2;
      width: 100vw;

      .wrapper {
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
        background-color: $white;
        border-radius: 10px;
        width: 35%;
        padding: 20px;
        margin-left: auto;

        h2 {
          color: $primary-color;
          margin-top: 1rem;
          text-align: center;
        }

        p {
          text-align: center;
          margin-top: 20px;
          margin-bottom: 30px;
        }

        .vision-img {
          height: 200px;
          border-radius: 7px;
        }
      }
    }
  }

  &.our-team {
    .wrapper {
      width: 100%;

      h2 {
        text-align: center;
        color: $primary-color;
        margin-bottom: 3rem;
      }

      .team-list {
        display: flex;
        gap: 5%;

        .group {
          width: 30%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .team-photo {
            border-radius: 50%;
            width: 70%;
          }

          .name {
            margin-top: 2rem;
            font-size: 22px;
            font-weight: $font-weight-semibold;
            letter-spacing: 1.2px;
            text-transform: uppercase;
            width: fit-content;
          }

          p {
            width: fit-content;
            font-size: 18px;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $bp-lg) {
  section {
    &.vision-mission {
      .bg-house {
        height: 350px;
      }

      .bg-beige {
        height: 350px;
        ize: 18px;
      }
    }
  }
}

@media screen and (max-width: $bp-md) {
  section {
    padding: 30px 0;

    &.about-us-jumbotron {
      padding: 5rem 0;
    }

    &.our-story {
      .wrapper {
        margin: auto;
        width: 90%;
        flex-direction: column;

        h2 {
          width: 100%;
        }

        p {
          font-size: 18px;
          width: 100%;
        }
      }
    }

    &.vision-mission {
      .bg-house {
        height: 300px;
      }

      .bg-beige {
        height: 300px;
        display: flex;

        p {
          width: 90%;
          margin: auto;
          font-size: 16px;
        }
      }

      .vision {
        top: -40px;
        position: relative;

        .wrapper {
          width: 90%;
          margin: auto;
        }
      }
    }

    &.our-team {
      .wrapper {
        margin: auto;
        width: 90%;
        .team-list {
          flex-direction: column;
          gap: 2rem;

          .group {
            width: 100%;

            .team-photo {
              width: 40%;
            }

            .name {
              font-size: 20px;
            }

            p {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $bp-xs) {
  section {
    &.vision-mission {
      .bg-house {
        height: 250px;
      }
    }

    &.our-team {
      .wrapper {
        .team-list {
          .group {
            width: 100%;

            .team-photo {
              width: 60%;
            }
          }
        }
      }
    }
  }
}
</style>
