<template>
  <v-app>
    <section class="agent-data">
      <v-container>
        <v-row>
          <div class="wrapper">
            <div class="top-section">
              <v-img
                aspect-ratio="1/1"
                src="@/assets/user-big.png"
                class="agent-img"
                cover
                v-if="agentData.image_url == 'default/profile.png'"
              ></v-img>
              <v-img
                aspect-ratio="1/1"
                :src="`${this.$apiImg}${agentData.image_url}`"
                class="agent-img"
                cover
                v-else
              ></v-img>
              <div class="info-section">
                <h4 class="name">{{ agentData.name }}</h4>
                <div class="contacts-role">
                  <p class="role">Property Agent</p>
                  <div class="contacts">
                    <a
                      :href="`https://mail.google.com/mail/?view=cm&to=${agentData.email}&su=KONSULTASI-PROPERTI`"
                      class="contact"
                      target="_blank"
                    >
                      <v-img
                        src="@/assets/email-white.png"
                        class="link-icon"
                      ></v-img>
                    </a>
                    <a
                      :href="`tel:${agentData.phone_number}`"
                      class="contact"
                      target="_blank"
                    >
                      <v-img
                        src="@/assets/phone-white.png"
                        class="link-icon"
                      ></v-img>
                    </a>
                    <a
                      :href="`https://wa.me/${agentData.phone_number}`"
                      class="contact"
                      target="_blank"
                    >
                      <v-img
                        src="@/assets/chat-white.png"
                        class="link-icon"
                      ></v-img>
                    </a>
                  </div>
                </div>
                <div class="business-info">
                  <div class="group">
                    <p class="title">Dijual</p>
                    <p class="value">{{ agentDataProp.dijual }}</p>
                  </div>
                  <div class="group">
                    <p class="title">Disewakan</p>
                    <p class="value">{{ agentDataProp.disewa }}</p>
                  </div>
                  <div class="group">
                    <p class="title">Jumlah Listing</p>
                    <p class="value">{{ agentDataProp.listing }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="bottom-section">
              <h4 class="about">Listing dari {{ agentData.name }}</h4>
            </div>
          </div>
        </v-row>
      </v-container>
    </section>

    <section class="search-result">
      <v-container>
        <v-row>
          <div class="wrapper-list">
            <p
              v-if="
                propAll.data &&
                Array.isArray(propAll.data) &&
                propAll.data.length === 0
              "
              class="result-notfound"
            >
              Agent belum memiliki listing.
            </p>
            <router-link
              v-else
              class="property-card"
              v-for="property in propAll.data"
              :key="property.id"
              :to="`${property.path}`"
            >
              <v-img
                class="property-img"
                height="200px"
                aspect-ratio="3/4"
                cover
                v-if="property.images && property.images.length > 0"
                :src="this.$apiImg + property.images[0].image_url"
              ></v-img>
              <v-img
                class="property-img"
                height="200px"
                aspect-ratio="3/4"
                cover
                v-else
                src="@/assets/property-img.png"
              ></v-img>
              <div class="mid-section">
                <p class="price">Rp{{ formatPrice(property.harga) }}</p>
                <p class="type">{{ property.tipe_properti }}</p>
                <p class="property-name">{{ property.judul }}</p>
                <p class="location">
                  <span
                    ><v-img
                      src="@/assets/location.png"
                      width="17px"
                      height="17px"
                      contain
                    ></v-img></span
                  >{{ property.area }}
                </p>
              </div>
              <div class="bottom-section">
                <div class="wrapper">
                  <div class="top-text">
                    <v-img
                      src="@/assets/bed.png"
                      width="25px"
                      max-width="25px"
                      contain
                      height="25px"
                    ></v-img>
                    <p class="qty">{{ property.kamar_tidur }}</p>
                  </div>
                  <p class="title">Kamar</p>
                </div>
                <div class="wrapper">
                  <div class="top-text">
                    <v-img
                      src="@/assets/shower.png"
                      width="25px"
                      max-width="25px"
                      contain
                      height="25px"
                    ></v-img>
                    <p class="qty">{{ property.kamar_mandi }}</p>
                  </div>
                  <p class="title">Toilet</p>
                </div>
                <div class="wrapper">
                  <div class="top-text">
                    <v-img
                      src="@/assets/ruler.png"
                      width="25px"
                      max-width="25px"
                      contain
                      height="25px"
                    ></v-img>
                    <p class="qty">
                      {{ property.luas_tanah }}<span>m<sup>2</sup></span>
                    </p>
                  </div>
                  <p class="title">Luas Tanah</p>
                </div>
              </div>
              <div class="post-detail">
                <!-- <div class="group poster">
                  <v-img
                    src="@/assets/user.png"
                    width="25px"
                    height="25px"
                    max-width="25px"
                  ></v-img>
                  <p>{{ property.creator.name }}</p>
                </div> -->
                <div class="group posted-at">
                  <v-img
                    src="@/assets/attach.png"
                    width="25px"
                    max-width="25px"
                    height="25px"
                  ></v-img>
                  <p>{{ property.date }}</p>
                </div>
              </div>
            </router-link>
          </div>
        </v-row>
      </v-container>
    </section>

    <v-pagination
      v-if="
        propAll.data && Array.isArray(propAll.data) && propAll.data.length > 0
      "
      v-model="currentPage"
      :total-visible="5"
      :length="propAll.last_page"
      style="margin: 2rem"
    ></v-pagination>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      agentData: [],
      agentDataProp: [],
      agentProp: [],
      propAll: [],
      currentPage: 1,
    };
  },
  methods: {
    async getAgentData() {
      // this.loading = true;
      try {
        const res = await axios.get(
          `${this.$api}/user/agent/detail/` + this.$route.params.id
        );
        if (res.status === 200 || res.status === 201) {
          this.agentData = res.data.data;
          this.agentDataProp = res.data;
          console.log(this.agentData);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getPropAll() {
      // this.loading = true;
      try {
        const res = await axios.get(
          `${this.$api}/user/agent/property/` +
            this.$route.params.id +
            `?page=${this.currentPage}`
        );
        if (res.status === 200 || res.status === 201) {
          this.propAll = res.data.data;
          console.log(this.propAll);
        }
      } catch (error) {
        console.log(error);
      }
    },
    formatPrice(value) {
      const formattedValue = (value / 1).toFixed(0).replace(".", ",");
      return formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  watch: {
    currentPage() {
      this.getAgentList();
    },
  },
  async mounted() {
    await this.getAgentData();
    await this.getPropAll();
  },
};
</script>

<style lang="scss" scoped>
section {
  &.agent-data {
    padding-top: 50px;
    .wrapper {
      width: 100%;
      .top-section {
        display: flex;
        gap: 2rem;

        .agent-img {
          width: 20%;
          // height: fit-content;
          aspect-ratio: 1/1;
          border-radius: 50%;
        }

        .info-section {
          width: 80%;
          display: flex;
          flex-direction: column;

          .name {
            font-weight: $font-weight-semibold;
          }

          .contacts-role {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;

            .contacts {
              display: flex;
              gap: 1rem;

              .contact {
                background-color: $button-color;
                padding: 5px 2rem;
                border-radius: 5px;

                .link-icon {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }

          .business-info {
            display: flex;
            gap: 1.5rem;
            width: 80%;
            margin-top: 3rem;

            .group {
              width: 25%;
              border-radius: 5px;
              background-color: $papaya-whip;
              padding: 10px 20px;
              border-left: 5px solid $button-color;
              height: fit-content;

              .value {
                font-weight: $font-weight-medium;
                font-size: 18px;
                margin-top: 10px;
              }
            }
          }
        }
      }

      .bottom-section {
        width: 100%;
        margin-top: 2rem;

        .about {
          color: $primary-color;
          margin-bottom: 1rem;
          font-weight: $font-weight-semibold;
        }

        .desc {
          line-height: 1.5rem;
        }
      }
    }
  }

  &.search-result {
    padding-top: 0;
    .wrapper-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;

      .property-card {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        width: calc(25% - 24px);
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
        background-color: $white;
        border-radius: 10px;

        .property-img {
          border-radius: 10px 10px 0 0;
        }

        .mid-section {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding: 20px 15px;

          .price {
            font-size: 20px;
            font-weight: $font-weight-semibold;
            color: $black;
          }

          .type {
            font-size: 12px;
            font-weight: $font-weight-semibold;
            text-transform: uppercase;
            color: $black;
          }

          .property-name {
            color: $black;
            font-weight: $font-weight-medium;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .location {
            display: flex;
            gap: 3px;
            align-items: center;
            color: $roman-silver;
            font-size: 12px;
            font-weight: $font-weight-medium;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .bottom-section {
          padding: 20px 15px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1.5px solid $gainsboro;

          .wrapper {
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: fit-content;

            .top-text {
              display: flex;
              gap: 10px;
              align-items: center;

              .qty {
                font-weight: $font-weight-semibold;
                font-size: 18px;
                color: $roman-silver;
                line-height: 18px;

                span {
                  font-size: 12px;
                }
              }
            }

            .title {
              color: $roman-silver;
              font-weight: $font-weight-medium;
              font-size: 9px;
            }
          }
        }

        .post-detail {
          display: flex;
          gap: 10px;
          padding: 20px 15px;
          align-items: center;
          .group {
            display: flex;
            align-items: center;

            p {
              font-size: 13px;
              color: $roman-silver;
            }
          }

          .poster {
            width: 55%;
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .posted-at {
            width: 45%;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1266px) {
  section {
    &.search-result {
      .wrapper-list {
        gap: 2%;
        .property-card {
          width: 32%;
        }
      }
    }
  }
}

@media screen and (max-width: $bp-lg) {
  section {
    &.agent-data {
      .wrapper {
        .top-section {
          .info-section {
            .business-info {
              width: 100%;

              .group {
                width: fit-content;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $bp-md) {
  section {
    &.agent-data {
      .wrapper {
        width: 90%;
        margin: auto;
        .top-section {
          flex-direction: column;
          align-items: center;
          .name {
            text-align: center;
          }
          .info-section {
            width: 100%;
            .contacts-role {
              flex-direction: column;
              align-items: center;
              gap: 1rem;
            }
            .business-info {
              flex-wrap: wrap;
              gap: 4%;
              row-gap: 20px;
              .group {
                width: 48%;
              }
            }
          }
        }

        .bottom-section {
          width: 100%;
          margin-top: 2rem;

          .about {
            color: $primary-color;
            margin-bottom: 1rem;
            font-weight: $font-weight-semibold;
            text-align: center;
          }

          .desc {
            line-height: 1.5rem;
            text-align: center;
          }
        }
      }
    }

    &.search-result {
      .wrapper-list {
        width: 95%;
        margin: auto;
        gap: 4%;
        row-gap: 2rem;
        .property-card {
          width: 48%;
        }
      }
    }
  }
}

@media screen and (max-width: $bp-xs) {
  section {
    &.agent-data {
      .wrapper {
        .top-section {
          .agent-img {
            width: 50%;
          }
          .info-section {
            .business-info {
              .group {
                padding: 5px 10px;
                .title {
                  font-size: 14px;
                }

                .value {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }

    &.search-result {
      padding-top: 0;
      .wrapper-list {
        width: 95%;
        margin: auto;
        row-gap: 2rem;
        .property-card {
          width: 100%;
        }
      }
    }
  }
}
</style>
