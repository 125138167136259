<template>
  <section class="company">
    <div class="top-section">
      <div class="above">
        <h4>Artikel</h4>
        <profile />
      </div>
    </div>
    <div class="tools">
      <router-link to="/admin/add-new-article" class="add-user"
        ><v-img
          src="@/assets/plus.png"
          max-width="18px"
          min-width="18px"
          max-height="18px"
          contain
        ></v-img>
        Tambah Artikel
      </router-link>
      <!-- <div class="search-field">
        <v-text-field
          v-model="search"
          append-inner-icon="mdi-magnify"
          density="compact"
          placeholder="Cari User"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          hide-details
        ></v-text-field>
      </div> -->
    </div>
    <v-card class="rounded-6">
      <v-data-table
        :headers="headers"
        :search="search"
        :items="articleAll.data"
        class="elevation-1"
        :items-per-page="itemsPerPage"
        :loading="loading"
      >
        <!-- <template v-slot:loading>
          <p>loading.</p>
        </template> -->
        <template v-slot:[`item.role`]="{ item }">
          {{ item.raw.roles[0].name }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <router-link
            :to="`edit-article/${item.raw.slug}`"
            v-if="item.raw.name != 'owner'"
            class="to-edit"
          >
            <v-icon size="small" class="me-2"> mdi-pencil </v-icon>
          </router-link>
          <v-icon
            size="small"
            @click="deleteConfirm(item.raw)"
            style="color: #ff2828"
            v-if="item.raw.name != 'owner'"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <p>Data tidak dapat dimuat.</p>
        </template>
        <template v-slot:bottom>
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :length="articleAll.last_page"
            ></v-pagination>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </section>
  <section class="pop-out-delete" v-if="dialogDelete">
    <v-container
      ><v-row>
        <div class="wrapper">
          <v-img
            class="close-pop-up"
            src="@/assets/close-btn.png"
            @click="deleteItemToggle()"
          ></v-img>
          <p class="prompt">
            Apakah anda yakin ingin menghapus user {{ currDelete.name }}?
          </p>
          <div class="button-ctrl">
            <div class="btn cancel" @click="deleteItemToggle()">Cancel</div>
            <div class="btn yes" @click="deleteItem(currDelete.id)">
              Ya, keluar
            </div>
          </div>
        </div>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import profile from "@/components/component/ProfileNav.vue";

export default {
  data: () => ({
    articleAll: [],
    headers: [
      { title: "Judul", key: "title" },
      { title: "Tanggal", key: "created_at" },
      { title: "Actions", key: "actions", sortable: false },
    ],
    page: 1,
    loading: false,
    search: "",
    dialogDelete: false,
    currDelete: [],
    itemsPerPage: 10,
  }),
  methods: {
    async getArticleAll() {
      this.loading = true;
      try {
        const res = await axios.get(
          `${this.$api}/article/list?page=${this.page}`
        );
        if (res.status === 200 || res.status === 201) {
          this.articleAll = res.data.data;
          this.loading = false;
          console.log(this.articleAll);
        }
      } catch (error) {
        console.log(error);
      }
    },
    deleteConfirm(data) {
      this.dialogDelete = true;
      this.currDelete = data;
      console.log(data);
    },
    deleteItemToggle() {
      this.dialogDelete = !this.dialogDelete;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async deleteItem(item) {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const res = await axios.post(`${this.$api}/article/delete/id/${item}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(res);
        if (res.status === 200 || res.status === 201) {
          this.dialogDelete = false;
          this.getArticleAll(this.page);
        }
      } catch (error) {
        console.log(error.response.data);
      }
    },
  },
  components: {
    profile,
  },

  async mounted() {
    await this.getArticleAll();
  },
};
</script>

<script setup>
import { VDataTable } from "vuetify/labs/VDataTable";
</script>

<style lang="scss" scoped>
section {
  &.company {
    width: 80%;
    max-height: 100vh;
    padding: 30px;
    overflow: scroll;
    background-color: $anti-flash;
    .top-section {
      .above {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.2rem;
        align-items: center;
        padding-bottom: 1.2rem;
        border-bottom: 2px solid $roman-silver;

        .profile {
          position: relative;
          .preview {
            display: flex;
            gap: 10px;
            align-items: center;
            position: relative;
            cursor: pointer;

            .name {
              font-size: 14px;
              font-weight: $font-weight-semibold;
              margin-bottom: 5px;
            }
          }

          .role {
            font-size: 12px;
            color: $roman-silver;
          }

          .collapsible-menu {
            border-radius: 5px;
            border: 1.5px solid $gainsboro;
            background-color: $white;
            position: absolute;
            top: 60px;
            width: 150%;
            z-index: 999;
            right: 0;

            .menu {
              display: flex;
              padding: 15px 25px;
              align-items: center;
              gap: 15px;
              text-decoration: none;
              cursor: pointer;

              .menu-icon {
                max-width: 16px;
                min-width: 16px;
                min-height: 16px;
                max-height: 16px;
              }

              p {
                color: $roman-silver;
                font-size: 14px;
              }

              &:hover {
                background-color: #ffefd1;
              }
            }
          }
        }
      }
      h4 {
        width: fit-content;
        font-weight: $font-weight-semibold;
      }
    }

    .add-user {
      background-color: $primary-color !important;
      color: $font-color !important;
      font-weight: $font-weight-medium;
      border-radius: 5px;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      gap: 1rem;
      text-decoration: none;
    }

    .tools {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
    }

    .search-field {
      width: 250px;
      margin-left: auto;
    }
  }
}
.pop-out-delete {
  background: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  .wrapper {
    width: 400px;
    margin: auto;
    background-color: $white;
    padding: 2rem;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: relative;

    .close-pop-up {
      width: 30px;
      height: 30px;
      max-width: 30px;
      max-height: 30px;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .prompt {
      text-align: center;
      width: 100%;
      margin-top: 10px;
      font-weight: $font-weight-medium;
    }

    .button-ctrl {
      display: flex;
      gap: 5px;
      width: 100%;
      .btn {
        width: 50%;
        text-align: center;
        padding: 7px 0;
        border-radius: 5px;
        font-size: 14px;
        font-weight: $font-weight-medium;
        cursor: pointer;

        &.cancel {
          color: #ff2828;
          background-color: #f7f7f7;
        }

        &.yes {
          background-color: $primary-color;
        }
      }
    }
  }
}

.wrapper {
  background-color: $white;
  border-radius: 7px;
  height: fit-content;
  padding: 20px;

  .input-contain {
    width: 100%;
    p {
      margin-bottom: 5px;
      span {
        color: #ff2828;
      }
    }

    .horizontal {
      width: 100%;
      flex-wrap: wrap;
      flex: unset;
    }

    .radios {
      padding: 15px;
      .radio {
        width: 30%;
        color: $font-color;
        font-weight: $font-weight-medium;
        flex: unset;
      }
    }
  }

  .buttons {
    display: flex;
    gap: 1rem;
    margin-top: 5rem;
    align-items: center;

    .cancel {
      height: 100%;
      padding: 7px 15px;
      color: $font-color;
      background-color: $gainsboro;
      border-radius: 3px;
      text-decoration: none;
      font-size: 15px;
      min-height: fit-content !important;
    }

    .save-button {
      color: $font-color;
      background-color: $button-color;
      font-size: 15px;
      min-height: fit-content !important;
      text-transform: capitalize;
    }
  }
}

.wrapper-delete {
  .title {
    font-weight: $font-weight-semibold;
  }
}

.to-edit {
  color: $font-color;
}
</style>
