<template>
  <v-app>
    <section class="search">
      <div class="background">
        <v-img
          src="@/assets/bg-search-prop.png"
          width="100vw"
          height="200px"
          cover
        ></v-img>
      </div>
      <!-- <div class="search-bar">
        <v-container>
          <v-row>
            <div class="wrapper">
              <v-text-field
                density="compact"
                placeholder="Cari Properti"
                prepend-inner-icon="mdi-magnify"
                variant="solo"
                hide-details
              ></v-text-field>
            </div>
          </v-row>
        </v-container>
      </div> -->
    </section>
    <!-- <section class="popular-article">
      <v-container>
        <v-row>
          <div class="wrapper">
            <h4>Artikel Terpopuler</h4>
            <div class="article-list">
              <div class="main-article">
                <router-link to="/#" class="article-card">
                  <v-img
                    src="@/assets/article-img.png"
                    class="article-img"
                    cover
                  ></v-img>

                  <div class="article-text">
                    <p class="release-date">27 Mei 2023</p>
                    <p class="title">
                      5 Jenis Sertifikat yang Perlu Kamu Ketahui Sebelum Jual
                      Beli Rumah
                    </p>
                    <p class="preview">
                      Hai bagi kalian yang sedang mencari rumah tentu memiliki
                      rumah impian yang kalian inginkan, seperti taman yang
                      luas, bangunan yang kokoh, lingkungan contoh
                      sidauysgdfuyasdgfuasvfuasdvfgyudasvfgydfvdgfvsdgfv
                    </p>
                  </div>
                </router-link>
              </div>

              <div class="side-article">
                <router-link
                  class="side-article-card"
                  to="/#"
                  v-for="i in 3"
                  :key="i"
                >
                  <v-img
                    class="article-img"
                    src="@/assets/article-img.png"
                    height="140px"
                    cover
                    aspect-ratio="16/9"
                  ></v-img>
                  <div class="article-preview">
                    <p class="release-date">April 23, 2022</p>
                    <p class="article-title">
                      Ternyata Beli Rumah Menggunakan Agen Properti Banyak
                      Manfaatnya
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </v-row>
      </v-container>
    </section> -->

    <!-- Section Article -->
    <section class="article">
      <v-container>
        <v-row>
          <div class="wrapper">
            <h4>Artikel</h4>
            <div class="article-wrap">
              <router-link
                :to="`/article/${article.slug}`"
                class="article-card"
                v-for="article in articleList.data"
                :key="article"
              >
                <v-img
                  :src="`${this.$apiImg}${article.image_url}`"
                  class="article-img"
                  cover
                ></v-img>

                <div class="article-text">
                  <p class="release-date">{{ article.created_at }}</p>
                  <p class="title">
                    {{ article.title }}
                  </p>
                  <p class="preview">
                    {{ article.content }}
                  </p>
                </div>
              </router-link>
            </div>

            <!-- <v-btn class="load-more" variant="outlined"
              >Lihat Lebih Banyak</v-btn
            > -->
          </div>
          <v-pagination
            v-model="currentPage"
            :total-visible="5"
            :length="articleList.last_page"
            style="margin: auto"
          ></v-pagination>
        </v-row>
      </v-container>
    </section>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      articleList: [],
      currentPage: 1,
    };
  },
  methods: {
    async getArticleList() {
      this.loading = true;
      try {
        const res = await axios.get(
          `${this.$api}/article/list?page=${this.currentPage}`
        );
        if (res.status === 200 || res.status === 201) {
          this.articleList = res.data.data;
          console.log(this.articleList);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    await this.getArticleList();
  },
  watch: {
    currentPage() {
      this.getArticleList();
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  &.search {
    display: flex;
    position: relative;

    .background {
      z-index: 1;
    }

    .search-bar {
      position: absolute;
      width: 100vw;
      z-index: 2;
      height: 200px;
      display: flex;
      align-items: center;

      .wrapper {
        width: 70%;
        margin: auto;
      }
    }
  }

  &.popular-article {
    padding: 50px 0;
    .wrapper {
      width: 100%;
      padding: 30px 0;
      border-top: 3px solid #c4c7d0;
      border-bottom: 3px solid #c4c7d0;

      h4 {
        margin-bottom: 3rem;
      }

      .article-list {
        width: 100%;
        display: flex;
        gap: 50px;

        .main-article {
          width: 55%;

          .article-card {
            text-decoration: none;

            .article-img {
              border-radius: 5px;
              height: 350px;
              margin-bottom: 1.5rem;
            }

            .article-text {
              display: flex;
              flex-direction: column;
              gap: 10px;

              .release-date {
                color: $button-color;
                font-size: 13px;
                font-weight: $font-weight-semibold;
              }

              .title {
                font-size: 20px;
                color: $black;
                font-weight: $font-weight-semibold;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              }

              .preview {
                font-size: 13px;
                color: $font-color;
                font-weight: $font-weight-medium;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
              }
            }
          }
        }

        .side-article {
          width: 45%;
          display: flex;
          flex-direction: column;
          gap: 50px;

          .side-article-card {
            text-decoration: none;
            display: flex;
            gap: 20px;

            .article-img {
              width: 40%;
              border-radius: 5px;
            }

            .article-preview {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .release-date {
                color: $primary-color;
                font-weight: $font-weight-medium;
              }

              .article-title {
                font-size: 20px;
                font-weight: $font-weight-medium;
                color: $font-color;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }
    }
  }

  // Article
  &.article {
    padding: 50px 0;
    .wrapper {
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;

      h4 {
        margin-bottom: 3rem;
      }

      .article-wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 4%;
        row-gap: 2rem;
        width: 100%;

        .article-card {
          width: 22%;
          text-decoration: none;

          .article-img {
            border-radius: 5px;
            height: 200px;
            margin-bottom: 1.5rem;
          }

          .article-text {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .release-date {
              color: $button-color;
              font-size: 13px;
              font-weight: $font-weight-semibold;
            }

            .title {
              font-size: 20px;
              color: $black;
              font-weight: $font-weight-semibold;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }

            .preview {
              font-size: 13px;
              color: $font-color;
              font-weight: $font-weight-medium;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
            }
          }
        }
      }

      .load-more {
        margin: auto;
        color: $primary-color;
        border-radius: 5px;
        border: 1.5px solid $primary-color;
        padding: 7px 3rem;
        margin-top: 3rem;
      }
    }
  }
}

@media screen and (max-width: $bp-lg) {
  section {
    &.article {
      .wrapper {
        .article-wrap {
          display: flex;
          flex-wrap: wrap;
          gap: 5%;
          row-gap: 2rem;
          width: 100%;

          .article-card {
            width: 30%;
            text-decoration: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $bp-md) {
  section {
    &.popular-article {
      padding: 30px 0;

      .wrapper {
        width: 97%;
        margin: auto;

        h4 {
          margin-bottom: 2rem;
        }

        .article-list {
          gap: 30px;
          .main-article {
            .article-card {
              .article-img {
                height: 220px;
              }
            }
          }

          .side-article {
            .side-article-card {
              .article-img {
                height: 100px !important;
              }

              .article-preview {
                .release-date {
                  font-size: 11px;
                }
                .article-title {
                  font-size: 13px;
                  font-weight: $font-weight-semibold;
                }
              }
            }
          }
        }
      }
    }

    &.article {
      .wrapper {
        width: 97%;
        .article-wrap {
          gap: 5%;

          .article-card {
            width: 47.5%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $bp-sm) {
  section {
    &.popular-article {
      .wrapper {
        width: 90%;
        .article-list {
          flex-direction: column;
          .main-article,
          .side-article {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $bp-xs) {
  section {
    &.article {
      .wrapper {
        width: 90%;
        .article-wrap {
          flex-direction: column;
          gap: 20px;

          .article-card {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
