<template>
  <v-app>
    <section class="contact-us">
      <v-container>
        <v-row>
          <div class="wrapper">
            <div class="title">
              <h2>Hubungi Kami</h2>
              <p>Hubungi kami untuk detail properti yang Anda inginkan.</p>
            </div>
            <div class="contact-wrap">
              <div class="left-section">
                <div class="our-contact">
                  <p class="section-name">Hubungi Kami</p>

                  <div class="group-cp">
                    <p class="contact-person">Peter Gunawan</p>
                    <div class="group">
                      <v-img
                        class="icon-contact"
                        src="@/assets/call.png"
                      ></v-img>
                      <p class="value">081226489858</p>
                    </div>
                    <div class="group">
                      <v-img
                        class="icon-contact"
                        src="@/assets/email.png"
                      ></v-img>
                      <p class="value">ptrgnwn@gmail.com</p>
                    </div>
                  </div>
                  <div class="group-cp">
                    <p class="contact-person">Else Susilowaty</p>
                    <div class="group">
                      <v-img
                        class="icon-contact"
                        src="@/assets/call.png"
                      ></v-img>
                      <p class="value">085225676801</p>
                    </div>
                    <div class="group">
                      <v-img
                        class="icon-contact"
                        src="@/assets/email.png"
                      ></v-img>
                      <p class="value">elseproperty1810@gmail.co</p>
                    </div>
                  </div>
                  <div class="group-cp">
                    <p class="contact-person">Swanny Tjahya</p>
                    <div class="group">
                      <v-img
                        class="icon-contact"
                        src="@/assets/call.png"
                      ></v-img>
                      <p class="value">08122503988</p>
                    </div>
                  </div>
                </div>
                <div class="our-contact">
                  <p class="section-name">Kantor Kami</p>

                  <div class="group-cp">
                    <div class="group">
                      <v-img
                        class="icon-contact"
                        src="@/assets/map-black.png"
                      ></v-img>
                      <p class="value">
                        Ruko Graha Padma Boulevard Blok AA1 No 28 Semarang
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="right-section">
                <p class="prompt">
                  Masukkan data Anda untuk dapat segera kami hubungi
                </p>
                <div class="cust-name">
                  <div class="input-contain">
                    <p>
                      Nama Depan<span><sup>*</sup></span>
                    </p>
                    <v-text-field
                      class="input-template"
                      v-model="firstName"
                      placeholder="Nama Depan"
                      density="compact"
                      variant="outlined"
                      base-color="#818A91"
                      bg-color="#fff"
                      color="#363636"
                    ></v-text-field>
                  </div>
                  <div class="input-contain">
                    <p>Nama Belakang</p>
                    <v-text-field
                      class="input-template"
                      v-model="lastName"
                      placeholder="Nama Belakang"
                      density="compact"
                      variant="outlined"
                      base-color="#818A91"
                      bg-color="#fff"
                      color="#363636"
                    ></v-text-field>
                  </div>
                </div>
                <div class="input-contain">
                  <p>
                    Email<span><sup>*</sup></span>
                  </p>
                  <v-text-field
                    class="input-template"
                    v-model="email"
                    placeholder="Email"
                    density="compact"
                    variant="outlined"
                    base-color="#818A91"
                    bg-color="#fff"
                    color="#363636"
                  ></v-text-field>
                </div>
                <div class="input-contain">
                  <p>
                    Pesan<span><sup>*</sup></span>
                  </p>
                  <v-textarea
                    class="input-template"
                    v-model="message"
                    placeholder="Harga Minimal"
                    density="compact"
                    variant="outlined"
                    base-color="#818A91"
                    bg-color="#fff"
                    color="#363636"
                  ></v-textarea>
                </div>
                <v-checkbox
                  label="Saya bukan robot"
                  color="#FFA600"
                  v-model="notRobot"
                ></v-checkbox>
                <v-btn variant="flat" class="send-btn">Kirim</v-btn>
              </div>
            </div>
          </div>
        </v-row>
      </v-container>
    </section>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      notRobot: "",
    };
  },
};
</script>

<style lang="scss" scoped>
section {
  &.contact-us {
    background-color: $secondary-color;
    padding: 50px 0;
    .wrapper {
      width: 100%;

      .title {
        margin-bottom: 3rem;
        h2 {
          font-weight: $font-weight-semibold;
          color: $primary-color;
          margin-top: 10px;
          text-align: center;
        }
        p {
          font-size: 18px;
          text-align: center;
        }
      }

      .contact-wrap {
        display: flex;
        gap: 25px;

        .left-section {
          display: flex;
          flex-direction: column;
          gap: 25px;
          width: 35%;

          .our-contact {
            display: flex;
            flex-direction: column;
            padding: 20px;
            border-radius: 10px;
            background-color: $white;
            gap: 25px;

            .section-name {
              font-size: 20px;
              font-weight: $font-weight-medium;
              margin-bottom: 10px;
            }

            .group-cp {
              display: flex;
              flex-direction: column;
              gap: 10px;

              .contact-person {
                font-size: 18px;
                font-weight: $font-weight-medium;
              }

              .group {
                display: flex;
                gap: 10px;
                align-items: center;

                .icon-contact {
                  width: 20px;
                  height: 20px;
                  max-width: 20px;
                }
              }
            }
          }
        }

        .right-section {
          width: 65%;
          background-color: $white;
          border-radius: 10px;
          padding: 25px;
          display: flex;
          flex-direction: column;

          .prompt {
            font-size: 18px;
            font-weight: $font-weight-medium;
            margin-bottom: 30px;
          }

          .input-contain {
            p {
              font-weight: $font-weight-medium;
              margin-bottom: 5px;

              span {
                color: red;
              }
            }
          }

          .cust-name {
            display: flex;
            width: 100%;
            gap: 1rem;

            .input-contain {
              width: 50%;
            }
          }

          .send-btn {
            color: $font-color;
            background-color: $button-color;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $bp-md) {
  section {
    &.contact-us {
      .wrapper {
        width: 90%;
        margin: auto;
        .contact-wrap {
          flex-direction: column-reverse;

          .left-section,
          .right-section {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $bp-xs) {
  section {
    &.contact-us {
      .wrapper {
        .title {
          p {
            font-size: 16px;
          }
        }
        .contact-wrap {
          .right-section {
            .cust-name {
              flex-direction: column;
              .input-contain {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
