<template>
  <section class="company">
    <p>Fitur belum tersedia</p>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
