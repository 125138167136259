<template>
  <section class="company">
    <div class="nav-header">
      <router-link to="/admin/user-list">
        <v-img
          src="@/assets/chevron-left-gray.png"
          height="30px"
          width="17px"
        ></v-img>
      </router-link>

      <h4>Artikel Baru</h4>
    </div>

    <div class="wrapper">
      <div class="input-contain">
        <p>
          Judul <span><sup>*</sup></span>
        </p>
        <v-text-field
          class="input-template"
          v-model="title"
          placeholder="Judul"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>
          Isi Artikel<span><sup>*</sup></span>
        </p>
        <v-textarea
          class="input-template"
          v-model="content"
          placeholder="Isi artikel"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          required
        ></v-textarea>
      </div>
      <div class="input-contain">
        <p>
          Foto Artikel<span><sup>*</sup></span>
        </p>
        <v-file-input
          variant="outlined"
          required
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          label=""
          placeholder="Foto Artikel"
          density="compact"
          @change="handleFileChange"
        ></v-file-input>
      </div>
      <p class="warn" v-if="!confField">
        Isi semua form untuk menambahkan artikel.
      </p>

      <div class="buttons">
        <router-link to="/admin/user-list" class="cancel"> Cancel </router-link>
        <v-btn
          class="save-button"
          variant="flat"
          prepend-icon="mdi-content-save"
          @click="postAddArticle"
          >Simpan</v-btn
        >
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    title: "",
    file: null,
    content: null,
    rules: {
      required: (value) => !!value || "Field is required",
    },
    confField: false,
  }),
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        try {
          this.file = file;
          console.log(file);
          // Validate the file type and size
          const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
          const maxSize = 2 * 1024 * 1024; // 2MB
          if (!allowedTypes.includes(file.type) || file.size > maxSize) {
            this.errorField = "Invalid image format or size";
          } else {
            this.errorField = null;
          }
        } catch (e) {
          console.error("Error processing file:", e);
        }
      }
    },
    async postAddArticle() {
      try {
        const formData = new FormData();
        formData.append("title", this.title);
        formData.append("content", this.content);
        formData.append("image", this.file);

        const res = await axios.post(`${this.$api}/article/create`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200 || res.status === 201) {
          // this.$router.push({ name: "user-list" });
        }
        console.log(res);
      } catch (error) {
        console.log(error.response.data);
        this.errorField =
          error.response.data.message || "Error uploading the article";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  &.company {
    width: 80%;
    max-height: 100vh;
    padding: 30px;
    overflow: scroll;
    background-color: $anti-flash;

    .nav-header {
      display: flex;
      gap: 3rem;
      align-items: center;
      padding-bottom: 1.5rem;
      border-bottom: 2px solid $font-color;
      margin-bottom: 1.5rem;

      h4 {
        font-weight: $font-weight-semibold;
      }
    }

    .wrapper {
      width: 70%;
      background-color: $white;
      border-radius: 7px;
      height: fit-content;
      padding: 20px;

      .input-contain {
        width: 100%;
        p {
          margin-bottom: 5px;
          span {
            color: #ff2828;
          }
        }

        .horizontal {
          width: 100%;
          flex-wrap: wrap;
          flex: unset;
        }

        .radios {
          padding: 15px;
          .radio {
            width: 30%;
            color: $font-color;
            font-weight: $font-weight-medium;
            flex: unset;
          }
        }
      }

      .warn {
        margin-top: 3rem;
        margin-bottom: 1rem;
        color: red;
      }

      .buttons {
        display: flex;
        gap: 1rem;
        align-items: center;

        .cancel {
          height: 100%;
          padding: 7px 15px;
          color: $font-color;
          background-color: $gainsboro;
          border-radius: 3px;
          text-decoration: none;
          font-size: 15px;
          min-height: fit-content !important;
        }

        .save-button {
          color: $font-color;
          background-color: $button-color;
          font-size: 15px;
          min-height: fit-content !important;
          text-transform: capitalize;
        }
      }
    }
  }
}

.title {
  color: $font-color !important;
  font-weight: $font-weight-semibold !important;
}
</style>
