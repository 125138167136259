<template>
  <section class="company">
    <div class="nav-header">
      <router-link to="/admin/user-list">
        <v-img
          src="@/assets/chevron-left-gray.png"
          height="30px"
          width="17px"
        ></v-img>
      </router-link>

      <h4>User</h4>
    </div>

    <div class="wrapper">
      <div class="input-contain">
        <p>
          Nama <span><sup>*</sup></span>
        </p>
        <v-text-field
          class="input-template"
          v-model="name"
          placeholder="Nama"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>
          Email <span><sup>*</sup></span>
        </p>
        <v-text-field
          class="input-template"
          v-model="email"
          placeholder="Email"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          :rules="[rules.required, rules.email]"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>
          No Whatsapp <span><sup>*</sup></span>
        </p>
        <v-text-field
          class="input-template"
          v-model="phoneNumber"
          placeholder="08XXXXXXXXXX"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>
          Password Baru <span><sup>*</sup></span>
        </p>
        <v-text-field
          class="input-template"
          placeholder="Password baru"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          :append-inner-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show4 ? 'text' : 'password'"
          v-model="password"
          @click:append-inner="show4 = !show4"
          :rules="[rules.required, rules.min]"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>
          Konfirmasi Password Baru<span><sup>*</sup></span>
        </p>
        <v-text-field
          :append-inner-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show3 ? 'text' : 'password'"
          v-model="confirmPassword"
          @click:append-inner="show3 = !show3"
          class="input-template"
          placeholder="Konfirmasi password baru"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          :rules="[rules.required, matchingPasswords]"
        ></v-text-field>
      </div>

      <div class="input-contain">
        <p>
          User Roles Permissions <span><sup>*</sup></span>
        </p>
        <v-radio-group v-model="roles" class="radios">
          <v-row class="horizontal">
            <v-radio label="Owner" value="owner" class="radio"></v-radio>
            <v-radio label="Admin" value="admin" class="radio"></v-radio>
            <v-radio label="Agent" value="agent" class="radio"></v-radio>
          </v-row>
        </v-radio-group>
      </div>

      <div class="buttons">
        <router-link to="/admin/user-list" class="cancel"> Cancel </router-link>
        <v-btn
          class="save-button"
          variant="flat"
          prepend-icon="mdi-content-save"
          @click="postUpdateUser"
          >Simpan</v-btn
        >
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    name: "",
    email: "",
    phoneNumber: "",
    show4: false,
    show3: false,
    password: "",
    confirmPassword: "",
    roles: "",
    rules: {
      required: (value) => !!value || "Field is required",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
    loading: false,
    userDetail: {},
  }),
  methods: {
    matchingPasswords: function () {
      if (this.password === this.confirmPassword) {
        return true;
      } else {
        return "Password tidak cocok";
      }
    },
    async getUserDetail() {
      this.loading = true;
      try {
        const res = await axios.get(
          `${this.$api}/admin/user/detail/id/${this.$route.params.id}`
        );
        if (res.status === 200 || res.status === 201) {
          this.userDetail = res.data.data;
          this.name = this.userDetail.name;
          this.email = this.userDetail.email;
          this.phoneNumber = this.userDetail.phone_number;
          // this.password = this.userDetail.name;
          // this.name = this.userDetail.name;
          this.roles = res.data.data.roles[0].name;
          this.loading = false;
          console.log(res.data.data.roles[0].name);
          console.log(this.userDetail);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async postUpdateUser() {
      try {
        const res = await axios.post(
          `${this.$api}/admin/user/update/id/${this.$route.params.id}`,
          {
            name: this.name,
            email: this.email,
            role: this.roles,
            password: this.password,
            phone_number: this.phoneNumber,
          }
        );
        if (res.status === 200 || res.status === 201) {
          this.$router.push({ name: "user-list" });
        }
        console.log(res);
      } catch (error) {
        console.log(error.response.data);
        const errorField = error.response.data;
        this.errorField = errorField;
      }
    },
  },
  async mounted() {
    await this.getUserDetail();
  },
};
</script>

<style lang="scss" scoped>
section {
  &.company {
    width: 80%;
    max-height: 100vh;
    padding: 30px;
    overflow: scroll;
    background-color: $anti-flash;

    .nav-header {
      display: flex;
      gap: 3rem;
      align-items: center;
      padding-bottom: 1.5rem;
      border-bottom: 2px solid $font-color;
      margin-bottom: 1.5rem;

      h4 {
        font-weight: $font-weight-semibold;
      }
    }

    .wrapper {
      width: 70%;
      background-color: $white;
      border-radius: 7px;
      height: fit-content;
      padding: 20px;

      .input-contain {
        width: 100%;
        p {
          margin-bottom: 5px;
          span {
            color: #ff2828;
          }
        }

        .horizontal {
          width: 100%;
          flex-wrap: wrap;
          flex: unset;
        }

        .radios {
          padding: 15px;
          .radio {
            width: 30%;
            color: $font-color;
            font-weight: $font-weight-medium;
            flex: unset;
          }
        }
      }

      .buttons {
        display: flex;
        gap: 1rem;
        margin-top: 5rem;
        align-items: center;

        .cancel {
          height: 100%;
          padding: 7px 15px;
          color: $font-color;
          background-color: $gainsboro;
          border-radius: 3px;
          text-decoration: none;
          font-size: 15px;
          min-height: fit-content !important;
        }

        .save-button {
          color: $font-color;
          background-color: $button-color;
          font-size: 15px;
          min-height: fit-content !important;
          text-transform: capitalize;
        }
      }
    }
  }
}

.title {
  color: $font-color !important;
  font-weight: $font-weight-semibold !important;
}
</style>
