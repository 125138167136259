<template>
  <v-app>
    <section class="search-property">
      <div class="background">
        <v-img
          src="@/assets/bg-search-prop.png"
          width="100vw"
          height="220px"
          cover
        ></v-img>
      </div>
      <div class="search-bar">
        <v-container>
          <v-row>
            <div class="wrapper">
              <v-text-field
                append-inner-icon="mdi-filter"
                density="compact"
                placeholder="Cari Properti"
                prepend-inner-icon="mdi-magnify"
                variant="solo"
                v-model="keyword"
                @input="postFilter()"
                @click:append-inner="toggleFilter"
              ></v-text-field>
            </div>
          </v-row>
        </v-container>
      </div>

      <!-- Filter Pop Up -->
      <div class="filter-search" v-if="visible">
        <v-container>
          <v-row>
            <div class="wrapper">
              <v-form ref="form">
                <div class="dialog-head">
                  <p>Filter</p>
                  <v-img
                    src="@/assets/close-btn.png"
                    width="30px"
                    @click="toggleFilter"
                    class="close-btn"
                  ></v-img>
                </div>
                <div class="filters">
                  <!-- List of checkboxes -->
                  <div class="checkbox-contain">
                    <h5>Lokasi</h5>
                    <div class="filter-wrapper">
                      <v-checkbox
                        v-for="propertyType in propertyTypes"
                        :key="propertyType.id"
                        :label="propertyType.label"
                        v-model="typeProp"
                        density="compact"
                        :value="propertyType.value"
                        color="#FFA600"
                        class="check-prop"
                      >
                      </v-checkbox>
                    </div>
                  </div>
                  <div class="filter-group">
                    <h5>Area</h5>
                    <div class="filter-wrapper">
                      <div class="input-contain full-size">
                        <p>Lokasi</p>
                        <v-select
                          v-model="location"
                          :items="locItems"
                          item-title="nama"
                          item-value="id"
                          chips
                          multiple
                          placeholder="Tipe Properti"
                          variant="outlined"
                          base-color="#818A91"
                          density="compact"
                          bg-color="#fff"
                          color="#363636"
                        ></v-select>
                      </div>
                    </div>
                  </div>

                  <div class="filter-group">
                    <h5>Harga</h5>
                    <div class="filter-wrapper">
                      <div class="input-contain">
                        <p>Harga Minimal</p>
                        <v-text-field
                          class="input-template"
                          v-model="minPrice"
                          placeholder="Harga Minimal"
                          density="compact"
                          variant="outlined"
                          base-color="#818A91"
                          type="number"
                          bg-color="#fff"
                          color="#363636"
                        ></v-text-field>
                      </div>
                      <div class="input-contain">
                        <p>Harga Maksimal</p>
                        <v-text-field
                          class="input-template"
                          v-model="maxPrice"
                          placeholder="Harga Maksimal"
                          density="compact"
                          variant="outlined"
                          base-color="#818A91"
                          bg-color="#fff"
                          color="#363636"
                        ></v-text-field>
                      </div>
                    </div>
                  </div>

                  <div class="filter-group">
                    <h5>Luas Tanah</h5>
                    <div class="filter-wrapper">
                      <div class="input-contain">
                        <p>Min. Luas Tanah</p>
                        <v-text-field
                          class="input-template"
                          v-model="minArea"
                          placeholder="Luas Tanah"
                          density="compact"
                          type="number"
                          variant="outlined"
                          base-color="#818A91"
                          bg-color="#fff"
                          color="#363636"
                        ></v-text-field>
                      </div>
                      <div class="input-contain">
                        <p>Maks. Luas Tanah</p>
                        <v-text-field
                          class="input-template"
                          v-model="maxArea"
                          placeholder="Luas Tanah"
                          density="compact"
                          type="number"
                          variant="outlined"
                          base-color="#818A91"
                          bg-color="#fff"
                          color="#363636"
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                  <div class="filter-group">
                    <h5>Kamar Tidur & Kamar Mandi</h5>
                    <div class="filter-wrapper">
                      <div class="input-contain">
                        <p>Kamar Tidur</p>
                        <v-text-field
                          class="input-template"
                          v-model="bedroom"
                          placeholder="Jumlah Kamar"
                          density="compact"
                          variant="outlined"
                          base-color="#818A91"
                          type="number"
                          bg-color="#fff"
                          color="#363636"
                        ></v-text-field>
                      </div>
                      <div class="input-contain">
                        <p>Kamar Mandi</p>
                        <v-text-field
                          class="input-template"
                          v-model="bathroom"
                          placeholder="Jumlah Kamar Mandi"
                          density="compact"
                          variant="outlined"
                          base-color="#818A91"
                          type="number"
                          bg-color="#fff"
                          color="#363636"
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="dialog-foot">
                  <p @click="reset" class="clear-filter">Hapus Filter</p>
                  <v-btn
                    class="find-property"
                    variant="flat"
                    @click="callToggleAndPostFilter"
                    >Cari</v-btn
                  >
                </div>
              </v-form>
            </div>
          </v-row>
        </v-container>
      </div>
    </section>

    <section class="search-result">
      <v-container>
        <v-row>
          <div class="loading-anim">
            <v-progress-circular
              v-if="loading"
              indeterminate
              size="40"
            ></v-progress-circular>
          </div>
        </v-row>
        <v-row>
          <div class="wrapper-list">
            <p v-if="notFound" class="result-notfound">
              Tidak ada hasil yang cocok.
            </p>
            <div
              class="card"
              v-for="property in propAll.data"
              :key="property.id"
            >
              <router-link :to="`${property.path}`" class="property-card">
                <v-img
                  class="property-img"
                  height="200px"
                  aspect-ratio="3/4"
                  cover
                  v-if="property.images && property.images.length > 0"
                  :src="this.$apiImg + property.images[0].image_url"
                ></v-img>
                <v-img
                  class="property-img"
                  height="200px"
                  aspect-ratio="3/4"
                  cover
                  v-else
                  src="@/assets/property-img.png"
                ></v-img>
                <div class="mid-section">
                  <p class="price">Rp{{ formatPrice(property.harga) }}</p>
                  <p class="type">{{ property.tipe_properti }}</p>
                  <p class="property-name">{{ property.judul }}</p>
                  <p class="location">
                    <span
                      ><v-img
                        src="@/assets/location.png"
                        width="17px"
                        height="17px"
                        contain
                      ></v-img></span
                    >{{ property.area }}
                  </p>
                </div>
                <div class="bottom-section">
                  <div class="wrapper">
                    <div class="top-text">
                      <v-img
                        src="@/assets/bed.png"
                        width="20px"
                        max-width="20px"
                        contain
                        height="20px"
                      ></v-img>
                      <p class="qty">{{ property.kamar_tidur }}</p>
                    </div>
                    <p class="title">Kamar</p>
                  </div>
                  <div class="wrapper">
                    <div class="top-text">
                      <v-img
                        src="@/assets/shower.png"
                        width="20px"
                        max-width="20px"
                        contain
                        height="20px"
                      ></v-img>
                      <p class="qty">{{ property.kamar_mandi }}</p>
                    </div>
                    <p class="title">Toilet</p>
                  </div>
                  <div class="wrapper">
                    <div class="top-text">
                      <v-img
                        src="@/assets/ruler.png"
                        width="25px"
                        max-width="25px"
                        contain
                        height="25px"
                      ></v-img>
                      <p class="qty">
                        {{ property.luas_tanah }}<span>m<sup>2</sup></span>
                      </p>
                    </div>
                    <p class="title">Luas Tanah</p>
                  </div>
                  <div class="wrapper">
                    <div class="top-text">
                      <v-img
                        src="@/assets/area-building.png"
                        width="20px"
                        max-width="20px"
                        contain
                        height="20px"
                      ></v-img>
                      <p class="qty">
                        {{ property.luas_tanah }}<span>m<sup>2</sup></span>
                      </p>
                    </div>
                    <p class="title">Luas Bangunan</p>
                  </div>
                </div>
              </router-link>

              <div class="creator-section">
                <div class="creator">
                  <v-img
                    :src="this.$apiImg + property.creator.image_url"
                    cover
                    min-width="25px"
                    max-width="25px"
                    min-height="25px"
                    max-height="25px"
                    class="creator-img"
                  ></v-img>
                  <p class="name">{{ property.creator.name }}</p>
                </div>
                <a
                  :href="`https://wa.me/${property.creator.phone_number}`"
                  target="_blank"
                  class="creator-wa"
                >
                  <v-img
                    src="@/assets/whatsapp-logo.png"
                    contain
                    min-width="20px"
                    max-width="20px"
                    min-height="20px"
                    max-height="20px"
                    class="wa-img"
                  ></v-img>
                  <p class="wa">WhatsApp</p>
                </a>
              </div>
            </div>
          </div>
        </v-row>
      </v-container>
    </section>
    <v-pagination
      v-if="!notFound && !loading"
      v-model="page"
      :length="propAll.last_page"
      :total-visible="10"
      class="pagination"
      style="margin-bottom: 2rem"
    ></v-pagination>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      propertyTypes: [
        // Corrected variable name from "propertyTypes" to "propertyType"
        { id: 1, label: "Apartemen", value: "Apartemen" },
        { id: 2, label: "Rumah", value: "Rumah" },
        { id: 3, label: "Gudang", value: "Gudang" },
        { id: 4, label: "Ruko", value: "Ruko" },
        { id: 5, label: "Kos", value: "Kos" },
        { id: 6, label: "Tanah", value: "Tanah" },
        // Add more items as needed
      ],
      chosen: [],
      selectAll: false,
      visible: false,
      propAll: [],
      page: 1,
      loading: false,
      error: false,
      currentPage: 1,
      locItems: [],
      keyword: "",
      minArea: null,
      maxArea: null,
      minPrice: null,
      maxPrice: null,
      bathroom: null,
      bedroom: null,
      location: [],
      typeProp: [],
      notFound: false,
    };
  },
  watch: {
    page() {
      this.postFilter();
    },
  },
  methods: {
    toggleAll() {
      // Toggle all checkboxes based on the "Select All" checkbox status
      this.propertyTypes.forEach((item) => (item.selected = this.selectAll));
    },
    callToggleAndPostFilter() {
      this.toggleFilter();
      this.page = 1;
      this.postFilter();
    },
    toggleFilter() {
      this.visible = !this.visible; // Toggle the visibility
    },
    async getCity() {
      try {
        const res = await axios.get(`${this.$api}/area/semarang`);
        this.locItems = res.data.data;
        console.log(this.cityItems);
      } catch (error) {
        console.error(error);
      }
    },
    reset() {
      this.keyword = "";
      this.minArea = null;
      this.maxArea = null;
      this.minPrice = null;
      this.maxPrice = null;
      this.bathroom = null;
      this.bedroom = null;
      this.locItems = [];
      this.location = [];
      this.typeProp = [];
    },

    formatPrice(value) {
      const formattedValue = (value / 1).toFixed(0).replace(".", ",");
      return formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    async postFilter() {
      try {
        this.notFound = false;

        this.loading = true;
        const payload = {
          status: "Dijual",
          page: this.page,
        };
        if (this.status) {
          payload.status = "Dijual";
        }
        if (this.keyword) {
          payload.kata_kunci = this.keyword;
        }

        if (this.typeProp.length > 0) {
          payload.tipe_properti = this.typeProp;
        }

        if (this.location.length > 0) {
          payload.kecamatan_id = this.location;
        }

        if (this.minArea !== null) {
          payload.min_luas_tanah = this.minArea;
        }

        if (this.maxArea !== null) {
          payload.max_luas_tanah = this.maxArea;
        }

        if (this.minPrice !== null) {
          payload.min_harga = this.minPrice;
        }

        if (this.maxPrice !== null) {
          payload.max_harga = this.maxPrice;
        }

        if (this.bathroom !== null) {
          payload.kamar_mandi = this.bathroom;
        }

        if (this.bedroom !== null) {
          payload.kamar_tidur = this.bedroom;
        }

        const res = await axios.post(`${this.$api}/property/filter`, payload);
        if (res.status === 200 || res.status === 201) {
          this.propAll = res.data.data;
        } else {
          this.error = true;
        }
        this.error = false;
        console.log(this.propAll);
      } catch (error) {
        this.notFound = true;
        console.log(error.message);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    await this.getCity();
    await this.postFilter();
  },
};
</script>

<style lang="scss" scoped>
.find-property {
  width: fit-content;
  text-align: center;
  color: $font-color;
  background-color: $button-color;
  text-decoration: none;
  font-size: 15px;
  font-weight: $font-weight-semibold;
}
section {
  padding: 50px 0;
  &.search-property {
    padding: 0;
    .search-bar {
      .wrapper {
        width: 75%;
        margin: auto;
        position: relative;
        top: -27px;
      }
    }

    .filter-search {
      position: fixed;
      width: 100vw;
      height: 100vh;
      overflow-y: scroll;
      background: rgba(0, 0, 0, 0.3);
      top: 0;
      left: 0;
      z-index: 9999;
      padding: 3rem 0;

      .wrapper {
        width: 50%;
        background-color: $white;
        margin: auto;
        border-radius: 7px;

        .dialog-head {
          padding: 15px 0;
          text-align: center;
          border-radius: 7px 7px 0 0;
          background-color: $gainsboro;
          position: relative;
          display: flex;
          justify-content: center;

          p {
            font-size: 18px;
            font-weight: $font-weight-semibold;
          }

          .close-btn {
            position: absolute;
            right: 30px;
          }
        }

        .filters {
          padding: 50px 30px;

          h5 {
            font-size: 18px;
            font-weight: $font-weight-semibold;
          }

          .checkbox-contain {
            .filter-wrapper {
              margin-left: 10px;
              display: flex;
              flex-wrap: wrap;
              margin-top: 10px;

              .check-prop {
                width: 50%;
              }
            }
          }

          .filter-group {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .filter-wrapper {
              display: flex;
              margin-left: 1rem;
              gap: 20px;
              .input-contain {
                width: 50%;

                p {
                  margin-bottom: 10px;
                  font-weight: $font-weight-medium;
                }
              }
              .full-size {
                width: 100% !important;
              }
            }
          }
        }

        .dialog-foot {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px 30px;
          background-color: $gainsboro;
          border-radius: 0 0 7px 7px;
          .clear-filter {
            cursor: pointer;
          }

          .find-property {
            width: fit-content;
            text-align: center;
            color: $font-color;
            background-color: $button-color;
            text-decoration: none;
            font-size: 15px;
            padding: 0px 3rem;
            font-weight: $font-weight-semibold;
            text-transform: capitalize;
          }
        }
      }
    }
  }

  &.search-result {
    padding-top: 0 !important;
    .loading-anim {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
    }
    .wrapper-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      height: fit-content;
      .result-notfound {
        width: 100%;
        text-align: center;
      }

      .card {
        width: calc(25% - 24px);
        display: flex;
        flex-direction: column;
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
        background-color: $white;
        border-radius: 10px;
        max-height: 485px;

        .property-card {
          text-decoration: none;
          display: flex;
          flex-direction: column;

          .property-img {
            border-radius: 10px 10px 0 0;
          }

          .mid-section {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 15px;

            .price {
              font-size: 20px;
              font-weight: $font-weight-semibold;
              color: $black;
            }

            .type {
              font-size: 12px;
              font-weight: $font-weight-semibold;
              text-transform: uppercase;
              color: $black;
            }

            .property-name {
              font-size: 14px;
              color: $black;
              font-weight: $font-weight-medium;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .location {
              display: flex;
              gap: 3px;
              align-items: center;
              color: $roman-silver;
              font-size: 12px;
              font-weight: $font-weight-medium;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .bottom-section {
            padding: 15px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1.5px solid #dedede;
            border-top: 1.5px solid #dedede;

            .wrapper {
              display: flex;
              flex-direction: column;
              gap: 5px;
              width: fit-content;

              .top-text {
                display: flex;
                gap: 10px;
                align-items: center;

                .qty {
                  font-weight: $font-weight-semibold;
                  font-size: 16px;
                  color: $roman-silver;
                  line-height: 18px;

                  span {
                    font-size: 12px;
                  }
                }
              }

              .title {
                color: $roman-silver;
                font-weight: $font-weight-medium;
                font-size: 9px;
              }
            }
          }
        }
        .creator-section {
          padding: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .creator {
            gap: 10px;
            display: flex;
            align-items: center;
            .creator-img {
              border-radius: 100px;
            }

            .name {
              color: $roman-silver;
              font-size: 13px;
            }
          }
          .creator-wa {
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 5px 15px;
            border-radius: 30px;
            text-decoration: none;
            border: 1px solid #33bb47;

            .wa {
              color: #33bb47;
              font-size: 10px;
              font-weight: $font-weight-medium;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1266px) {
  section {
    &.search-result {
      .wrapper-list {
        gap: 2%;
        .card {
          width: 32%;
        }
      }
    }
  }
}

@media screen and (max-width: $bp-lg) {
  section {
    &.search-property {
      .filter-search {
        .wrapper {
          width: 75%;
        }
      }
    }
  }
}

@media screen and (max-width: $bp-md) {
  section {
    &.search-result {
      .wrapper-list {
        width: 95%;
        margin: auto;
        gap: 4%;
        row-gap: 2rem;
        .card {
          width: 48%;
        }
      }
    }
  }
}

@media screen and (max-width: $bp-sm) {
  section {
    &.search-property {
      .search-bar {
        .wrapper {
          width: 85%;
        }
      }

      .filter-search {
        background-color: $white;
        padding: 0;
        .wrapper {
          width: 100%;

          .filters {
            padding: 1rem;

            .filter-group {
              .filter-wrapper {
                flex-direction: column;
                gap: 0;
                .input-contain {
                  width: 100%;
                }
              }
            }
          }

          .dialog-head,
          .dialog-foot {
            background-color: $white;
          }

          .dialog-head {
            border-bottom: 1.5px solid $roman-silver;
          }
        }
      }
    }

    // &.search-result {
    //   padding-top: 0;
    //   .wrapper-list {
    //     width: 95%;
    //     margin: auto;
    //     row-gap: 2rem;
    //     .property-card {
    //       width: 100%;
    //     }
    //   }
    // }
  }
}

@media screen and (max-width: $bp-xs) {
  section {
    &.search-result {
      padding-top: 0;
      .wrapper-list {
        width: 95%;
        margin: auto;
        row-gap: 2rem;
        .card {
          width: 100%;
        }
      }
    }
  }
}
</style>
