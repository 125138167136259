<template>
  <header>
    <v-row class="ma-0">
      <v-container class="pa-0">
        <div class="header-wrap">
          <div class="logo">
            <v-img
              src="@/assets/logo-header.png"
              height="40px"
              width="100px"
              class="logo-hepi"
            ></v-img>
          </div>
          <button @click="toggleClass" class="hamburger">
            <v-img
              class="hamburg-icon"
              src="@/assets/hamburger.png"
              max-height="30px"
            ></v-img>
          </button>
          <nav>
            <router-link to="/" class="menu">Beranda</router-link>
            <router-link to="/property-for-sell" class="menu">Jual</router-link>
            <router-link to="/property-for-rent" class="menu">Sewa</router-link>
            <router-link to="/article" class="menu">Artikel</router-link>
            <router-link to="/agents" class="menu">Agen</router-link>
            <router-link to="/about" class="menu">Tentang Kami</router-link>
            <router-link to="/contact-us" class="menu"
              >Hubungi Kami</router-link
            >
          </nav>
        </div>
      </v-container>
    </v-row>
  </header>
  <div class="sidebar" :class="{ active: isActive }">
    <nav>
      <router-link to="/" class="menu" @click="toggleClass"
        >Beranda</router-link
      >
      <router-link to="/property-for-sell" class="menu" @click="toggleClass"
        >Jual</router-link
      >
      <router-link to="/property-for-rent" class="menu" @click="toggleClass"
        >Sewa</router-link
      >
      <router-link to="/article" class="menu" @click="toggleClass"
        >Artikel</router-link
      >
      <router-link to="/agents" class="menu" @click="toggleClass"
        >Agen</router-link
      >
      <router-link to="/about" class="menu" @click="toggleClass"
        >Tentang Kami</router-link
      >
      <router-link to="/about" class="menu" @click="toggleClass"
        >Hubungi Kami</router-link
      >
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    toggleClass() {
      this.isActive = !this.isActive; // Toggles the value of isActive between true and false
    },
  },
};
</script>

<style scoped lang="scss">
:v-deep .v-application--wrap {
  min-height: fit-content;
}

header {
  padding: 1rem 0;
  background-color: $secondary-color;
  .header-wrap {
    display: flex;
    justify-content: space-between;

    .hamburger {
      display: none;
    }

    nav {
      display: flex;
      gap: 2rem;
      align-items: center;

      .menu {
        color: $font-color;
        text-decoration: none;
        &.router-link-exact-active {
          font-weight: $font-weight-semibold;
        }
      }
    }
  }
}

.sidebar {
  display: none;
}

@media screen and (max-width: $bp-md) {
  header {
    padding: 1rem;
    .header-wrap {
      nav {
        display: none;
      }

      .logo {
        .logo-hepi {
          width: 70px;
        }
      }

      .hamburger {
        display: block;
        .hamburg-icon {
          width: 25px;
        }
      }
    }
  }

  .sidebar {
    display: block;
    background: $secondary-color;
    -webkit-transition: max-height 0.5s, background 0.5s linear; /* Include max-height and background in the transition */
    -moz-transition: max-height 0.5s, background 0.5s linear;
    -ms-transition: max-height 0.5s, background 0.5s linear;
    -o-transition: max-height 0.5s, background 0.5s linear;
    transition: max-height 0.5s, background 0.5s linear;
    overflow: hidden;
    max-height: 0px;
    z-index: 999;
    position: absolute;
    top: 70px;
    width: 100%;

    &.active {
      background: $secondary-color;
      display: block; /* Add display: block; to make the element visible */
      -webkit-transition: max-height 0.5s, background 0.5s linear;
      -moz-transition: max-height 0.5s, background 0.5s linear;
      -ms-transition: max-height 0.5s, background 0.5s linear;
      -o-transition: max-height 0.5s, background 0.5s linear;
      transition: max-height 0.5s, background 0.5s linear;
      max-height: 800px;
      z-index: 999;
      position: absolute;
      top: 70px;
      width: 100%;
    }

    nav {
      display: flex;
      flex-direction: column;
      padding: 2rem;
      .menu {
        color: $font-color;
        padding: 10px 20px;
        text-decoration: none;
        color: $roman-silver;
        &.router-link-exact-active {
          font-weight: $font-weight-medium;
          border-radius: 5px;
          background: rgba(255, 166, 0, 0.15);
          color: $primary-color;
        }
      }
    }
  }
}
</style>
