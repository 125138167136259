<template>
  <section class="company">
    <div class="top-section">
      <div class="above">
        <h4>Properti Unggulan</h4>
        <profile />
      </div>
      <div class="add-search">
        <div class="filter">
          <v-select
            v-model="propertyType"
            @update:modelValue="postFilter()"
            :items="propertyTypeItems"
            item-title="items"
            item-value="value"
            prepend-inner-icon="mdi-filter"
            chips
            multiple
            clearable
            placeholder="Filter"
            variant="outlined"
            base-color="#818A91"
            density="compact"
            bg-color="#fff"
            color="#363636"
          ></v-select>
        </div>
        <div class="search">
          <v-text-field
            density="compact"
            placeholder="Cari Properti"
            append-inner-icon="mdi-magnify"
            variant="solo"
            @input="postFilter()"
            v-model="keyword"
            max-wid
          ></v-text-field>
        </div>
      </div>
    </div>
    <h5 class="title-sect">Pin Unggulan</h5>

    <p class="filter-stat" v-if="laodHighlight">Loading...</p>
    <p class="filter-stat" v-if="highLen == 0">
      Tidak Ada Properti Unggulan...
    </p>
    <p class="filter-stat" v-if="errHigh">Error! Data tidak dapat dimuat.</p>

    <div class="wrapper-list" v-if="!error">
      <div
        class="wrap-card"
        v-for="highlight in propHighlight.data"
        :key="highlight.data.id"
      >
        <div class="edit-delete">
          <div class="btn-edit-delete" @click="deleteHighlight(highlight.id)">
            <v-img class="btn-icon" src="@/assets/unpin.png"></v-img>
          </div>
        </div>
        <router-link
          :to="`${highlight.data.path}/preview`"
          class="property-card"
        >
          <v-img
            class="property-img"
            height="200px"
            aspect-ratio="3/4"
            cover
            v-if="highlight.data.images && highlight.data.images.length > 0"
            :src="this.$apiImg + highlight.data.images[0].image_url"
          ></v-img>
          <v-img
            class="property-img"
            height="200px"
            aspect-ratio="3/4"
            cover
            v-else
            src="@/assets/property-img.png"
          ></v-img>
          <div class="mid-section">
            <p class="price">Rp{{ formatPrice(highlight.data.harga) }}</p>
            <p class="type">{{ highlight.data.tipe_properti }}</p>
            <p class="property-name">{{ highlight.data.judul }}</p>
            <p class="location">
              <span
                ><v-img
                  src="@/assets/location.png"
                  width="17px"
                  height="17px"
                  contain
                ></v-img></span
              >{{ highlight.data.area }}
            </p>
          </div>
          <div class="bottom-section">
            <div class="wrapper">
              <div class="top-text">
                <v-img
                  src="@/assets/bed.png"
                  width="20px"
                  max-width="20px"
                  contain
                  height="20px"
                ></v-img>
                <p class="qty">{{ highlight.data.kamar_tidur }}</p>
              </div>
              <p class="title">Kamar</p>
            </div>
            <div class="wrapper">
              <div class="top-text">
                <v-img
                  src="@/assets/shower.png"
                  width="20px"
                  max-width="20px"
                  contain
                  height="20px"
                ></v-img>
                <p class="qty">{{ highlight.data.kamar_mandi }}</p>
              </div>
              <p class="title">Toilet</p>
            </div>
            <div class="wrapper">
              <div class="top-text">
                <v-img
                  src="@/assets/ruler.png"
                  width="25px"
                  max-width="25px"
                  contain
                  height="25px"
                ></v-img>
                <p class="qty">
                  {{ highlight.data.luas_tanah }}<span>m<sup>2</sup></span>
                </p>
              </div>
              <p class="title">Luas Tanah</p>
            </div>
            <div class="wrapper">
              <div class="top-text">
                <v-img
                  src="@/assets/area-building.png"
                  width="20px"
                  max-width="20px"
                  contain
                  height="20px"
                ></v-img>
                <p class="qty">
                  {{ highlight.data.luas_tanah }}<span>m<sup>2</sup></span>
                </p>
              </div>
              <p class="title">Luas Bangunan</p>
            </div>
          </div>
        </router-link>
        <div class="creator-section">
          <div class="creator">
            <v-img
              :src="this.$apiImg + highlight.data.creator.image_url"
              cover
              min-width="25px"
              max-width="25px"
              min-height="25px"
              max-height="25px"
              class="creator-img"
            ></v-img>
            <p class="name">{{ highlight.data.creator.name }}</p>
          </div>
          <a
            :href="`https://wa.me/${highlight.data.creator.phone_number}`"
            target="_blank"
            class="creator-wa"
          >
            <v-img
              src="@/assets/whatsapp-logo.png"
              contain
              min-width="20px"
              max-width="20px"
              min-height="20px"
              max-height="20px"
              class="wa-img"
            ></v-img>
            <p class="wa">WhatsApp</p>
          </a>
        </div>
      </div>
    </div>
    <h5 class="title-sect">Semua Unggulan</h5>
    <p class="filter-stat" v-if="loading">Loading...</p>
    <p class="filter-stat" v-if="error">Data not found.</p>
    <p class="filter-stat" v-if="errAll">Error! Data tidak dapat dimuat.</p>
    <div class="wrapper-list" v-if="!error">
      <div
        class="wrap-card"
        v-for="property in propAll.data"
        :key="property.id"
      >
        <div class="edit-delete">
          <div class="edit-delete">
            <div
              class="btn-edit-delete"
              v-if="property.is_highlighted == 0"
              @click="addHighlight(property.unggulan.id)"
            >
              <v-img class="btn-icon" src="@/assets/pin.png"></v-img>
            </div>

            <div
              class="btn-edit-delete"
              @click="removeHighlight(property.unggulan.id)"
              v-else
            >
              <v-img class="btn-icon" src="@/assets/unpin.png"></v-img>
            </div>
          </div>
        </div>
        <router-link :to="`${property.path}/preview`" class="property-card">
          <v-img
            class="property-img"
            height="200px"
            aspect-ratio="3/4"
            cover
            v-if="property.images && property.images.length > 0"
            :src="this.$apiImg + property.images[0].image_url"
          ></v-img>
          <v-img
            class="property-img"
            height="200px"
            aspect-ratio="3/4"
            cover
            v-else
            src="@/assets/property-img.png"
          ></v-img>
          <div class="mid-section">
            <p class="price">Rp{{ formatPrice(property.harga) }}</p>
            <p class="type">{{ property.tipe_properti }}</p>
            <p class="property-name">{{ property.judul }}</p>
            <p class="location">
              <span
                ><v-img
                  src="@/assets/location.png"
                  width="17px"
                  height="17px"
                  contain
                ></v-img></span
              >{{ property.area }}
            </p>
          </div>
          <div class="bottom-section">
            <div class="wrapper">
              <div class="top-text">
                <v-img
                  src="@/assets/bed.png"
                  width="20px"
                  max-width="20px"
                  contain
                  height="20px"
                ></v-img>
                <p class="qty">{{ property.kamar_tidur }}</p>
              </div>
              <p class="title">Kamar</p>
            </div>
            <div class="wrapper">
              <div class="top-text">
                <v-img
                  src="@/assets/shower.png"
                  width="20px"
                  max-width="20px"
                  contain
                  height="20px"
                ></v-img>
                <p class="qty">{{ property.kamar_mandi }}</p>
              </div>
              <p class="title">Toilet</p>
            </div>
            <div class="wrapper">
              <div class="top-text">
                <v-img
                  src="@/assets/ruler.png"
                  width="25px"
                  max-width="25px"
                  contain
                  height="25px"
                ></v-img>
                <p class="qty">
                  {{ property.luas_tanah }}<span>m<sup>2</sup></span>
                </p>
              </div>
              <p class="title">Luas Tanah</p>
            </div>
            <div class="wrapper">
              <div class="top-text">
                <v-img
                  src="@/assets/area-building.png"
                  width="20px"
                  max-width="20px"
                  contain
                  height="20px"
                ></v-img>
                <p class="qty">
                  {{ property.luas_tanah }}<span>m<sup>2</sup></span>
                </p>
              </div>
              <p class="title">Luas Bangunan</p>
            </div>
          </div>
        </router-link>
        <div class="creator-section">
          <div class="creator">
            <v-img
              :src="this.$apiImg + property.creator.image_url"
              cover
              min-width="25px"
              max-width="25px"
              min-height="25px"
              max-height="25px"
              class="creator-img"
            ></v-img>
            <p class="name">{{ property.creator.name }}</p>
          </div>
          <a
            :href="`https://wa.me/${property.creator.phone_number}`"
            target="_blank"
            class="creator-wa"
          >
            <v-img
              src="@/assets/whatsapp-logo.png"
              contain
              min-width="20px"
              max-width="20px"
              min-height="20px"
              max-height="20px"
              class="wa-img"
            ></v-img>
            <p class="wa">WhatsApp</p>
          </a>
        </div>
      </div>
    </div>
    <v-pagination
      v-model="page"
      :length="propAll.last_page"
      :total-visible="5"
      class="pagination"
    ></v-pagination>
  </section>

  <div class="warning" v-if="popUpWarn">
    <div class="wrapper">
      <v-img
        class="close-btn"
        src="@/assets/close-btn.png"
        @click="popUpWarn = false"
      ></v-img>
      <v-img class="warning-icon" src="@/assets/warning.png"></v-img>
      <p class="prompt">
        Properti yang anda pin telah mencapai batas.<br />
        Hapus salah satu pin untuk melanjutkan
      </p>
      <div class="decision">
        <div class="choice yes" @click="popUpWarn = false">Lanjut</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import profile from "@/components/component/ProfileNav.vue";

export default {
  data() {
    return {
      filter: ["Dijual", "Disewakan"],
      filterAgent: "",
      propertyType: [],
      keyword: "",
      propertyTypeItems: [
        {
          items: "Apartemen",
          value: "apartemen",
        },
        {
          items: "Ruko",
          value: "ruko",
        },
        {
          items: "Rumah",
          value: "rumah",
        },
        {
          items: "Rumah Kos",
          value: "rumah kos",
        },
        {
          items: "Gudang",
          value: "gudang",
        },
        {
          items: "Tanah",
          value: "tanah",
        },
      ],
      itemsPerPage: 8,
      currentPage: 1,
      propAll: [],
      page: 1,
      loading: false,
      error: false,
      confirmLogout: false,
      menuNav: false,
      propHighlight: [],
      highLen: null,
      popUpWarn: false,
      laodHighlight: false,
      errAll: false,
      errHigh: false,
    };
  },

  watch: {
    page() {
      this.postFilter();
    },
  },

  methods: {
    warnMaxxed() {
      if (this.highLen > 2) {
        this.popUpWarn = true;
      }
    },
    async addHighlight(propertyId) {
      if (this.highLen > 2) {
        this.popUpWarn = true;
      } else {
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const response = await axios.post(
            `${this.$api}/property/set-highlight/id/${propertyId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.status == 200) {
            await this.postFilter();
            await this.getPropHighlight();
          }
        } catch (error) {
          console.error("Error deleting property:", error);
        }
      }
    },
    async deleteHighlight(propertyId) {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.post(
          `${this.$api}/property/remove-highlight/id/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status == 200) {
          await this.postFilter();
          await this.getPropHighlight();
        }
      } catch (error) {
        console.error("Error deleting property:", error);
      }
    },
    toggleLogout() {
      this.confirmLogout = !this.confirmLogout;
    },
    logout() {
      console.log("Logging out");
      localStorage.clear();

      const isDataCleared = localStorage.getItem("data") === null;

      if (isDataCleared) {
        console.log("Local Storage Cleared");
        this.$router.push({ name: "login" });
        console.log("Router navigated to login");
      } else {
        console.log("Unable to clear local storage");
      }
    },
    toggleMenu() {
      this.menuNav = !this.menuNav;
    },
    async getPropHighlight() {
      try {
        this.laodHighlight = true;
        const res = await axios.get(`${this.$api}/property/unggulan/highlight`);
        this.propHighlight = res.data;
        this.highLen = res.data.data.length;

        console.log(this.propHighlight);
        console.log(this.highLen);
      } catch (error) {
        console.log(error);
        this.errHigh = true;
      } finally {
        this.laodHighlight = false;
      }
    },
    async postFilter() {
      try {
        this.loading = true;
        const payload = {
          page: 1,
          is_unggulan: 1,
        };

        if (this.keyword) {
          payload.kata_kunci = this.keyword;
        }

        if (this.propertyType.length > 0) {
          payload.tipe_properti = this.propertyType;
        }

        const res = await axios.post(`${this.$api}/property/filter`, payload);
        if (res.status === 200 || res.status === 201) {
          this.propAll = res.data.data;
        } else {
          this.error = true;
        }
        this.error = false;
        console.log(this.propAll);
      } catch (error) {
        console.log(error.message);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    formatPrice(value) {
      const formattedValue = (value / 1).toFixed(0).replace(".", ",");
      return formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  components: {
    profile,
  },

  async mounted() {
    await this.postFilter();
    await this.getPropHighlight();
  },
};
</script>

<style lang="scss" scoped>
.warning {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;

  .wrapper {
    background-color: $white;
    padding: 40px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .prompt {
      font-size: 20px;
      color: $font-color;
      text-align: center;
      margin-bottom: 3rem;
    }

    .close-btn {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    .warning-icon {
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
    }

    h4 {
      font-weight: $font-weight-bold;
      color: $primary-color;
    }

    .prompt {
      font-size: 14px;
      margin-bottom: 30px;
    }

    .decision {
      width: 100%;
      display: flex;
      gap: 1.5rem;
      justify-content: center;

      .choice {
        width: 50%;
        padding: 0.5rem 0;
        justify-content: center;
        display: flex;
        cursor: pointer;
        border-radius: 5px;

        &.no {
          background-color: $gainsboro;
        }
        &.yes {
          background-color: $primary-color;
        }
      }
    }
  }
}
section {
  &.company {
    width: 80%;
    max-height: 100vh;
    overflow-y: auto;
    padding: 30px;
    background-color: $anti-flash;

    .top-section {
      .above {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.2rem;
        align-items: center;
      }
      h4 {
        width: fit-content;
        font-weight: $font-weight-semibold;
      }

      .add-search {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 1.5rem;
        border-top: 2px solid $font-color;

        .add-btn,
        .search {
          width: 250px;
        }

        .add-btn {
          background-color: $button-color;
          text-transform: capitalize;
          font-size: 16px;
          height: 45px;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $black;
          text-decoration: none;
          // font-size: 20px;
          font-weight: $font-weight-medium;
        }
      }

      .filter {
        width: 250px;
      }
    }

    .filter-stat {
      text-align: center;
      width: 100%;
      margin: 2rem 0;
      color: $roman-silver;
    }

    .title-sect {
      margin-bottom: 2rem;
    }

    .wrapper-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      margin-bottom: 3rem;
      .wrap-card {
        width: calc(25% - 24px);
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
        background-color: $white;
        border-radius: 10px;
        position: relative;
        display: flex;
        flex-direction: column;

        .edit-delete {
          display: flex;
          gap: 7px;
          position: absolute;
          z-index: 2;
          top: 10px;
          right: 10px;

          .btn-edit-delete {
            min-width: unset;
            padding: 10px;
            background: rgba(225, 225, 225, 0.8);
            cursor: pointer;
            border-radius: 8px;

            .btn-icon {
              width: 20px;
              height: 20px;
            }
          }
        }
        .property-card {
          text-decoration: none;
          z-index: 1;

          .property-img {
            border-radius: 10px 10px 0 0;
          }

          .mid-section {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 20px 15px;

            .price {
              font-size: 20px;
              font-weight: $font-weight-semibold;
              color: $black;
            }

            .type {
              font-size: 12px;
              font-weight: $font-weight-semibold;
              text-transform: uppercase;
              color: $black;
            }

            .property-name {
              color: $black;
              font-weight: $font-weight-medium;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .location {
              display: flex;
              gap: 3px;
              align-items: center;
              color: $roman-silver;
              font-size: 12px;
              font-weight: $font-weight-medium;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .bottom-section {
            padding: 20px 15px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1.5px solid $gainsboro;

            .wrapper {
              display: flex;
              flex-direction: column;
              gap: 5px;
              width: fit-content;

              .top-text {
                display: flex;
                gap: 10px;
                align-items: center;

                .qty {
                  font-weight: $font-weight-semibold;
                  font-size: 18px;
                  color: $roman-silver;
                  line-height: 18px;

                  span {
                    font-size: 12px;
                  }
                }
              }

              .title {
                color: $roman-silver;
                font-weight: $font-weight-medium;
                font-size: 9px;
              }
            }
          }

          .post-detail {
            display: flex;
            gap: 10px;
            padding: 20px 15px;
            align-items: center;
            .group {
              display: flex;
              align-items: center;

              p {
                font-size: 13px;
                color: $roman-silver;
              }
            }

            .poster {
              width: 55%;
              p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .posted-at {
              width: 45%;
              justify-content: flex-end;
            }
          }
        }
      }
      .creator-section {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .creator {
          gap: 10px;
          display: flex;
          align-items: center;
          .creator-img {
            border-radius: 100px;
          }

          .name {
            color: $roman-silver;
            font-size: 13px;
          }
        }
        .creator-wa {
          display: flex;
          gap: 10px;
          align-items: center;
          padding: 5px 15px;
          border-radius: 30px;
          text-decoration: none;
          border: 1px solid #33bb47;

          .wa {
            color: #33bb47;
            font-size: 10px;
            font-weight: $font-weight-medium;
          }
        }
      }
    }

    .pagination {
      margin-top: 2rem;
    }
  }
}
</style>
