<template>
  <section class="company">
    <div class="top-section">
      <div class="above">
        <h4>Properti</h4>
        <profile />
      </div>
      <div class="add-search">
        <router-link
          v-if="roles != 'agent'"
          to="/admin/add-properties"
          class="add-btn"
          size="large"
          ><span>+</span> Tambah Properti
        </router-link>
        <div class="search">
          <v-text-field
            density="compact"
            placeholder="Cari Properti"
            append-inner-icon="mdi-magnify"
            variant="solo"
            @input="postFilter()"
            v-model="keyword"
            max-wid
          ></v-text-field>
        </div>
      </div>
      <div class="filter">
        <v-select
          v-model="propertyType"
          @update:modelValue="postFilter()"
          :items="propertyTypeItems"
          item-title="items"
          item-value="value"
          prepend-inner-icon="mdi-filter"
          chips
          multiple
          clearable
          placeholder="Filter"
          variant="outlined"
          base-color="#818A91"
          density="compact"
          bg-color="#fff"
          color="#363636"
        ></v-select>
      </div>
    </div>
    <p class="filter-stat" v-if="loading">Loading...</p>
    <p class="filter-stat" v-if="error">Data not found.</p>
    <div class="wrapper-list" v-if="!error">
      <div
        class="wrap-card"
        v-for="property in propAll.data"
        :key="property.id"
      >
        <router-link :to="`${property.path}/preview`" class="property-card">
          <v-img
            class="property-img"
            height="200px"
            aspect-ratio="3/4"
            cover
            v-if="property.images && property.images.length > 0"
            :src="this.$apiImg + property.images[0].image_url"
          ></v-img>
          <v-img
            class="property-img"
            height="200px"
            aspect-ratio="3/4"
            cover
            v-else
            src="@/assets/property-img.png"
          ></v-img>
          <div class="mid-section">
            <p class="price">Rp{{ formatPrice(property.harga) }}</p>
            <p class="type">{{ property.tipe_properti }}</p>
            <p class="property-name">{{ property.judul }}</p>
            <p class="location">
              <span
                ><v-img
                  src="@/assets/location.png"
                  width="17px"
                  height="17px"
                  contain
                ></v-img></span
              >{{ property.area }}
            </p>
          </div>
          <div class="bottom-section">
            <div class="wrapper">
              <div class="top-text">
                <v-img
                  src="@/assets/bed.png"
                  width="20px"
                  max-width="20px"
                  contain
                  height="20px"
                ></v-img>
                <p class="qty">{{ property.kamar_tidur }}</p>
              </div>
              <p class="title">Kamar</p>
            </div>
            <div class="wrapper">
              <div class="top-text">
                <v-img
                  src="@/assets/shower.png"
                  width="20px"
                  max-width="20px"
                  contain
                  height="20px"
                ></v-img>
                <p class="qty">{{ property.kamar_mandi }}</p>
              </div>
              <p class="title">Toilet</p>
            </div>
            <div class="wrapper">
              <div class="top-text">
                <v-img
                  src="@/assets/ruler.png"
                  width="25px"
                  max-width="25px"
                  contain
                  height="25px"
                ></v-img>
                <p class="qty">
                  {{ property.luas_tanah }}<span>m<sup>2</sup></span>
                </p>
              </div>
              <p class="title">Luas Tanah</p>
            </div>
            <div class="wrapper">
              <div class="top-text">
                <v-img
                  src="@/assets/area-building.png"
                  width="20px"
                  max-width="20px"
                  contain
                  height="20px"
                ></v-img>
                <p class="qty">
                  {{ property.luas_tanah }}<span>m<sup>2</sup></span>
                </p>
              </div>
              <p class="title">Luas Bangunan</p>
            </div>
          </div>
        </router-link>
        <div class="creator-section">
          <div class="creator">
            <v-img
              :src="this.$apiImg + property.creator.image_url"
              cover
              min-width="25px"
              max-width="25px"
              min-height="25px"
              max-height="25px"
              class="creator-img"
            ></v-img>
            <p class="name">{{ property.creator.name }}</p>
          </div>
          <a
            :href="`https://wa.me/${property.creator.phone_number}`"
            target="_blank"
            class="creator-wa"
          >
            <v-img
              src="@/assets/whatsapp-logo.png"
              contain
              min-width="20px"
              max-width="20px"
              min-height="20px"
              max-height="20px"
              class="wa-img"
            ></v-img>
            <p class="wa">WhatsApp</p>
          </a>
        </div>
        <div class="edit-delete" v-if="roles != 'admin'">
          <router-link
            :to="`edit-properties/slug/${property.slug}`"
            class="btn-edit-delete"
          >
            <v-img class="btn-icon" src="@/assets/edit-icon.png"></v-img>
          </router-link>

          <v-btn class="btn-edit-delete" @click="deleteProperty(property.id)">
            <v-img class="btn-icon" src="@/assets/delete-icon.png"></v-img>
          </v-btn>
        </div>
      </div>
    </div>
    <v-pagination
      v-model="page"
      :length="propAll.last_page"
      :total-visible="5"
      class="pagination"
    ></v-pagination>
  </section>
</template>

<script>
import axios from "axios";
import profile from "@/components/component/ProfileNav.vue";

export default {
  data() {
    return {
      roles: null,
      filter: ["Dijual", "Disewakan"],
      filterAgent: "",
      propertyType: [],
      keyword: "",
      propertyTypeItems: [
        {
          items: "Apartemen",
          value: "apartemen",
        },
        {
          items: "Ruko",
          value: "ruko",
        },
        {
          items: "Rumah",
          value: "rumah",
        },
        {
          items: "Rumah Kos",
          value: "rumah kos",
        },
        {
          items: "Gudang",
          value: "gudang",
        },
        {
          items: "Tanah",
          value: "tanah",
        },
      ],
      itemsPerPage: 8,
      currentPage: 1,
      propAll: [],
      page: 1,
      loading: false,
      error: false,
      confirmLogout: false,
      menuNav: false,
    };
  },

  watch: {
    page() {
      this.getPropInit();
    },
  },

  methods: {
    toggleLogout() {
      this.confirmLogout = !this.confirmLogout;
    },
    logout() {
      console.log("Logging out");
      localStorage.clear();

      const isDataCleared = localStorage.getItem("data") === null;

      if (isDataCleared) {
        console.log("Local Storage Cleared");
        this.$router.push({ name: "login" });
        console.log("Router navigated to login");
      } else {
        console.log("Unable to clear local storage");
      }
    },
    toggleMenu() {
      this.menuNav = !this.menuNav;
    },
    async getPropInit() {
      try {
        const res = await axios.get(
          `${this.$api}/property/list?page=${this.page}`
        );
        this.propAll = res.data.data;

        console.log(this.propAll);
      } catch (error) {
        console.log(error);
      }
    },
    async postFilter() {
      try {
        this.loading = true;
        const payload = {
          page: 1,
        };

        if (this.keyword) {
          payload.kata_kunci = this.keyword;
        }

        if (this.propertyType.length > 0) {
          payload.tipe_properti = this.propertyType;
        }

        const res = await axios.post(`${this.$api}/property/filter`, payload);
        if (res.status === 200 || res.status === 201) {
          this.propAll = res.data.data;
        } else {
          this.error = true;
        }
        this.error = false;
        console.log(this.propAll);
      } catch (error) {
        console.log(error.message);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    async deleteProperty(propertyId) {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.post(
          `${this.$api}/property/delete/id/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200 || response.status === 204) {
          console.log("Property deleted successfully");
          await this.getPropInit();
        }
      } catch (error) {
        console.error("Error deleting property:", error);
      }
    },
    formatPrice(value) {
      const formattedValue = (value / 1).toFixed(0).replace(".", ",");
      return formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async getUserDetail() {
      try {
        const res = await axios.get(`${this.$api}/profile/detail`);
        if (res.status === 200 || res.status === 201) {
          this.roles = res.data.data.roles[0].name;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  components: {
    profile,
  },

  async mounted() {
    await this.getPropInit();
  },
  async created() {
    await this.getUserDetail();
  },
};
</script>

<style lang="scss" scoped>
section {
  &.company {
    width: 80%;
    max-height: 100vh;
    overflow-y: auto;
    padding: 30px;
    background-color: $anti-flash;

    .top-section {
      .above {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.2rem;
        align-items: center;
      }
      h4 {
        width: fit-content;
        font-weight: $font-weight-semibold;
      }

      .add-search {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 1.5rem;
        border-top: 2px solid $font-color;

        .add-btn,
        .search {
          width: 250px;
        }

        .add-btn {
          background-color: $button-color;
          text-transform: capitalize;
          font-size: 16px;
          height: 45px;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $black;
          text-decoration: none;
          // font-size: 20px;
          font-weight: $font-weight-medium;
        }
      }

      .filter {
        width: 250px;
      }
    }

    .filter-stat {
      text-align: center;
      width: 100%;
      margin: 2rem 0;
      color: $roman-silver;
    }

    .wrapper-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      .wrap-card {
        width: calc(25% - 24px);
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
        background-color: $white;
        border-radius: 10px;
        position: relative;
        display: flex;
        flex-direction: column;

        .edit-delete {
          display: flex;
          gap: 7px;
          position: absolute;
          z-index: 2;
          top: 10px;
          right: 10px;

          .btn-edit-delete {
            min-width: unset;
            padding: 10px;
            background-color: $white;
            height: 36px;
            border-radius: 3px;

            .btn-icon {
              width: 20px;
              height: 20px;
            }
          }
        }
        .property-card {
          text-decoration: none;
          z-index: 1;

          .property-img {
            border-radius: 10px 10px 0 0;
          }

          .mid-section {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 20px 15px;

            .price {
              font-size: 20px;
              font-weight: $font-weight-semibold;
              color: $black;
            }

            .type {
              font-size: 12px;
              font-weight: $font-weight-semibold;
              text-transform: uppercase;
              color: $black;
            }

            .property-name {
              color: $black;
              font-weight: $font-weight-medium;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .location {
              display: flex;
              gap: 3px;
              align-items: center;
              color: $roman-silver;
              font-size: 12px;
              font-weight: $font-weight-medium;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .bottom-section {
            padding: 20px 15px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1.5px solid $gainsboro;

            .wrapper {
              display: flex;
              flex-direction: column;
              gap: 5px;
              width: fit-content;

              .top-text {
                display: flex;
                gap: 10px;
                align-items: center;

                .qty {
                  font-weight: $font-weight-semibold;
                  font-size: 18px;
                  color: $roman-silver;
                  line-height: 18px;

                  span {
                    font-size: 12px;
                  }
                }
              }

              .title {
                color: $roman-silver;
                font-weight: $font-weight-medium;
                font-size: 9px;
              }
            }
          }
        }
      }

      .creator-section {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .creator {
          gap: 10px;
          display: flex;
          align-items: center;
          .creator-img {
            border-radius: 100px;
          }

          .name {
            color: $roman-silver;
            font-size: 13px;
          }
        }
        .creator-wa {
          display: flex;
          gap: 10px;
          align-items: center;
          padding: 5px 15px;
          border-radius: 30px;
          text-decoration: none;
          border: 1px solid #33bb47;

          .wa {
            color: #33bb47;
            font-size: 10px;
            font-weight: $font-weight-medium;
          }
        }
      }
    }

    .pagination {
      margin-top: 2rem;
    }
  }
}
</style>
