<template>
  <footer>
    <v-row class="ma-0">
      <v-container class="pa-0">
        <div class="footer-wrapper">
          <div class="content-wrapper">
            <div class="logo pr-8">
              <v-img
                src="@/assets/logo-header.png"
                height="100px"
                width="130px"
                contain
              ></v-img>
            </div>
          </div>
          <div class="content-wrapper">
            <div class="footer-content contact-us">
              <p class="content-title">Hubungi Kami</p>
              <div class="social-links">
                <div
                  class="social-link"
                  v-for="(social, i) in socials"
                  :key="i"
                >
                  <v-img
                    :src="social.src"
                    class="social-icon"
                    height="25px"
                    max-width="25px"
                    contain
                  ></v-img>
                  <a :href="social.link" class="menu" target="_blank">{{
                    social.content
                  }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="wrapper">
            <div class="content-wrapper">
              <div class="footer-content">
                <p class="content-title">Properti</p>
                <div class="link-contain">
                  <router-link to="/" class="menu">Properti Dijual</router-link>
                  <router-link to="/" class="menu">Sewa Properti</router-link>
                  <router-link to="/" class="menu">Agen Properti</router-link>
                </div>
              </div>
            </div>
            <div class="content-wrapper">
              <div class="footer-content">
                <p class="content-title">Informasi</p>
                <div class="link-contain">
                  <router-link to="/" class="menu">Artikel</router-link>
                  <router-link to="/" class="menu">Tentang Kami</router-link>
                  <router-link to="/" class="menu">Hubungi Kami</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-container>
      <v-container>
        <div class="copyright">
          <v-img
            src="@/assets/copyright.png"
            max-height="15px"
            max-width="15px"
            contain
          ></v-img>
          <p>2023 HEPI PROPERTY</p>
        </div>
      </v-container>
    </v-row>
  </footer>
</template>

<script>
export default {
  data: () => ({
    socials: [
      {
        src: require("@/assets/call.png"),
        link: "https://wa.me/6281226489858",
        content: "0812-2648-9858",
      },
      {
        src: require("@/assets/email.png"),
        link: "mailto:info@hepiproperty.com",
        content: "info@hepiproperty.com",
      },
      {
        src: require("@/assets/facebook.png"),
        link: "https://www.facebook.com/hepipropertysemarang",
        content: "Hepi Property Semarang",
      },
      {
        src: require("@/assets/instagram.png"),
        link: "https://instagram.com/hepipropertysemarang?igshid=YmM0MjE2YWMzOA",
        content: "hepipropertysemarang",
      },
    ],
  }),
};
</script>

<style scoped lang="scss">
::v-deep .v-application--wrap {
  min-height: fit-content;
}

footer {
  background-color: $secondary-color;
  padding: 3rem 0;

  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;

    .wrapper {
      display: flex;
      width: 35%;
      justify-content: space-between;
    }

    .content-wrapper {
      width: fit-content;

      .footer-content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .content-title {
          font-size: 20px;
          font-weight: $font-weight-semibold;
          margin: 0;
        }

        .link-contain {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .menu {
            color: $font-color;
            text-decoration: none;
            &:hover {
              color: $primary-color;
            }
          }
        }

        .social-links {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .social-link {
            display: flex;
            gap: 1rem;
            .social-icon {
              width: 25px;
            }

            .menu {
              color: $font-color;
              text-decoration: none;
              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }
    }
  }

  .copyright {
    width: 100%;
    display: flex;
    gap: 5px;
    justify-content: center;
    padding-top: 3rem;
    align-items: center;

    p {
      width: fit-content;
      margin: 0;
    }
  }
}

@media screen and (max-width: $bp-md) {
  footer {
    .footer-wrapper {
      display: flex;
      justify-content: center;
      padding: 0 1rem;
      align-items: center;
      flex-direction: column;
      gap: 4rem;

      .wrapper {
        width: 60%;
      }

      .content-wrapper {
        .logo {
          padding: 0 !important;
        }
        .footer-content {
          &.contact-us {
            p,
            .social-link {
              justify-content: center;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $bp-sm) {
  footer {
    .footer-wrapper {
      .wrapper {
        width: 90%;
      }
    }
  }
}
</style>
