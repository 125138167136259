<template>
  <section class="company">
    <div class="nav-header">
      <router-link to="/admin/properties">
        <v-img
          src="@/assets/chevron-left-gray.png"
          height="30px"
          width="17px"
        ></v-img>
      </router-link>

      <h4>Tambah Properti</h4>
    </div>
    <div class="wrapper">
      <div class="input-contain">
        <p>
          Status<span><sup>*</sup></span>
        </p>
        <v-radio-group v-model="status" class="radios">
          <v-row class="horizontal">
            <v-radio label="Dijual" value="dijual" class="radio"></v-radio>
            <v-radio
              label="Disewakan"
              value="disewakan"
              class="radio"
            ></v-radio>
          </v-row>
        </v-radio-group>
      </div>

      <div class="input-contain">
        <p>
          Tipe Properti<span><sup>*</sup></span>
        </p>
        <v-radio-group v-model="propType" class="radios">
          <v-row class="horizontal">
            <v-radio
              v-for="property in propertyType"
              :key="property.value"
              :label="property.items"
              :value="property.value"
              class="radio"
            ></v-radio>
          </v-row>
        </v-radio-group>
      </div>
      <div class="input-contain">
        <p>
          Foto Properti<span><sup>*</sup></span>
        </p>
        <div class="images-wrap">
          <!-- Display uploaded images -->
          <div
            v-for="(image, index) in uploadedImages"
            :key="index"
            class="uploaded-image"
            draggable="true"
            @dragstart="onDragStart($event, index)"
            @dragover="onDragOver"
            @drop="onDrop($event, index)"
          >
            <v-img :src="image.url" class="thumbnail" cover></v-img>
            <v-img
              class="delete-btn"
              @click="deleteImage(index)"
              src="@/assets/close-red.png"
            ></v-img>
          </div>

          <!-- Upload button -->
          <div @click="openFileInput" class="custom-upload-button">
            <v-img src="@/assets/upload.png" class="upload-icon"></v-img>
            <p>Unggah</p>
          </div>

          <!-- File input -->
          <v-file-input
            style="display: none"
            ref="fileInput"
            multiple
            @change="handleFileUpload"
            v-model="selectedImages"
          ></v-file-input>
        </div>
      </div>
      <div class="input-contain">
        <p>
          Judul<span><sup>*</sup></span>
        </p>
        <v-text-field
          class="input-template"
          v-model="name"
          :rules="[() => !!name || 'Required']"
          placeholder="Nama Properti"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          required
        ></v-text-field>
      </div>
      <div class="input-contain" v-if="status == 'disewakan'">
        <p>
          Periode Sewa<span><sup>*</sup></span>
        </p>
        <v-radio-group v-model="rentPeriodSelect" class="radios">
          <v-row class="horizontal">
            <v-radio
              v-for="property in rentPeriod"
              :key="property.value"
              :label="property.items"
              :value="property.value"
              class="radio"
            ></v-radio>
          </v-row>
        </v-radio-group>
      </div>
      <div class="input-contain" v-if="status == 'disewakan'">
        <p>
          Tipe Harga Sewa<span><sup>*</sup></span>
        </p>
        <v-radio-group v-model="rentTypeSelect" class="radios">
          <v-row class="horizontal">
            <v-radio
              v-for="property in rentType"
              :key="property.value"
              :label="property.items"
              :value="property.value"
              class="radio"
            ></v-radio>
          </v-row>
        </v-radio-group>
      </div>
      <div class="input-contain">
        <p>
          Harga<span><sup>*</sup></span>
        </p>
        <v-text-field
          class="input-template"
          v-model="formattedPrice"
          :rules="[() => !!price || 'Required']"
          placeholder="Harga Properti"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          required
          @input="handleInput"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>
          Deskripsi<span><sup>*</sup></span>
        </p>
        <v-textarea
          class="input-template"
          v-model="description"
          :rules="[() => !!description || 'Required']"
          placeholder="Deskripsi Properti"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          required
        ></v-textarea>
      </div>

      <div class="input-contain">
        <p>
          Provinsi<span><sup>*</sup></span>
        </p>

        <v-select
          v-model="province"
          :items="provinceItems"
          :rules="[() => !!province || 'Required']"
          item-title="nama"
          item-value="id"
          @update:modelValue="getCity()"
          placeholder="Provinsi"
          variant="outlined"
          base-color="#818A91"
          density="compact"
          bg-color="#fff"
          color="#363636"
          required
        ></v-select>
      </div>

      <div class="input-contain">
        <p>
          Kota<span><sup>*</sup></span>
        </p>

        <v-select
          v-model="city"
          :items="cityItems"
          item-title="nama"
          :rules="[() => !!city || 'Required']"
          item-value="id"
          @update:modelValue="getDistrict()"
          placeholder="Kota"
          variant="outlined"
          base-color="#818A91"
          density="compact"
          bg-color="#fff"
          color="#363636"
          required
        ></v-select>
      </div>

      <div class="input-contain">
        <p>
          Kecamatan atau Area<span><sup>*</sup></span>
        </p>
        <v-autocomplete
          variant="outlined"
          v-model="district"
          :items="districtItems"
          :rules="[() => !!district || 'Required']"
          item-title="nama"
          item-value="id"
          placeholder="Ketik untuk cari"
          base-color="#818A91"
          density="compact"
          bg-color="#fff"
          color="#363636"
        ></v-autocomplete>
      </div>

      <div class="input-contain">
        <p>
          Alamat Listing<span><sup>*</sup></span>
        </p>
        <v-textarea
          class="input-template"
          v-model="area"
          :rules="[() => !!area || 'Required']"
          placeholder="Alamat Lengkap Properti"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          required
        ></v-textarea>
      </div>

      <div class="input-contain">
        <p>
          Agen<span><sup>*</sup></span>
        </p>
        <v-autocomplete
          variant="outlined"
          v-model.number="agent"
          :items="agentList.data"
          :rules="[() => !!district || 'Required']"
          item-title="name"
          item-value="id"
          placeholder="Ketik untuk cari"
          base-color="#818A91"
          density="compact"
          bg-color="#fff"
          color="#363636"
        ></v-autocomplete>
      </div>

      <div class="input-contain">
        <p>Luas Tanah</p>
        <v-text-field
          class="input-template"
          v-model.number="landArea"
          placeholder="Luas Tanah"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          suffix="m²"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>Luas Bangunan</p>
        <v-text-field
          class="input-template"
          v-model.number="buildingArea"
          placeholder="Luas Bangunan"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          suffix="m²"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>Lebar Depan Bangunan</p>
        <v-text-field
          class="input-template"
          v-model.number="frontLength"
          placeholder="Lebar Depan Bangunan"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          suffix="m"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>Jumlah Lantai</p>
        <v-text-field
          class="input-template"
          v-model.number="floorLevel"
          type="number"
          placeholder="Jumlah Lantai"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>Kamar Tidur</p>
        <v-text-field
          class="input-template"
          v-model.number="bedroom"
          type="number"
          placeholder="Jumlah Kamar Tidur"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>Kamar Mandi</p>
        <v-text-field
          class="input-template"
          v-model.number="bathroom"
          type="number"
          placeholder="Jumlah Kamar Mandi"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>Kamar Tidur Pembantu</p>
        <v-text-field
          class="input-template"
          v-model.number="bedroomArt"
          type="number"
          placeholder="Jumlah Kamar Tidur Pembantu"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>Kamar Mandi Pembantu</p>
        <v-text-field
          class="input-template"
          v-model.number="bathroomArt"
          type="number"
          placeholder="Jumlah Kamar Mandi Pembantu"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>Garasi</p>
        <v-text-field
          class="input-template"
          v-model.number="garage"
          type="number"
          placeholder="Jumlah Garasi"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          suffix="Mobil"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>Carport</p>
        <v-text-field
          class="input-template"
          v-model.number="carport"
          type="number"
          placeholder="Jumlah Carport"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          suffix="Mobil"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>Listrik</p>
        <v-text-field
          class="input-template"
          v-model.number="voltage"
          placeholder="Listrik"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          suffix="watt"
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>Air</p>
        <v-radio-group v-model="water" class="radios">
          <v-row class="horizontal">
            <v-radio
              v-for="property in waterSelect"
              :key="property.value"
              :label="property.items"
              :value="property.value"
              class="radio"
            ></v-radio>
          </v-row>
        </v-radio-group>
      </div>
      <div class="input-contain">
        <p>Sertifikat</p>
        <v-radio-group v-model="sertificate" class="radios">
          <v-row class="horizontal">
            <v-radio
              v-for="property in sertificateSelect"
              :key="property.value"
              :label="property.items"
              :value="property.value"
              class="radio"
            ></v-radio>
          </v-row>
        </v-radio-group>
      </div>
      <div class="input-contain">
        <p>Hadap</p>
        <v-radio-group v-model="position" class="radios">
          <v-row class="horizontal">
            <v-radio
              v-for="property in directionSelect"
              :key="property.value"
              :label="property.items"
              :value="property.value"
              class="radio"
            ></v-radio>
          </v-row>
        </v-radio-group>
      </div>
      <div class="input-contain">
        <p>Kondisi Rumah</p>

        <v-radio-group v-model="propertyCond" class="radios">
          <v-row class="horizontal">
            <v-radio
              v-for="property in conditionSelect"
              :key="property.value"
              :label="property.items"
              :value="property.value"
              class="radio"
            ></v-radio>
          </v-row>
        </v-radio-group>
      </div>

      <div class="input-contain">
        <p>Nama Vendor</p>
        <v-text-field
          class="input-template"
          v-model="vendorName"
          placeholder="Nama Vendor"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          required
        ></v-text-field>
      </div>
      <div class="input-contain">
        <p>Nomor HP Vendor</p>
        <v-text-field
          class="input-template"
          v-model="vendorPhone"
          placeholder="Nomor HP Vendor"
          density="compact"
          variant="outlined"
          base-color="#818A91"
          bg-color="#fff"
          color="#363636"
          required
        ></v-text-field>
      </div>

      <div class="buttons">
        <router-link to="/admin/properties" class="cancel">
          Cancel
        </router-link>
        <v-btn
          class="save-button"
          variant="flat"
          prepend-icon="mdi-content-save"
          @click="postProperty()"
          :disabled="isButtonDisabled"
          >Simpan</v-btn
        >
      </div>
      <p v-if="isButtonDisabled" class="warn">
        Isi semua data untuk membuat listing baru.
      </p>
    </div>
  </section>
  <section class="loader" v-if="loading">
    <v-progress-circular
      v-if="loading"
      indeterminate
      size="40"
    ></v-progress-circular>
  </section>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      items: ["Dijual", "Disewakan"],
      conditionItems: ["Furnished", "Semi Furnished", "Unfurnished"],
      name: null,
      price: 0,
      formattedPrice: "",
      description: null,
      area: null,
      landArea: null,
      buildingArea: null,
      frontLength: null,
      bedroom: null,
      bathroom: null,
      bedroomArt: null,
      bathroomArt: null,
      voltage: null,
      water: null,
      position: null,
      carport: null,
      garage: null,
      propertyCond: null,
      propType: null,
      status: null,
      sertificate: null,
      propImage: [],
      province: 14,
      city: 220,
      district: null,
      propertyType: [
        {
          items: "Rumah",
          value: "Rumah",
        },
        {
          items: "Ruko",
          value: "Ruko",
        },
        {
          items: "Tanah",
          value: "Tanah",
        },
        {
          items: "Gudang",
          value: "Gudang",
        },
        {
          items: "Apartemen",
          value: "Apartemen",
        },
        {
          items: "Rumah Kos",
          value: "Rumah Kos",
        },
        {
          items: "Ruang Usaha",
          value: "Ruang usaha",
        },
        {
          items: "Hotel",
          value: "Hotel",
        },
        {
          items: "Villa",
          value: "Villa",
        },
      ],
      rentPeriod: [
        {
          items: "Per Tahun",
          value: "Per Tahun",
        },
        {
          items: "Per Bulan",
          value: "Per Bulan",
        },
        {
          items: "Per Hari",
          value: "Per Hari",
        },
      ],
      rentType: [
        {
          items: "Total",
          value: "Total",
        },
        {
          items: "Per m²",
          value: "Per m²",
        },
      ],
      waterSelect: [
        {
          items: "PAM",
          value: "PAM",
        },
        {
          items: "Sumur",
          value: "Sumur",
        },
        {
          items: "Artetis",
          value: "Artetis",
        },
      ],
      sertificateSelect: [
        {
          items: "SHM",
          value: "SHM",
        },
        {
          items: "HGB",
          value: "HGB",
        },
        {
          items: "Strata Title",
          value: "Strata Title",
        },
        {
          items: "PPJB",
          value: "PPJB",
        },
      ],
      directionSelect: [
        {
          items: "Timur",
          value: "Timur",
        },
        {
          items: "Tenggara",
          value: "Tenggara",
        },
        {
          items: "Selatan",
          value: "Selatan",
        },
        {
          items: "Barat Daya",
          value: "Barat Daya",
        },
        {
          items: "Barat",
          value: "Barat",
        },
        {
          items: "Barat Laut",
          value: "Barat Laut",
        },
        {
          items: "Utara",
          value: "Utara",
        },
        {
          items: "Timur Laut",
          value: "Timur Laut",
        },
      ],
      conditionSelect: [
        {
          items: "Furnished",
          value: "Furnished",
        },
        {
          items: "Semi Furnished",
          value: "Semi Furnished",
        },
        {
          items: "Unfurnished",
          value: "Unfurnished",
        },
      ],
      rentPeriodSelect: null,
      rentTypeSelect: null,
      selectedFile: null,
      selectedImages: [],
      uploadedImages: [],
      provinceItems: [],
      cityItems: [],
      districtItems: [],
      loading: false,
      vendorName: null,
      vendorPhone: null,
      floorLevel: null,
      agent: null,
      agentList: [],
      dragIndex: null,
    };
  },
  computed: {
    isButtonDisabled() {
      // Check if any required field is empty
      const requiredFields = [
        this.name,
        this.status,
        this.propType,
        this.description,
        this.price,
        this.propImage,
        this.province,
        this.city,
        this.district,
        // Add other required fields here
      ];
      return requiredFields.some((field) => !field);
    },
  },
  methods: {
    async getAgentList() {
      try {
        const res = await axios.get(
          `${this.$api}/user/agent/list?page=${this.currentPage}`
        );
        if (res.status === 200 || res.status === 201) {
          this.agentList = res.data.data;
          console.log(this.agentList);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getProvince() {
      try {
        const res = await axios.get(`${this.$api}/area/provinsi`);
        this.provinceItems = res.data.data;

        console.log(this.provinceItems);
      } catch (error) {
        console.log(error);
      }
    },
    async getCity() {
      try {
        const res = await axios.get(
          `${this.$api}/area/provinsi/${this.province}/kota`
        );
        this.cityItems = res.data.data;

        console.log(this.cityItems);
      } catch (error) {
        console.log(error);
      }
    },
    async getDistrict() {
      try {
        const res = await axios.get(
          `${this.$api}/area/provinsi/kota/${this.city}/kecamatan`
        );
        this.districtItems = res.data.data;

        console.log(this.districtItems);
      } catch (error) {
        console.log(error);
      }
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    onDragStart(event, index) {
      // Store the index of the dragged item
      this.dragIndex = index;
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("text/plain", index);
    },
    onDragOver(event) {
      // Prevent the default behavior to allow drop
      event.preventDefault();
    },
    onDrop(event, dropIndex) {
      // Prevent default behavior
      event.preventDefault();

      // Get the index of the dragged item
      const dragIndex = event.dataTransfer.getData("text/plain");

      // Reorder images in the array
      const movedImage = this.uploadedImages[dragIndex];
      this.uploadedImages.splice(dragIndex, 1);
      this.uploadedImages.splice(dropIndex, 0, movedImage);
    },
    handleFileUpload(event) {
      const files = event.target.files;
      if (files && files.length > 0) {
        for (const file of files) {
          const url = URL.createObjectURL(file);
          this.uploadedImages.push({ url, file });
        }
      }
    },
    deleteImage(index) {
      const deletedImage = this.uploadedImages.splice(index, 1)[0];
      if (deletedImage) {
        URL.revokeObjectURL(deletedImage.url); // Clean up the object URL
        this.$refs.fileInput.value = ""; // Clear the file input value
      }
    },
    handleInput(event) {
      const value = event.target.value.replace(/\./g, "");
      this.price = parseInt(value, 10) || 0;
      this.formattedPrice = this.formatPrice(this.price);
    },
    formatPrice(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async postProperty() {
      try {
        this.loading = true;

        const formData = new FormData();
        for (const uploadedImage of this.uploadedImages) {
          if (uploadedImage.file) {
            formData.append("images[]", uploadedImage.file);
          }
        }

        if (this.agent) formData.append("agent_id", this.agent);
        if (this.name) formData.append("judul", this.name);
        if (this.status) formData.append("status", this.status);
        if (this.propType) formData.append("tipe_properti", this.propType);
        if (this.description) formData.append("deskripsi", this.description);
        if (this.price) formData.append("harga", this.price);
        if (this.area) formData.append("area", this.area);
        if (this.province) formData.append("provinsi_id", this.province);
        if (this.city) formData.append("kota_id", this.city);
        if (this.district) formData.append("kecamatan_id", this.district);
        if (this.landArea) formData.append("luas_tanah", this.landArea);
        if (this.buildingArea)
          formData.append("luas_bangunan", this.buildingArea);
        if (this.bedroom) formData.append("kamar_tidur", this.bedroom);
        if (this.bathroom) formData.append("kamar_mandi", this.bathroom);
        if (this.bedroomArt)
          formData.append("kamar_tidur_pembantu", this.bedroomArt);
        if (this.bathroomArt)
          formData.append("kamar_mandi_pembantu", this.bathroomArt);
        if (this.voltage) formData.append("listrik", this.voltage);
        if (this.water) formData.append("air", this.water);
        if (this.sertificate) formData.append("sertifikat", this.sertificate);
        if (this.position) formData.append("hadap", this.position);
        if (this.garage) formData.append("garasi", this.garage);
        if (this.carport) formData.append("carport", this.carport);
        if (this.frontLength)
          formData.append("lebar_depan_bangunan", this.frontLength);
        if (this.floorLevel) formData.append("jumlah_lantai", this.floorLevel);
        if (this.rentTypeSelect)
          formData.append("tipe_harga_sewa", this.rentTypeSelect);
        if (this.rentPeriodSelect)
          formData.append("periode_sewa", this.rentPeriodSelect);
        if (this.vendorName) formData.append("nama_vendor", this.vendorName);
        if (this.vendorPhone) formData.append("no_hp_vendor", this.vendorPhone);
        if (this.area) formData.append("alamat", this.area);
        if (this.propertyCond)
          formData.append("kondisi_bangunan", this.propertyCond);

        const res = await axios.post(`${this.$api}/property/create`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log(res);
        if (res.status == 201 || res.status == 200) {
          this.$router.push({ name: "properties" });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false; // Set loading to false whether the request is successful or not
      }
    },
  },
  async mounted() {
    await this.getProvince();
    await this.getCity();
    await this.getDistrict();
    await this.getAgentList();
  },
};
</script>

<style lang="scss" scoped>
section {
  &.company {
    width: 80%;
    max-height: 100vh;
    padding: 30px;
    overflow: scroll;
    background-color: $anti-flash;

    .nav-header {
      display: flex;
      gap: 3rem;
      align-items: center;
      padding-bottom: 1.5rem;
      border-bottom: 2px solid $font-color;
      margin-bottom: 1.5rem;

      h4 {
        font-weight: $font-weight-semibold;
      }
    }

    .wrapper {
      width: 70%;
      background-color: $white;
      border-radius: 7px;
      height: fit-content;
      padding: 20px;

      .input-contain {
        width: 100%;
        p {
          margin-bottom: 5px;
          span {
            color: #ff2828;
          }
        }

        .horizontal {
          width: 100%;
          flex-wrap: wrap;
          flex: unset;
        }

        .radios {
          padding: 15px;
          .radio {
            width: 50%;
            color: $font-color;
            font-weight: $font-weight-medium;
            flex: unset;
          }
        }
      }

      .images-wrap {
        margin-bottom: 10px;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        .custom-upload-button {
          width: calc(20% - 16px);
          display: flex;
          aspect-ratio: 1/1;
          border-radius: 7px;
          border: 2px dashed #83858d;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 10px;

          .upload-icon {
            width: 25px;
            margin-top: 10px;
            max-height: 25px;
          }

          p {
            font-weight: $font-weight-medium;
            color: #83858d;
          }
        }

        .uploaded-image {
          width: calc(20% - 16px);
          aspect-ratio: 1/1;
          border-radius: 7px;
          position: relative;
          object-fit: cover;
          cursor: move;

          .thumbnail {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 7px;
          }

          .delete-btn {
            position: absolute;
            top: -7px;
            right: -7px;
            width: 15px;
            height: 15px;
          }
        }
      }

      .buttons {
        display: flex;
        gap: 1rem;
        align-items: center;

        .cancel {
          height: 100%;
          padding: 7px 15px;
          color: $font-color;
          background-color: $gainsboro;
          border-radius: 3px;
          text-decoration: none;
          font-size: 15px;
          min-height: fit-content !important;
        }

        .save-button {
          color: $font-color;
          background-color: $button-color;
          font-size: 15px;
          min-height: fit-content !important;
          text-transform: capitalize;
        }
      }

      .warn {
        margin-top: 10px;
        font-size: 14px;
        color: #ff2828;
      }
    }
  }
  &.loader {
    width: 100%;
    min-height: 100vh;
    max-height: 100vw;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    width: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
