<template>
  <v-app>
    <section class="article-detail">
      <v-container>
        <v-row>
          <div class="wrapper">
            <div class="article-info">
              <h3>
                {{ articleDetail.title }}
              </h3>
              <p class="uploader">Ditulis oleh Darma Panji</p>
              <div class="date-share-like">
                <p class="date">{{ formatDate(articleDetail.created_at) }}</p>
                <div class="share-like">
                  <v-btn
                    class="share-like-btn"
                    variant="text"
                    ripple="false"
                    @click="sharePropPopup"
                  >
                    <v-img
                      src="@/assets/share-gray.png"
                      width="24px"
                      height="24px"
                      max-height="24px"
                      class="share-icon"
                    ></v-img>
                    <p>Bagikan</p>
                  </v-btn>
                  <!-- <v-btn
                    @click="toggleHeart"
                    variant="text"
                    class="share-like-btn"
                  >
                    <v-icon :color="heartColor" size="large">{{
                      heartIcon
                    }}</v-icon>
                    <p>5 Suka</p>
                  </v-btn> -->
                </div>
              </div>
            </div>

            <div class="reading-section">
              <div class="article-content">
                <v-img
                  :src="`${this.$apiImg}${articleDetail.image_url}`"
                  height="400px"
                  cover
                  class="content-img"
                ></v-img>
                <p class="aricle-text">
                  {{ articleDetail.content }}
                </p>
              </div>
              <!-- <div class="related-article">
                <div class="article-list">
                  <router-link
                    to="/#"
                    class="article-card"
                    v-for="i in 3"
                    :key="i"
                  >
                    <v-img
                      src="@/assets/article-img.png"
                      class="article-img"
                      cover
                    ></v-img>

                    <div class="article-text">
                      <p class="release-date">27 Mei 2023</p>
                      <p class="title">
                        5 Jenis Sertifikat yang Perlu Kamu Ketahui Sebelum Jual
                        Beli Rumah
                      </p>
                      <p class="preview">
                        Hai bagi kalian yang sedang mencari rumah tentu memiliki
                        rumah impian yang kalian inginkan, seperti taman yang
                        luas, bangunan yang kokoh, lingkungan contoh
                        sidauysgdfuyasdgfuasvfuasdvfgyudasvfgydfvdgfvsdgfv
                      </p>
                    </div>
                  </router-link>
                </div>
              </div> -->
            </div>
          </div>
        </v-row>
      </v-container>
    </section>

    <section class="pop-up-share" v-if="showSharePopup">
      <v-container>
        <v-row>
          <div class="wrapper">
            <v-img
              class="close-pop-up"
              src="@/assets/close-btn.png"
              @click="sharePropPopup"
            ></v-img>
            <h4>Bagikan Properti di Sosial<br />Mediamu</h4>
            <div class="socmed-wrapper">
              <a @click="shareImageAsset" class="group instagram">
                <v-img
                  src="@/assets/instagram-colored.png"
                  class="socmed-icon"
                ></v-img>
              </a>
              <a
                :href="`https://twitter.com/intent/tweet?text=Default+share+text&url=${currLink}`"
                class="group twitter"
                target="_blank"
              >
                <v-img src="@/assets/twitter.png" class="socmed-icon"></v-img>
              </a>
              <a
                :href="`https://wa.me/?text=${currLink}`"
                class="group whatsapp"
                target="_blank"
              >
                <v-img
                  src="@/assets/whatsapp-logo.png"
                  class="socmed-icon"
                ></v-img>
              </a>
              <a
                :href="`https://www.facebook.com/sharer/sharer.php?u=${currLink}`"
                class="group fb"
                target="_blank"
              >
                <v-img
                  src="@/assets/facebook-colored.png"
                  class="socmed-icon"
                ></v-img>
              </a>
              <!-- <a href="#" class="group">
                <v-img src="@/assets/tiktok.png" class="socmed-icon"></v-img>
              </a> -->
            </div>
            <div class="input-contain">
              <p>atau salin tautan</p>
              <v-text-field
                class="input-template"
                v-model="link"
                density="compact"
                variant="outlined"
                base-color="#818A91"
                bg-color="#fff"
                color="#363636"
                append-inner-icon="mdi-content-copy"
                @click:append-inner="copyLinkToClipboard"
                :rules="[rules.copy]"
              ></v-text-field>
            </div>
          </div>
        </v-row>
      </v-container>
    </section>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      isOutlined: true,
      showSharePopup: false,
      link: "https://hepiproperty.com/property-dijual/",
      rules: {
        copy: (copied) => (copied ? "Tersalin." : true),
      },
      currLink: null,
      articleDetail: [],
    };
  },
  computed: {
    heartIcon() {
      return this.isOutlined ? "mdi-heart-outline" : "mdi-heart";
    },
    heartColor() {
      return this.isOutlined ? "#818A91" : "red"; // Adjust color values as needed
    },
  },
  methods: {
    sharePropPopup() {
      this.showSharePopup = !this.showSharePopup;
    },
    async getArticleDetail() {
      try {
        const res = await axios.get(
          `${this.$api}/article/detail/slug/${this.$route.params.slug}`
        );
        if (res.status === 200 || res.status === 201) {
          this.articleDetail = res.data.data;
          //   console.log(this.articleDetail.image_url);
        }
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    async shareImageAsset() {
      try {
        // Get the image URL directly from the property details
        const imageUrl = `${this.$apiImg}${this.articleDetail.image_url}`;

        // Fetch the image blob directly
        const response = await fetch(imageUrl);
        const blob = await response.blob();

        const filesArray = [
          new File([blob], `${this.articleDetail.title}.png`, {
            type: "image/png",
            lastModified: new Date().getTime(),
          }),
        ];

        const shareData = {
          title: this.articleDetail.title,
          files: filesArray,
          text: `Check out this property: ${window.location.href}`,
        };

        if (navigator.canShare && navigator.canShare(shareData)) {
          await navigator.share(shareData);
          return true;
        } else {
          console.error("Sharing not supported");
          return false;
        }
      } catch (error) {
        console.error("Error sharing image asset:", error);
        return false;
      }
    },
    async whatsAppLink() {
      const currentUrl = encodeURIComponent(window.location.href);
      this.currLink = currentUrl;
      this.link = window.location.href;
    },
  },
  async mounted() {
    await this.getArticleDetail();
    await this.whatsAppLink();
  },
};
</script>

<style lang="scss" scoped>
section {
  &.article-detail {
    padding: 50px 0;
    .wrapper {
      width: 100%;
      .article-info {
        width: 70%;
        margin-bottom: 2rem;
        h3 {
          color: $font-color;
          font-weight: $font-weight-semibold;
        }

        .uploader {
          margin-top: 25px;
          margin-bottom: 10px;
          font-weight: $font-weight-medium;
          color: $roman-silver;
          font-size: 18px;
        }

        .date-share-like {
          display: flex;
          justify-content: space-between;

          .date {
            color: $roman-silver;
          }

          .share-like {
            display: flex;
            align-items: center;
            gap: 2rem;

            .share-like-btn {
              display: flex;
              text-transform: capitalize;
              align-items: center;
              padding: 0;

              p {
                margin-left: 5px;
              }
            }
          }
        }
      }

      .reading-section {
        display: flex;
        gap: 30px;

        .article-content {
          width: 70%;

          .content-img {
            border-radius: 5px;
            margin-bottom: 2rem;
          }

          .article-text {
            white-space: pre-line;
          }
        }

        .related-article {
          width: 30%;
          display: flex;
          flex-direction: column;
          gap: 30px;

          .article-card {
            width: 30%;
            text-decoration: none;

            .article-img {
              border-radius: 5px;
              height: 200px;
              margin-bottom: 1.5rem;
            }

            .article-text {
              display: flex;
              flex-direction: column;
              gap: 10px;

              .release-date {
                color: $button-color;
                font-size: 13px;
                font-weight: $font-weight-semibold;
              }

              .title {
                font-size: 20px;
                color: $black;
                font-weight: $font-weight-semibold;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              }

              .preview {
                font-size: 13px;
                color: $font-color;
                font-weight: $font-weight-medium;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }
    }
  }

  &.pop-up-share {
    background: rgba(0, 0, 0, 0.3);
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    .wrapper {
      width: 600px;
      margin: auto;
      background-color: $white;
      padding: 2rem;
      border-radius: 7px;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      position: relative;

      .close-pop-up {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 10px;
        right: 10px;
      }

      h4 {
        text-align: center;
        font-weight: $font-weight-semibold;
        color: $font-color;
      }

      .socmed-wrapper {
        display: flex;
        justify-content: space-between;

        .group {
          padding: 20px;
          border-radius: 50%;
          &.instagram {
            background: rgba(195, 57, 172, 0.1);
          }

          &.twitter {
            background: rgba(85, 172, 238, 0.1);
          }
          &.whatsapp {
            background: rgba(0, 230, 118, 0.1);
          }
          &.fb {
            background: rgba(25, 118, 210, 0.1);
          }

          .socmed-icon {
            width: 40px;
            height: 40px;
          }
        }
      }

      .input-contain {
        p {
          font-weight: $font-weight-medium;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: $bp-md) {
  section {
    &.article-detail {
      padding: 30px 0;
      .wrapper {
        width: 90%;
        margin: auto;
        .article-info {
          width: 100%;
          margin-bottom: 1rem;
        }

        .reading-section {
          flex-direction: column;

          .article-content {
            width: 100%;
          }

          .related-article {
            display: none;
          }
        }
      }
    }

    &.pop-up-share {
      .wrapper {
        width: 90%;
      }
    }
  }
}

@media screen and (max-width: $bp-sm) {
  section {
    &.article-detail {
      .wrapper {
        .article-info {
          .uploader {
            font-size: 10px;
            margin-top: 20px;
            margin-bottom: 0;
          }

          .date-share-like {
            align-items: center;
            .date {
              font-weight: $font-weight-reg;
              font-size: 10px;
            }

            .share-like {
              gap: 10px;
              .share-like-btn {
                .share-icon {
                  width: 16px !important;
                }
                p {
                  font-weight: $font-weight-reg;
                  font-size: 10px;
                }
              }
            }
          }
        }

        .reading-section {
          .article-content {
            .content-img {
              height: 250px !important;
            }
          }
        }
      }
    }

    &.pop-up-share {
      .wrapper {
        width: 90%;
        padding: 1rem;

        .close-pop-up {
          width: 20px;
          height: 20px;
        }

        h4 {
          font-size: 16px;
        }

        .socmed-wrapper {
          display: flex;
          justify-content: space-between;

          .group {
            padding: 10px;
            border-radius: 50%;

            .socmed-icon {
              width: 20px;
              height: 20px;
            }
          }
        }

        .input-contain {
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
