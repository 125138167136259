<template>
  <v-app>
    <!-- Section Jumbotron -->
    <section class="jumbotron">
      <div class="background">
        <v-img
          src="@/assets/background-jumbotron.png"
          cover
          align="center"
          justify="center"
          max-width="100vw"
          width="100vw"
          min-height="450px"
          height="450px"
        ></v-img>
      </div>
      <div class="jumbotron-content">
        <v-container>
          <v-row>
            <div class="jumbotron-text">
              <h1>Temukan Properti Impianmu dengan Hepi Property</h1>
              <p>
                Temukan Properti Impianmu di Lokasi terbaik di Semarang.<br />Bersama
                para agen yang profesional dalam bidangnya.
              </p>
            </div>
          </v-row>
          <v-row>
            <div class="search-property">
              <div class="search-form">
                <div class="input-contain keyword">
                  <p>Kata Kunci</p>
                  <v-text-field
                    class="input-template"
                    v-model="keyword"
                    placeholder="Masukkan Kata Kunci"
                    density="compact"
                    variant="outlined"
                    base-color="#818A91"
                    bg-color="#fff"
                    color="#363636"
                    @input="updateKeyword"
                  ></v-text-field>
                </div>
                <div class="input-contain">
                  <p>Tipe Listing</p>
                  <v-select
                    v-model="propertyType"
                    :items="items"
                    @input="propertyType"
                    placeholder="Tipe Properti"
                    variant="outlined"
                    base-color="#818A91"
                    density="compact"
                    bg-color="#fff"
                    color="#363636"
                  ></v-select>
                </div>
                <div class="input-contain">
                  <p>Harga Minimal</p>
                  <v-text-field
                    class="input-template"
                    v-model="minPrice"
                    placeholder="Harga Minimal"
                    density="compact"
                    variant="outlined"
                    base-color="#818A91"
                    bg-color="#fff"
                    color="#363636"
                    @input="formatMinPrice"
                  ></v-text-field>
                </div>
                <div class="input-contain">
                  <p>Harga Maksimal</p>
                  <v-text-field
                    class="input-template"
                    v-model="maxPrice"
                    placeholder="Harga Maksimal"
                    density="compact"
                    variant="outlined"
                    base-color="#818A91"
                    bg-color="#fff"
                    color="#363636"
                    @input="formatMaxPrice"
                  ></v-text-field>
                </div>

                <v-btn
                  class="button-search text-black text-capitalize"
                  color="#FBBC58"
                  variant="flat"
                  @click="saveFiltersAndMove()"
                >
                  Cari
                </v-btn>
              </div>
            </div>
          </v-row>
        </v-container>
      </div>
    </section>

    <!-- Section Properties Best -->
    <section class="properties best">
      <v-container>
        <v-row>
          <div class="wrapper">
            <div class="properties-text">
              <h2>Properti Unggulan</h2>
              <p>
                Hepi Property Membantu Anda untuk Menemukan Jual Beli Sewa dan
                Investasi Property Semarang
              </p>
            </div>
            <div class="card-wrap">
              <div
                class="card"
                v-for="highlight in propHighlight.data"
                :key="highlight.data.id"
              >
                <router-link
                  :to="`${highlight.data.path}`"
                  class="property-card"
                >
                  <v-img
                    class="property-img"
                    height="200px"
                    aspect-ratio="3/4"
                    cover
                    v-if="
                      highlight.data.images && highlight.data.images.length > 0
                    "
                    :src="this.$apiImg + highlight.data.images[0].image_url"
                  ></v-img>
                  <v-img
                    class="property-img"
                    height="200px"
                    aspect-ratio="3/4"
                    cover
                    v-else
                    src="@/assets/property-img.png"
                  ></v-img>
                  <div class="mid-section">
                    <p class="price">
                      Rp{{ formatPrice(highlight.data.harga) }}
                    </p>
                    <p class="type">{{ highlight.data.tipe_properti }}</p>
                    <p class="property-name">{{ highlight.data.judul }}</p>
                    <p class="location">
                      <span
                        ><v-img
                          src="@/assets/location.png"
                          width="17px"
                          height="17px"
                          contain
                        ></v-img></span
                      >{{ highlight.data.area }}
                    </p>
                  </div>
                  <div class="bottom-section">
                    <div class="wrapper">
                      <div class="top-text">
                        <v-img
                          src="@/assets/bed.png"
                          width="20px"
                          max-width="20px"
                          contain
                          height="20px"
                        ></v-img>
                        <p class="qty">{{ highlight.data.kamar_tidur }}</p>
                      </div>
                      <p class="title">Kamar</p>
                    </div>
                    <div class="wrapper">
                      <div class="top-text">
                        <v-img
                          src="@/assets/shower.png"
                          width="20px"
                          max-width="20px"
                          contain
                          height="20px"
                        ></v-img>
                        <p class="qty">{{ highlight.data.kamar_mandi }}</p>
                      </div>
                      <p class="title">Toilet</p>
                    </div>
                    <div class="wrapper">
                      <div class="top-text">
                        <v-img
                          src="@/assets/ruler.png"
                          width="25px"
                          max-width="25px"
                          contain
                          height="25px"
                        ></v-img>
                        <p class="qty">
                          {{ highlight.data.luas_tanah
                          }}<span>m<sup>2</sup></span>
                        </p>
                      </div>
                      <p class="title">Luas Tanah</p>
                    </div>
                    <div class="wrapper">
                      <div class="top-text">
                        <v-img
                          src="@/assets/area-building.png"
                          width="20px"
                          max-width="20px"
                          contain
                          height="20px"
                        ></v-img>
                        <p class="qty">
                          {{ highlight.data.luas_tanah
                          }}<span>m<sup>2</sup></span>
                        </p>
                      </div>
                      <p class="title">Luas Bangunan</p>
                    </div>
                  </div>
                </router-link>

                <div class="creator-section">
                  <div class="creator">
                    <v-img
                      :src="this.$apiImg + highlight.data.creator.image_url"
                      cover
                      min-width="25px"
                      max-width="25px"
                      min-height="25px"
                      max-height="25px"
                      class="creator-img"
                    ></v-img>
                    <p class="name">{{ highlight.data.creator.name }}</p>
                  </div>
                  <a
                    :href="`https://wa.me/${highlight.data.creator.phone_number}`"
                    target="_blank"
                    class="creator-wa"
                  >
                    <v-img
                      src="@/assets/whatsapp-logo.png"
                      contain
                      min-width="20px"
                      max-width="20px"
                      min-height="20px"
                      max-height="20px"
                      class="wa-img"
                    ></v-img>
                    <p class="wa">WhatsApp</p>
                  </a>
                </div>
              </div>
            </div>
            <router-link class="see-more" to="/best-properties"
              >Lihat Lebih Banyak</router-link
            >
          </div>
        </v-row>
      </v-container>
    </section>

    <!-- Section Properties Latest -->
    <section class="properties">
      <v-container>
        <v-row>
          <div class="wrapper">
            <div class="properties-text">
              <h2>Properti Terbaru</h2>
              <p>
                Hepi Property Membantu Anda untuk Menemukan Jual Beli Sewa dan
                Investasi Property Semarang
              </p>
            </div>
            <div class="card-wrap">
              <div
                class="card"
                v-for="newProp in propNewest.data"
                :key="newProp.id"
              >
                <router-link :to="`${newProp.path}`" class="property-card">
                  <v-img
                    class="property-img"
                    height="200px"
                    aspect-ratio="3/4"
                    cover
                    v-if="newProp.images && newProp.images.length > 0"
                    :src="this.$apiImg + newProp.images[0].image_url"
                  ></v-img>
                  <v-img
                    class="property-img"
                    height="200px"
                    aspect-ratio="3/4"
                    cover
                    v-else
                    src="@/assets/property-img.png"
                  ></v-img>
                  <div class="mid-section">
                    <p class="price">Rp{{ formatPrice(newProp.harga) }}</p>
                    <p class="type">{{ newProp.tipe_properti }}</p>
                    <p class="property-name">{{ newProp.judul }}</p>
                    <p class="location">
                      <span
                        ><v-img
                          src="@/assets/location.png"
                          width="17px"
                          height="17px"
                          contain
                        ></v-img></span
                      >{{ newProp.area }}
                    </p>
                  </div>
                  <div class="bottom-section">
                    <div class="wrapper">
                      <div class="top-text">
                        <v-img
                          src="@/assets/bed.png"
                          width="20px"
                          max-width="20px"
                          contain
                          height="20px"
                        ></v-img>
                        <p class="qty">{{ newProp.kamar_tidur }}</p>
                      </div>
                      <p class="title">Kamar</p>
                    </div>
                    <div class="wrapper">
                      <div class="top-text">
                        <v-img
                          src="@/assets/shower.png"
                          width="20px"
                          max-width="20px"
                          contain
                          height="20px"
                        ></v-img>
                        <p class="qty">{{ newProp.kamar_mandi }}</p>
                      </div>
                      <p class="title">Toilet</p>
                    </div>
                    <div class="wrapper">
                      <div class="top-text">
                        <v-img
                          src="@/assets/ruler.png"
                          width="25px"
                          max-width="25px"
                          contain
                          height="25px"
                        ></v-img>
                        <p class="qty">
                          {{ newProp.luas_tanah }}<span>m<sup>2</sup></span>
                        </p>
                      </div>
                      <p class="title">Luas Tanah</p>
                    </div>
                    <div class="wrapper">
                      <div class="top-text">
                        <v-img
                          src="@/assets/area-building.png"
                          width="20px"
                          max-width="20px"
                          contain
                          height="20px"
                        ></v-img>
                        <p class="qty">
                          {{ newProp.luas_tanah }}<span>m<sup>2</sup></span>
                        </p>
                      </div>
                      <p class="title">Luas Bangunan</p>
                    </div>
                  </div>
                </router-link>

                <div class="creator-section">
                  <div class="creator">
                    <v-img
                      :src="this.$apiImg + newProp.creator.image_url"
                      cover
                      min-width="25px"
                      max-width="25px"
                      min-height="25px"
                      max-height="25px"
                      class="creator-img"
                    ></v-img>
                    <p class="name">{{ newProp.creator.name }}</p>
                  </div>
                  <a
                    :href="`https://wa.me/${newProp.creator.phone_number}`"
                    target="_blank"
                    class="creator-wa"
                  >
                    <v-img
                      src="@/assets/whatsapp-logo.png"
                      contain
                      min-width="20px"
                      max-width="20px"
                      min-height="20px"
                      max-height="20px"
                      class="wa-img"
                    ></v-img>
                    <p class="wa">WhatsApp</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </v-row>
      </v-container>
    </section>

    <!-- Section Our Services -->
    <section class="service">
      <v-container>
        <v-row>
          <div class="wrapper">
            <h2>Layanan Kami</h2>
            <div class="service-wrap">
              <div class="service-group">
                <v-img
                  src="@/assets/service-1.png"
                  width="50px"
                  height="50px"
                  contain
                ></v-img>
                <div class="service-text">
                  <p class="title">Temukan Properti Impianmu</p>
                  <p class="desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
              <div class="service-group">
                <v-img
                  src="@/assets/service-2.png"
                  width="50px"
                  height="50px"
                  contain
                ></v-img>
                <div class="service-text">
                  <p class="title">Agen Properti Profesional</p>
                  <p class="desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
              <div class="service-group">
                <v-img
                  src="@/assets/service-3.png"
                  width="50px"
                  height="50px"
                  contain
                ></v-img>
                <div class="service-text">
                  <p class="title">Informasi Properti Terbaru</p>
                  <p class="desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </v-row>
      </v-container>
    </section>

    <!-- Section Favourite Location -->
    <!-- <section class="location">
      <v-container>
        <v-row>
          <div class="wrapper">
            <div class="location-title">
              <h2>Lokasi Favorit</h2>
              <p>
                Lokasi yang sangat strategis dan memiliki fasilitas yang sangat
                memadai
              </p>
            </div>
            <div class="location-content">
              <div class="location-card" v-for="i in 4" :key="i">
                <v-img
                  src="@/assets/location-img.png"
                  class="location-img"
                  width="100%"
                  height="500px"
                  cover
                ></v-img>
                <div class="location-text">
                  <p class="title">CANDI GOLF, SEMARANG</p>
                  <p class="amount">29 Properties</p>
                  <router-link class="find-property" to="/#"
                    >Cari Properti</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </v-row>
      </v-container>
    </section> -->

    <!-- Section Partner -->
    <section class="partner">
      <v-container>
        <v-row>
          <div class="wrapper">
            <v-img
              src="@/assets/partner-1.png"
              width="200px"
              max-width="200px"
              class="partner-img"
            ></v-img>
            <v-img
              src="@/assets/partner-2.png"
              width="200px"
              max-width="200px"
              class="partner-img"
            ></v-img>
            <v-img
              src="@/assets/partner-3.png"
              width="200px"
              max-width="200px"
              class="partner-img"
            ></v-img>
            <v-img
              src="@/assets/partner-4.png"
              width="200px"
              max-width="200px"
              class="partner-img"
            ></v-img>
            <v-img
              src="@/assets/partner-5.png"
              width="200px"
              max-width="200px"
              class="partner-img"
            ></v-img>
          </div>
        </v-row>
      </v-container>
    </section>

    <!-- Section Find Agent -->
    <section class="find-agent">
      <v-container>
        <v-row>
          <div class="wrapper">
            <h2>Temukan agen yang tepat<br />sesuai dengan kebutuhanmu</h2>
            <p>
              Temukan agen yang terpercaya dan professional untuk membantu
              memberikan solusi sesuai dengan kebutuhan properti anda.
            </p>
            <router-link to="/agents" class="to-agents"
              >Temukan Agen Propertimu</router-link
            >
          </div>
        </v-row>
      </v-container>
    </section>

    <!-- Section Article -->
    <section class="article">
      <v-container>
        <v-row>
          <div class="wrapper">
            <h2>Artikel</h2>
            <div class="article-wrap">
              <router-link
                v-for="article in limitedArticleList"
                :key="article.id"
                :to="`/${article.slug}`"
                class="article-card"
              >
                <v-img
                  :src="`${this.$apiImg}${article.image_url}`"
                  class="article-img"
                  cover
                ></v-img>
                <div class="article-text">
                  <p class="release-date">
                    {{ formatDate(article.created_at) }}
                  </p>
                  <p class="title">{{ article.title }}</p>
                  <p class="preview">
                    {{ truncateText(article.preview, 100) }}
                  </p>
                </div>
              </router-link>
            </div>
          </div>
        </v-row>
      </v-container>
    </section>
  </v-app>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
const debounce = (callback, delay) => {
  let tid;
  return function (...args) {
    const ctx = this;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};
export default {
  name: "HomeView",
  data() {
    return {
      tab: "Dijual",
      items: ["Dijual", "Disewakan"],
      location: [],
      propertyType: null,
      keyword: "",
      minArea: null,
      maxArea: null,
      minPrice: null,
      maxPrice: null,
      bathroom: null,
      bedroom: null,
      locItems: [],
      propNewest: [],
      propHighlight: [],
      articleList: [],
    };
  },
  computed: {
    ...mapGetters([
      "getMinPrice",
      "getMaxPrice",
      "getMinArea",
      "getMaxArea",
      "getType",
      "getKeyword",
      "getBathroom",
      "getBedroom",
      "getLocateCity",
    ]),
    limitedArticleList() {
      return this.articleList.slice(0, 3);
    },
  },
  methods: {
    async getCity() {
      try {
        const res = await axios.get(`${this.$api}/area/semarang`);
        this.locItems = res.data.data;
        console.log(this.cityItems);
      } catch (error) {
        console.error(error);
      }
    },
    formatPriceFilter(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    formatMinPrice(event) {
      let numericValue = event.target.value.replace(/\D/g, "");
      let formattedValue = this.formatPrice(numericValue);
      this.minPrice = formattedValue;
    },

    formatMaxPrice(event) {
      let numericValue = event.target.value.replace(/\D/g, "");
      let formattedValue = this.formatPrice(numericValue);
      this.maxPrice = formattedValue;
    },

    ...mapActions([
      "setMinPrice",
      "setMaxPrice",
      "setMinArea",
      "setMaxArea",
      "setType",
      "setKeyword",
      "setBathroom",
      "setBedroom",
      "setLocateCity",
    ]),
    updateMinPrice(event) {
      this.setMinPrice(event.target.value);
    },
    updateMaxPrice(event) {
      this.setMaxPrice(event.target.value);
    },
    updateMinArea(event) {
      this.setMinArea(event.target.value);
    },
    updateMaxArea(event) {
      this.setMaxArea(event.target.value);
    },
    updateType(event) {
      this.setType(event.target.value);
    },
    updateKeyword(event) {
      this.setKeyword(event.target.value);
    },
    updateBedroom(event) {
      this.setBedroom(event.target.value);
    },
    updateBathroom(event) {
      this.setBathroom(event.target.value);
    },
    updateLocateCity(event) {
      this.setLocateCity(event.target.value);
    },
    saveFiltersAndMove() {
      const filters = {
        propertyType: this.propertyType,
        keyword: this.keyword,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
      };

      localStorage.setItem("filters", JSON.stringify(filters));
      this.$router.push({ name: "filtered" });
    },

    async getPropNewest() {
      try {
        const res = await axios.get(`${this.$api}/property/newest`);
        this.propNewest = res.data.data;
        console.log(this.propNewest);
      } catch (error) {
        console.log(error);
      }
    },
    formatPrice(value) {
      const formattedValue = (value / 1).toFixed(0).replace(".", ",");
      return formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async getPropHighlight() {
      try {
        const res = await axios.get(`${this.$api}/property/unggulan/highlight`);
        this.propHighlight = res.data;

        console.log(this.propHighlight);
        console.log(this.highLen);
      } catch (error) {
        console.log(error);
      }
    },
    async getArticleList() {
      this.loading = true;
      try {
        const res = await axios.get(
          `${this.$api}/article/list?page=${this.currentPage}`
        );
        if (res.status === 200 || res.status === 201) {
          this.articleList = res.data.data.data; // Access the nested data array
          console.log(this.articleList);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    truncateText(text, length) {
      if (!text) return "";
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
  },
  async mounted() {
    await this.getCity();
    await this.getPropNewest();
    await this.getArticleList();
    await this.getPropHighlight();
    const _ = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends _ {
      constructor(callback) {
        callback = debounce(callback, 20);
        super(callback);
      }
    };
  },
};
</script>

<style lang="scss" scoped>
section {
  padding: 50px 0;

  // Jumbotron
  &.jumbotron {
    padding-top: 0;
    position: relative;
    max-width: 100vw;

    .background {
      position: absolute;
      z-index: 1;
    }

    .jumbotron-content {
      position: relative;
      z-index: 2;

      .jumbotron-text {
        width: 100%;
        margin-top: 4rem;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        h1,
        p {
          text-align: center;
          color: $white;
        }
      }
    }

    .search-property {
      width: 100%;
      margin-top: 4rem;

      .search-form {
        padding: 2rem;
        border-radius: 1rem;
        background-color: $white;
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        // align-items: center;

        .input-contain {
          width: calc(28% - 12px);
          box-sizing: border-box;
          &.keyword {
            width: 100%;
          }
        }

        .button-search {
          height: 44px;
          margin-top: 30px;
          padding: 10px 40px;
        }
      }

      .v-tab {
        text-transform: none !important;
        background-color: $button-color;
        color: $black;

        &--selected {
          background-color: $white;
          color: $primary-color;
          border: none;
        }

        &:first-child {
          border-radius: 7px 0 0 0;
        }

        &:last-child {
          border-radius: 0 7px 0 0;
        }
      }

      .input-contain {
        P {
          color: $font-color;
          font-weight: $font-weight-medium;
          margin-bottom: 10px;
        }
      }

      .v-window {
        box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.07);
      }

      .v-card {
        border-radius: 0 7px 7px 7px;
      }
    }
  }

  // Properties
  &.properties {
    &.best {
      .card-wrap {
        justify-content: center;
      }
    }
    .wrapper {
      width: 90%;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 3rem;

      .properties-text {
        h2 {
          text-align: center;
          color: $primary-color;
          margin-bottom: 10px;
        }

        p {
          font-size: 19px;
          text-align: center;
        }
      }

      .card-wrap {
        display: flex;
        gap: 5%;
        row-gap: 3rem;
        flex-wrap: wrap;

        .card {
          width: 30%;
          display: flex;
          flex-direction: column;
          box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
          background-color: $white;
          border-radius: 10px;
          max-height: 485px;

          .property-card {
            text-decoration: none;
            display: flex;
            flex-direction: column;

            .property-img {
              border-radius: 10px 10px 0 0;
            }

            .mid-section {
              display: flex;
              flex-direction: column;
              gap: 5px;
              padding: 15px;

              .price {
                font-size: 20px;
                font-weight: $font-weight-semibold;
                color: $black;
              }

              .type {
                font-size: 12px;
                font-weight: $font-weight-semibold;
                text-transform: uppercase;
                color: $black;
              }

              .property-name {
                font-size: 14px;
                color: $black;
                font-weight: $font-weight-medium;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .location {
                display: flex;
                gap: 3px;
                align-items: center;
                color: $roman-silver;
                font-size: 12px;
                font-weight: $font-weight-medium;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .bottom-section {
              padding: 15px;
              display: flex;
              justify-content: space-between;
              border-bottom: 1.5px solid #dedede;
              border-top: 1.5px solid #dedede;

              .wrapper {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: fit-content;

                .top-text {
                  display: flex;
                  gap: 10px;
                  align-items: center;

                  .qty {
                    font-weight: $font-weight-semibold;
                    font-size: 16px;
                    color: $roman-silver;
                    line-height: 18px;

                    span {
                      font-size: 12px;
                    }
                  }
                }

                .title {
                  color: $roman-silver;
                  font-weight: $font-weight-medium;
                  font-size: 9px;
                }
              }
            }
          }
          .creator-section {
            padding: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .creator {
              gap: 10px;
              display: flex;
              align-items: center;
              .creator-img {
                border-radius: 100px;
              }

              .name {
                color: $roman-silver;
                font-size: 13px;
              }
            }
            .creator-wa {
              display: flex;
              gap: 10px;
              align-items: center;
              padding: 5px 15px;
              border-radius: 30px;
              text-decoration: none;
              border: 1px solid #33bb47;

              .wa {
                color: #33bb47;
                font-size: 10px;
                font-weight: $font-weight-medium;
              }
            }
          }
        }
      }

      .see-more {
        margin: auto;
        text-decoration: none;
        font-weight: $font-weight-medium;
        color: $primary-color;
        padding: 10px 3rem;
        border-radius: 5px;
        border: 1.5px solid var(--cheese, #ffa600);
      }
    }
  }

  // Services
  &.service {
    background-color: $secondary-color;
    margin: 50px 0;

    .wrapper {
      display: flex;
      margin: auto;
      flex-direction: column;
      gap: 3rem;
      width: 90%;
      justify-content: center;
      align-items: center;

      h2 {
        text-align: center;
        color: $primary-color;
      }

      .service-wrap {
        display: flex;
        gap: 30px;

        .service-group {
          display: flex;
          gap: 1.5rem;

          .service-text {
            .title {
              color: $black;
              font-size: 18px;
              font-weight: $font-weight-semibold;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  // Location
  &.location {
    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      width: 90%;
      margin: auto;

      .location-title {
        h2 {
          color: $primary-color;
          text-align: center;
          margin-bottom: 10px;
        }

        p {
          text-align: center;
        }
      }

      .location-content {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        row-gap: 20px;

        .location-card {
          width: calc(25% - 15px);

          .location-img {
            border-radius: 5px;
          }

          .location-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            padding: 20px;

            .title {
              text-align: center;
              text-transform: uppercase;
              font-weight: $font-weight-medium;
            }

            .amount {
              font-size: 15px;
              font-weight: $font-weight-semibold;
              color: $roman-silver;
              text-align: center;
            }

            .find-property {
              width: fit-content;
              text-align: center;
              color: $primary-color;
              text-decoration: none;
              font-size: 15px;
              font-weight: $font-weight-semibold;
            }
          }
        }
      }
    }
  }

  // Partner
  &.partner {
    .wrapper {
      width: 100%;
      display: flex;
      gap: 3rem;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  // Find Agent
  &.find-agent {
    background-color: $secondary-color;
    background-image: url(@/assets/bg-agent.png);
    background-position: right bottom;
    background-size: 45%;
    margin: 3rem 0;

    .wrapper {
      padding: 5rem 0;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      h2 {
        color: $primary-color;
      }

      p {
        width: 65%;
        font-size: 18px;
      }

      .to-agents {
        text-decoration: none;
        color: $black;
        font-weight: $font-weight-medium;
        padding: 1rem 2rem;
        border-radius: 10px;
        background-color: $button-color;
        width: fit-content;
      }
    }
  }

  // Article
  &.article {
    .wrapper {
      width: 90%;
      margin: auto;

      h2 {
        color: $primary-color;
        text-align: center;
        margin-bottom: 3rem;
      }

      .article-wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 5%;
        row-gap: 2rem;
        width: 100%;

        .article-card {
          width: 30%;
          text-decoration: none;

          .article-img {
            border-radius: 5px;
            height: 200px;
            margin-bottom: 1.5rem;
          }

          .article-text {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .release-date {
              color: $button-color;
              font-size: 13px;
              font-weight: $font-weight-semibold;
            }

            .title {
              font-size: 20px;
              color: $black;
              font-weight: $font-weight-semibold;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }

            .preview {
              font-size: 13px;
              color: $font-color;
              font-weight: $font-weight-medium;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $bp-lg) {
  section {
    &.properties {
      &.best {
        .wrapper {
          .card-wrap {
            .card {
              &:last-child {
                display: none;
              }
            }
          }
        }
      }
      .wrapper {
        .card-wrap {
          gap: 6%;
          row-gap: 3rem;
          .card {
            width: 47%;
          }
        }
      }
    }

    &.service {
      .wrapper {
        .service-wrap {
          flex-direction: column;
        }
      }
    }

    &.find-agent {
      .wrapper {
        padding: 0 1rem;

        p {
          width: 65%;
          font-size: 16px;
        }
      }
    }
  }
}

// Medium screen responsive (<= Ipad)
@media screen and (max-width: $bp-md) {
  section {
    &.jumbotron {
      .search-property {
        margin: 20px;
        .search-form {
          flex-direction: column;
          .input-contain {
            width: 100% !important;
          }
        }
      }
    }

    &.location {
      .wrapper {
        .location-content {
          display: flex;
          gap: 20px;
          row-gap: 20px;

          .location-card {
            width: calc(50% - 10px);

            .location-img {
              height: 230px !important;
            }
          }
        }
      }
    }

    &.article {
      .wrapper {
        .article-wrap {
          .article-card {
            width: 47.5%;
            &:last-child {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $bp-sm) {
  section {
    &.properties {
      &.best {
        .wrapper {
          .card-wrap {
            .card {
              &:last-child {
                display: flex;
              }
            }
          }
        }
      }
      .wrapper {
        .properties-text {
          p {
            font-size: 13px;
          }
        }
        .card-wrap {
          gap: 0;
          row-gap: 3rem;
          .card {
            width: 100%;
          }
        }
      }
    }

    &.location {
      .wrapper {
        width: 80%;
        .location-content {
          display: flex;
          gap: 20px;
          row-gap: 20px;

          .location-card {
            width: 100%;
          }
        }
      }
    }

    &.partner {
      .wrapper {
        gap: 2rem;
        width: 90%;
        margin: auto;

        .partner-img {
          width: 80px !important;
        }
      }
    }

    &.find-agent {
      background-size: 75%;
      .wrapper {
        padding: 0 1rem;
        gap: 1rem;

        h2 {
          font-size: 22px;
        }

        p {
          width: 100%;
          font-size: 13px;
        }

        .to-agents {
          padding: 10px 15px;
          font-size: 13px;
          margin-bottom: 5rem;
          border-radius: 5px;
        }
      }
    }

    &.article {
      .wrapper {
        .article-wrap {
          .article-card {
            width: 100%;
            &:last-child {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
