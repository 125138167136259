<template>
  <section class="agents">
    <v-container>
      <v-row>
        <div class="wrapper">
          <!-- <v-select
            v-model="filterAgent"
            class="filter"
            :items="filter"
            placeholder="filter"
            variant="outlined"
            base-color="#818A91"
            density="compact"
            bg-color="#fff"
            color="#363636"
          ></v-select> -->
          <div class="card-list">
            <div
              v-for="item in agentList.data"
              :key="item.id"
              class="agent-card"
            >
              <div class="card-wrap">
                <v-img
                  src="@/assets/user-big.png"
                  class="agent-photo"
                  cover
                  v-if="item.image_url == 'default/profile.png'"
                ></v-img>
                <v-img
                  :src="`${this.$apiImg}${item.image_url}`"
                  class="agent-photo"
                  cover
                  v-else
                ></v-img>

                <div class="agent-info">
                  <p class="name">{{ item.name }}</p>
                  <div class="phone-email">
                    <div class="group">
                      <p class="value">{{ item.phone_number }}</p>
                    </div>
                    <div class="group">
                      <p class="value">{{ item.email }}</p>
                    </div>
                  </div>
                  <div class="wrap-links desktop">
                    <router-link
                      class="to-agent-detail"
                      :to="`/agents/${item.id}`"
                    >
                      Lihat
                    </router-link>
                    <a
                      :href="`https://wa.me/${item.phone_number}`"
                      target="_blank"
                      class="creator-wa"
                    >
                      <v-img
                        src="@/assets/whatsapp-logo.png"
                        contain
                        min-width="20px"
                        max-width="20px"
                        min-height="20px"
                        max-height="20px"
                        class="wa-img"
                      ></v-img>
                      <p class="wa">WhatsApp</p>
                    </a>
                  </div>
                </div>

                <div class="wrap-links mobile">
                  <router-link
                    class="to-agent-detail"
                    :to="`/agents/${item.id}`"
                  >
                    Lihat
                  </router-link>
                  <a
                    :href="`https://wa.me/${item.phone_number}`"
                    target="_blank"
                    class="creator-wa"
                  >
                    <v-img
                      src="@/assets/whatsapp-logo.png"
                      contain
                      min-width="20px"
                      max-width="20px"
                      min-height="20px"
                      max-height="20px"
                      class="wa-img"
                    ></v-img>
                    <p class="wa">WhatsApp</p>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <v-pagination
            v-model="currentPage"
            :total-visible="5"
            :length="agentList.last_page"
          ></v-pagination>
        </div>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      filter: ["Dijual", "Disewakan"],
      filterAgent: "",
      itemsPerPage: 8,
      currentPage: 1,
      agentList: [],
    };
  },
  methods: {
    async getAgentList() {
      this.loading = true;
      try {
        const res = await axios.get(
          `${this.$api}/user/agent/list?page=${this.currentPage}`
        );
        if (res.status === 200 || res.status === 201) {
          this.agentList = res.data.data;
          console.log(this.agentList);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    displayedItems() {
      const startIdx = (this.currentPage - 1) * this.itemsPerPage;
      const endIdx = startIdx + this.itemsPerPage;
      return this.items.slice(startIdx, endIdx);
    },
  },
  async mounted() {
    await this.getAgentList();
  },
  watch: {
    currentPage() {
      this.getAgentList();
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  padding: 50px 0;
  &.agents {
    .wrapper {
      width: 100%;

      .filter {
        width: 200px;
      }

      .card-list {
        margin-bottom: 3rem;
        display: flex;
        flex-wrap: wrap;
        gap: 4%;
        row-gap: 40px;

        .agent-card {
          width: 48%;
          padding: 20px;
          box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
          // max-height: 230px;
          border-radius: 7px;

          .card-wrap {
            display: flex;
            // width: 100%;
            align-items: center;
            height: 100%;
            gap: 20px;

            .agent-photo {
              border-radius: 50%;
              max-height: 190px;
              max-width: 190px;
              aspect-ratio: 1/1;
            }

            .agent-info {
              width: 65%;
              display: flex;
              flex-direction: column;
              gap: 15px;

              .name {
                font-size: 18px;
                font-weight: $font-weight-semibold;
              }

              .phone-email {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .group {
                  display: flex;
                  justify-content: space-between;

                  .title {
                    font-weight: $font-weight-semibold;
                  }

                  .value {
                    font-weight: $font-weight-medium;
                  }
                }
              }
            }
            .wrap-links {
              display: flex;
              gap: 5%;
              width: 100%;

              &.mobile {
                display: none;
              }
              .to-agent-detail {
                text-decoration: none;
                border-radius: 5px;
                background-color: $button-color;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35%;
                padding: 7px;
                color: $font-color;
                font-size: 14px;
                font-weight: $font-weight-medium;
              }
              .creator-wa {
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: center;
                width: 60%;
                border-radius: 5px;
                padding: 5px;
                text-decoration: none;
                border: 1px solid #33bb47;

                .wa {
                  color: #33bb47;
                  font-size: 14px;
                  font-weight: $font-weight-medium;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $bp-md) {
  section {
    &.agents {
      .wrapper {
        width: 90%;
        margin: auto;

        .card-list {
          .agent-card {
            max-height: unset;
            .card-wrap {
              flex-wrap: wrap;
              gap: 20px;
              align-items: center;
              justify-content: space-between;
              .agent-photo {
                max-height: 80px;
                max-width: 80px;
                aspect-ratio: 1/1;
              }

              .agent-info {
                width: 70%;
                gap: 20px;
                align-items: flex-end;

                .name,
                .value {
                  text-align: end;
                  margin-left: auto;
                }

                .to-agent-detail {
                  margin: auto;
                }
              }

              .wrap-links {
                &.desktop {
                  display: none;
                }
                &.mobile {
                  display: flex;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $bp-sm) {
  section {
    &.agents {
      .wrapper {
        .card-list {
          flex-direction: column;
          .agent-card {
            width: 100%;

            .agent-info {
              gap: 10px !important;

              .name {
                font-size: 14px;
              }

              .value {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
