<template>
  <v-app>
    <CustHeader />
    <router-view />
    <CustFooter />
  </v-app>
</template>

<script>
import CustFooter from "../component/custFooter.vue";
import CustHeader from "../component/custHeader.vue";

export default {
  components: {
    CustFooter,
    CustHeader,
  },
};
</script>

<style></style>
