<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  name: "App",

  data: () => ({
    //
  }),

  mounted() {
    var data = JSON.parse(localStorage.getItem("data"));
    if (data && data.token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
      // console.log(data.token);
    } else {
      console.log("undef");
    }
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.my-app.v-application .primary--text {
  color: $font-color !important;
}

* {
  font-family: "Poppins", sans-serif !important;
}
</style>
