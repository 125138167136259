import { createStore } from "vuex";

export default createStore({
  state: {
    minPrice: null,
    maxPrice: null,
    minArea: null,
    maxArea: null,
    propertyType: "",
    keyword: "",
    nToilet: "",
    nRoom: "",
    locateCity: "",
    // other filter-related values...
  },
  mutations: {
    SET_MAX_PRICE(state, value) {
      state.maxPrice = value;
    },
    SET_MIN_PRICE(state, value) {
      state.minPrice = value;
    },
    SET_TYPE(state, value) {
      state.propertyType = value;
    },
    SET_MAX_AREA(state, value) {
      state.maxArea = value;
    },
    SET_MIN_AREA(state, value) {
      state.minArea = value;
    },
    SET_KEYWORD(state, value) {
      state.keyword = value;
    },
    SET_BATHROOM(state, value) {
      state.nToilet = value;
    },
    SET_BEDROOM(state, value) {
      state.nRoom = value;
    },
    SET_LOCATION(state, value) {
      state.locateCity = value;
    },
    // other mutations for different filters...
  },
  actions: {
    setMaxPrice({ commit }, value) {
      commit("SET_MAX_PRICE", value);
    },
    setMinPrice({ commit }, value) {
      commit("SET_MIN_PRICE", value);
    },
    setType({ commit }, value) {
      commit("SET_TYPE", value);
    },
    setMaxArea({ commit }, value) {
      commit("SET_MAX_AREA", value);
    },
    setMinArea({ commit }, value) {
      commit("SET_MIN_AREA", value);
    },
    setKeyword({ commit }, value) {
      commit("SET_KEYWORD", value);
    },
    setBathroom({ commit }, value) {
      commit("SET_BATHROOM", value);
    },
    setBedroom({ commit }, value) {
      commit("SET_BEDROOM", value);
    },
    setLocateCity({ commit }, value) {
      commit("SET_BEDROOM", value);
    },
    // actions for other filters...
  },
  getters: {
    getMaxPrice: (state) => state.maxPrice,
    getMinPrice: (state) => state.minPrice,
    getType: (state) => state.propertyType,
    getMaxArea: (state) => state.maxArea,
    getMinArea: (state) => state.minArea,
    getKeyword: (state) => state.keyword,
    getBathroom: (state) => state.nToilet,
    getBedroom: (state) => state.nRoom,
    getLocateCity: (state) => state.locateCity,
    // getters for other filters...
  },
});
