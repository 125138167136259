<template>
  <header class="company-nav">
    <v-img class="logo" src="@/assets/logo-header.png"></v-img>
    <nav>
      <router-link to="/admin/my-account" class="menu">
        <v-img src="@/assets/dashboard.png" class="icon-menu"></v-img>
        <v-img
          src="@/assets/dashboard-black.png"
          class="icon-menu-active"
        ></v-img>
        Dashboard
      </router-link>

      <div class="outer">
        <div class="menu" @click="dropdownToggle">
          <v-img src="@/assets/prop-icon-gray.png" class="icon-menu"></v-img>
          Properti
          <v-img
            :class="{ 'rotate-chevron': dropdown }"
            src="@/assets/chevron-up.png"
            class="dropdown"
            contain
          ></v-img>
        </div>
        <div class="inner" v-if="dropdown">
          <router-link to="/admin/properties" class="menu">
            <v-img src="@/assets/prop-icon-gray.png" class="icon-menu"></v-img>
            <v-img
              src="@/assets/prop-icon.png"
              class="icon-menu-active"
            ></v-img>
            Semua Properti
          </router-link>
          <router-link
            to="/admin/properti-unggulan"
            class="menu"
            v-if="roles == 'owner'"
          >
            <v-img src="@/assets/bm-outlined.png" class="icon-menu"></v-img>
            <v-img
              src="@/assets/bm-outlined-active.png"
              class="icon-menu-active"
            ></v-img>
            Properti Unggulan
          </router-link>
        </div>
      </div>
      <router-link to="/admin/articles" class="menu">
        <v-img src="@/assets/article-outlined.png" class="icon-menu"></v-img>
        <v-img
          src="@/assets/article-outlined-active.png"
          class="icon-menu-active"
        ></v-img>
        Article
      </router-link>
      <router-link to="/admin/user-list" class="menu" v-if="roles == 'owner'">
        <v-img src="@/assets/user-icon.png" class="icon-menu"></v-img>
        <v-img
          src="@/assets/user-icon-black.png"
          class="icon-menu-active"
        ></v-img>
        User
      </router-link>
    </nav>
  </header>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      dropdown: true,
      roles: null,
    };
  },
  methods: {
    dropdownToggle() {
      this.dropdown = !this.dropdown;
    },
    async getUserDetail() {
      try {
        const res = await axios.get(`${this.$api}/profile/detail`);
        if (res.status === 200 || res.status === 201) {
          this.roles = res.data.data.roles[0].name;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    await this.getUserDetail();
  },
};
</script>

<style lang="scss" scoped>
header {
  width: 20%;
  background-color: $secondary-color;
  padding: 30px 20px;

  .logo {
    height: 60px;
    margin-bottom: 3rem;
  }

  nav {
    display: flex;
    flex-direction: column;

    .menu {
      display: flex;
      gap: 15px;
      padding: 10px 15px;
      color: $roman-silver;
      text-decoration: none;

      .dropdown {
        max-width: 1rem;
        margin-left: auto;
      }

      .icon-menu {
        width: 24px;
        max-width: 24px;
        height: 24px;
      }

      .icon-menu-active {
        display: none;
      }

      &.router-link-active {
        color: $font-color;
        border-radius: 3px;
        background: rgba(255, 166, 0, 0.15);

        .icon-menu-active {
          width: 24px;
          max-width: 24px;
          height: 24px;
          display: block;
        }

        .icon-menu {
          display: none;
        }
      }
    }
    .rotate-chevron {
      transform: rotate(180deg);
      transition: transform 0.2s ease; /* Optional: Add a smooth transition effect */
    }

    .outer {
      .inner {
        .menu {
          padding-left: 3.5rem;
        }
      }
    }
  }
}
</style>
