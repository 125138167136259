<template>
  <div class="profile">
    <div class="preview" @click="toggleMenu()">
      <div class="bg-profile">
        <v-img
          src="@/assets/user-img-default.png"
          max-width="45px"
          max-height="45px"
          min-height="45px"
          min-width="45px"
          class="profile-pic"
          cover
          v-if="userDetail.image_url == 'default/profile.png'"
        ></v-img>
        <v-img
          :src="`${this.$apiImg}${userDetail.image_url}`"
          max-width="45px"
          max-height="45px"
          min-height="45px"
          min-width="45px"
          class="profile-pic"
          cover
          v-else
        ></v-img>
      </div>
      <div class="name-role">
        <p class="name">{{ userDetail.name }}</p>
        <p class="role">{{ roles }}</p>
      </div>
    </div>
    <div class="collapsible-menu" v-if="menuNav">
      <router-link to="/admin/my-account" class="menu"
        ><v-img src="@/assets/user-grey-solid.png" class="menu-icon"></v-img>
        <p>Akun Saya</p></router-link
      >
      <div class="menu" @click="toggleLogout()">
        <v-img src="@/assets/logout-red.png" class="menu-icon"></v-img>
        <p>Log Out</p>
      </div>
    </div>
  </div>
  <section class="pop-out-log-out" v-if="confirmLogout">
    <v-container
      ><v-row>
        <div class="wrapper">
          <v-img
            class="close-pop-up"
            src="@/assets/close-btn.png"
            @click="toggleLogout()"
          ></v-img>
          <p class="prompt">Apakah anda yakin untuk keluar?</p>
          <div class="button-ctrl">
            <div class="btn cancel" @click="toggleLogout()">Cancel</div>
            <div class="btn yes" @click="logout()">Ya, keluar</div>
          </div>
        </div>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    menuNav: false,
    confirmLogout: false,
    userDetail: [],
    roles: null,
  }),
  methods: {
    toggleMenu() {
      this.menuNav = !this.menuNav;
    },
    toggleLogout() {
      this.confirmLogout = !this.confirmLogout;
    },
    logout() {
      console.log("Logging out");
      localStorage.clear();

      const isDataCleared = localStorage.getItem("data") === null;

      if (isDataCleared) {
        console.log("Local Storage Cleared");
        this.$router.push({ name: "login" });
        console.log("Router navigated to login");
      } else {
        console.log("Unable to clear local storage");
      }
    },
    async getUserDetail() {
      try {
        const res = await axios.get(`${this.$api}/profile/detail`);
        if (res.status === 200 || res.status === 201) {
          this.userDetail = res.data.data;
          this.roles = res.data.data.roles[0].name;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    await this.getUserDetail();
  },
};
</script>

<style lang="scss" scoped>
.profile {
  position: relative;
  .preview {
    display: flex;
    gap: 10px;
    align-items: center;
    position: relative;
    cursor: pointer;

    .bg-profile {
      background-color: $white;
      border-radius: 50px;

      .profile-pic {
        border-radius: 50px;
      }
    }

    .name {
      font-size: 14px;
      font-weight: $font-weight-semibold;
      margin-bottom: 5px;
    }
  }

  .role {
    font-size: 12px;
    color: $roman-silver;
  }

  .collapsible-menu {
    border-radius: 5px;
    border: 1.5px solid $gainsboro;
    background-color: $white;
    position: absolute;
    top: 60px;
    width: 150%;
    z-index: 999;
    right: 0;

    .menu {
      display: flex;
      padding: 15px 25px;
      align-items: center;
      gap: 15px;
      text-decoration: none;
      cursor: pointer;

      .menu-icon {
        max-width: 16px;
        min-width: 16px;
        min-height: 16px;
        max-height: 16px;
      }

      p {
        color: $roman-silver;
        font-size: 14px;
      }

      &:hover {
        background-color: #ffefd1;
      }
    }
  }
}
.pop-out-log-out {
  background: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  .wrapper {
    width: 400px;
    margin: auto;
    background-color: $white;
    padding: 2rem;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: relative;

    .close-pop-up {
      width: 30px;
      height: 30px;
      max-width: 30px;
      max-height: 30px;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .prompt {
      text-align: center;
      width: 100%;
      margin-top: 10px;
      font-weight: $font-weight-medium;
    }

    .button-ctrl {
      display: flex;
      gap: 5px;
      width: 100%;
      .btn {
        width: 50%;
        text-align: center;
        padding: 7px 0;
        border-radius: 5px;
        font-size: 14px;
        font-weight: $font-weight-medium;
        cursor: pointer;

        &.cancel {
          color: #ff2828;
          background-color: #f7f7f7;
        }

        &.yes {
          background-color: $primary-color;
        }
      }
    }
  }
}
</style>
