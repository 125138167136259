<template>
  <v-app>
    <section class="layout-company">
      <CompanyNav />
      <router-view />
    </section>
  </v-app>
</template>

<script>
import CompanyNav from "../component/CompanyNav.vue";

export default {
  components: {
    CompanyNav,
  },
};
</script>

<style lang="scss" scoped>
section {
  &.layout-company {
    display: flex;
    min-height: 100vh;
    width: 100vw;
  }
}
</style>
