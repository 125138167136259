import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as labsComponents from "vuetify/labs/components";
import { VDataTable } from "vuetify/labs/VDataTable";
import store from "./store";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

loadFonts();
const app = createApp(App);
app.use(router).use(vuetify);

app.config.productionTip = false;
app.config.globalProperties.$api = "https://api.hepiproperty.com/api";
app.config.globalProperties.$apiImg = "https://api.hepiproperty.com/storage/";
app.use(store);
app.mount("#app");

export default createVuetify({
  components: {
    ...components,
    ...labsComponents,
    VDataTable,
  },
  icons: {
    iconfont: "mdi",
  },
});
