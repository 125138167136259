<template>
  <v-app>
    <section class="detail">
      <v-container>
        <v-row>
          <div class="image-galery desktop">
            <div class="left-img">
              <v-img
                v-if="
                  propDetail &&
                  propDetail.images &&
                  propDetail.images.length > 0 &&
                  propDetail.images[0].image_url
                "
                :src="`${this.$apiImg}${propDetail.images[0].image_url}`"
                class="prop-img"
                height="450px"
                min-height="450px"
                max-height="450px"
                cover
              ></v-img>
              <v-img
                v-else
                src="@/assets/default-img.png"
                class="prop-img-inav"
                height="450px"
                min-height="450px"
                max-width="200px"
                max-height="450px"
                contain
              ></v-img>
              <v-btn variant="flat" class="btn-share" @click="sharePropPopup"
                ><v-img
                  src="@/assets/share.png"
                  width="13px"
                  height="13px"
                  class="image-share"
                  contain
                ></v-img
                >Share</v-btn
              >
            </div>
            <div class="right-img">
              <div class="right-top">
                <v-img
                  v-if="
                    propDetail &&
                    propDetail.images &&
                    propDetail.images.length > 1 &&
                    propDetail.images[1].image_url
                  "
                  :src="`${this.$apiImg}${propDetail.images[1].image_url}`"
                  class="prop-img"
                  height="215px"
                  max-height="215px"
                  cover
                ></v-img>
                <v-img
                  v-else
                  src="@/assets/default-img.png"
                  class="prop-img"
                  height="215px"
                  max-width="150px"
                  max-height="215px"
                  contain
                ></v-img>
              </div>
              <div class="more-image" @click="toggleGalleryPopup">
                <v-img
                  v-if="
                    propDetail &&
                    propDetail.images &&
                    propDetail.images.length > 2 &&
                    propDetail.images[2].image_url
                  "
                  :src="`${this.$apiImg}${propDetail.images[2].image_url}`"
                  class="prop-img"
                  height="215px"
                  min-height="215px"
                  cover
                ></v-img>
                <v-img
                  v-else
                  src="@/assets/default-img.png"
                  class="prop-img"
                  padding
                  max-width="150px"
                  height="215px"
                  min-height="215px"
                  contain
                ></v-img>
                <div class="see-more">
                  <v-img
                    src="@/assets/galery-icon.png"
                    width="40px"
                    height="40px"
                    max-height="40px"
                  ></v-img>
                  <p v-if="imgLen < 2">0 lainnya</p>
                  <p v-else>{{ imgLen - 2 }} lainnya</p>
                </div>
              </div>
            </div>
          </div>
          <div class="image-galery mobile">
            <div class="left-img">
              <v-img
                v-if="
                  propDetail &&
                  propDetail.images &&
                  propDetail.images.length > 0 &&
                  propDetail.images[0].image_url
                "
                :src="`${this.$apiImg}${propDetail.images[0].image_url}`"
                class="prop-img"
                height="450px"
                min-height="450px"
                max-height="450px"
                cover
              ></v-img>
              <v-img
                v-else
                src="@/assets/default-img.png"
                class="prop-img-inav"
                height="450px"
                min-height="450px"
                max-width="200px"
                max-height="450px"
                contain
              ></v-img>
              <v-btn variant="flat" class="btn-share" @click="sharePropPopup()">
                <v-img
                  src="@/assets/share.png"
                  width="13px"
                  height="13px"
                  class="image-share"
                  contain
                ></v-img>
              </v-btn>
            </div>
            <div class="right-img">
              <v-img
                v-if="
                  propDetail &&
                  propDetail.images &&
                  propDetail.images.length > 1 &&
                  propDetail.images[1].image_url
                "
                :src="`${this.$apiImg}${propDetail.images[1].image_url}`"
                class="prop-img"
                height="215px"
                max-height="215px"
                cover
              ></v-img>
              <v-img
                v-else
                src="@/assets/default-img.png"
                class="prop-img"
                height="215px"
                max-width="150px"
                max-height="215px"
                contain
              ></v-img>
              <v-img
                v-if="
                  propDetail &&
                  propDetail.images &&
                  propDetail.images.length > 2 &&
                  propDetail.images[2].image_url
                "
                :src="`${this.$apiImg}${propDetail.images[2].image_url}`"
                class="prop-img"
                cover
              ></v-img>
              <v-img
                v-else
                src="@/assets/default-img.png"
                class="prop-img"
                height="215px"
                contain
              ></v-img>
              <div class="more-image" @click="toggleGalleryPopup">
                <v-img
                  v-if="
                    propDetail &&
                    propDetail.images &&
                    propDetail.images.length > 2 &&
                    propDetail.images[2].image_url
                  "
                  :src="`${this.$apiImg}${propDetail.images[2].image_url}`"
                  class="prop-img"
                  cover
                ></v-img>
                <v-img
                  v-else
                  src="@/assets/default-img.png"
                  class="prop-img"
                  padding
                  max-width="150px"
                  contain
                ></v-img>
                <div class="see-more">
                  <v-img
                    src="@/assets/galery-icon.png"
                    width="40px"
                    height="40px"
                    max-height="40px"
                  ></v-img>
                  <p v-if="imgLen < 3">0 lainnya</p>
                  <p v-else>{{ imgLen - 3 }} lainnya</p>
                </div>
              </div>
            </div>
          </div>
        </v-row>

        <v-row>
          <div class="property-detail">
            <div class="prop-info">
              <div class="general-info">
                <h3 class="price">Rp{{ formatPrice(propDetail.harga) }}</h3>

                <div class="prop-status">
                  <div class="status">
                    <p>{{ propDetail.status }}</p>
                  </div>
                </div>
                <h4 class="prop-name">{{ propDetail.judul }}</h4>
                <div class="location">
                  <v-img
                    src="@/assets/location.png"
                    width="25px"
                    height="25px"
                  ></v-img>
                  <p>{{ propDetail.area }},</p>
                </div>
              </div>

              <div class="detailed-info">
                <h4 class="title">Informasi Properti</h4>
                <div class="specification">
                  <p class="title">Detail Properti</p>
                  <div class="spec-list">
                    <div class="spec-group">
                      <p class="spec-name">Tipe Properti</p>
                      <p class="spec-value">
                        {{ propDetail.tipe_properti || "-" }}
                      </p>
                    </div>

                    <div class="spec-group">
                      <p class="spec-name">Kamar Tidur</p>
                      <p class="spec-value">
                        {{ propDetail.kamar_tidur || "-" }}
                      </p>
                    </div>

                    <div class="spec-group">
                      <p class="spec-name">Kamar Mandi</p>
                      <p class="spec-value">
                        {{ propDetail.kamar_mandi || "-" }}
                      </p>
                    </div>

                    <div class="spec-group">
                      <p class="spec-name">Luas Tanah</p>
                      <p class="spec-value">
                        {{
                          propDetail.luas_tanah
                            ? propDetail.luas_tanah + "m²"
                            : "-"
                        }}
                      </p>
                    </div>

                    <div class="spec-group">
                      <p class="spec-name">Luas Bangunan</p>
                      <p class="spec-value">
                        {{
                          propDetail.luas_bangunan
                            ? propDetail.luas_bangunan + "m²"
                            : "-"
                        }}
                      </p>
                    </div>

                    <div class="spec-group">
                      <p class="spec-name">Kamar Tidur Pembantu</p>
                      <p class="spec-value">
                        {{ propDetail.kamar_tidur_pembantu || "-" }}
                      </p>
                    </div>

                    <div class="spec-group">
                      <p class="spec-name">Kamar Mandi Pembantu</p>
                      <p class="spec-value">
                        {{ propDetail.kamar_mandi_pembantu || "-" }}
                      </p>
                    </div>

                    <div class="spec-group">
                      <p class="spec-name">Listrik</p>
                      <p class="spec-value">
                        {{
                          propDetail.listrik
                            ? propDetail.listrik + " Watt"
                            : "-"
                        }}
                      </p>
                    </div>

                    <div class="spec-group">
                      <p class="spec-name">Air</p>
                      <p class="spec-value">{{ propDetail.air || "-" }}</p>
                    </div>

                    <div class="spec-group">
                      <p class="spec-name">Sertifikat</p>
                      <p class="spec-value">
                        {{ propDetail.sertifikat || "-" }}
                      </p>
                    </div>

                    <div class="spec-group">
                      <p class="spec-name">Posisi Rumah</p>
                      <p class="spec-value">
                        {{ propDetail.posisi_rumah || "-" }}
                      </p>
                    </div>

                    <div class="spec-group">
                      <p class="spec-name">Garasi dan Carport</p>
                      <p class="spec-value">
                        {{ propDetail.garasi_dan_carport || "-" }}
                      </p>
                    </div>

                    <div class="spec-group">
                      <p class="spec-name">Kondisi Bangunan</p>
                      <p class="spec-value">
                        {{ propDetail.kondisi_bangunan || "-" }}
                      </p>
                    </div>

                    <!-- Additional fields -->
                    <div class="spec-group">
                      <p class="spec-name">Hadap</p>
                      <p class="spec-value">{{ propDetail.hadap || "-" }}</p>
                    </div>

                    <div class="spec-group">
                      <p class="spec-name">Garasi</p>
                      <p class="spec-value">{{ propDetail.garasi || "-" }}</p>
                    </div>

                    <div class="spec-group">
                      <p class="spec-name">Carport</p>
                      <p class="spec-value">{{ propDetail.carport || "-" }}</p>
                    </div>

                    <div class="spec-group">
                      <p class="spec-name">Lebar Depan Bangunan</p>
                      <p class="spec-value">
                        {{ propDetail.lebar_depan_bangunan || "-" }}
                      </p>
                    </div>

                    <div class="spec-group">
                      <p class="spec-name">Jumlah Lantai</p>
                      <p class="spec-value">
                        {{ propDetail.jumlah_lantai || "-" }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="description">
                  <p class="desc-title">Deskripsi</p>
                  <p class="desc-content">
                    {{ propDetail.deskripsi }}
                  </p>
                </div>
              </div>
            </div>
            <div class="agent-info">
              <div class="agent">
                <v-img
                  aspect-ratio="1/1"
                  src="@/assets/user-big.png"
                  class="agent-pic"
                  cover
                  width="50px"
                  max-width="50px"
                  height="50px"
                  v-if="propDetailCreator.image_url == 'default/profile.png'"
                ></v-img>
                <v-img
                  aspect-ratio="1/1"
                  :src="`${this.$apiImg}${propDetailCreator.image_url}`"
                  class="agent-pic"
                  cover
                  width="50px"
                  max-width="50px"
                  height="50px"
                  v-else
                ></v-img>
                <div class="detail">
                  <p class="name">
                    {{ propDetailCreator.name }}
                  </p>
                  <router-link
                    :to="`/agents/${propDetail.agent_id}`"
                    class="to-agent"
                    >Lihat Listing</router-link
                  >
                </div>
              </div>

              <a
                :href="whatsAppLink"
                class="wa-link"
                target="_blank
              "
              >
                <v-img
                  src="@/assets/whatsapp-logo.png"
                  width="25px"
                  height="25px"
                  max-width="25px"
                ></v-img>
                WhatsApp
              </a>
            </div>
          </div>
        </v-row>
      </v-container>
    </section>

    <section class="galery-pop-up" v-if="showGalleryPopup">
      <v-container>
        <v-row>
          <div class="wrapper">
            <v-img
              class="close-pop-up"
              src="@/assets/close-btn.png"
              @click="toggleGalleryPopup"
            ></v-img>
            <v-carousel
              hide-delimiters
              color="orange-lighten-1"
              class="carousel"
              v-model="currentSlide"
            >
              <v-carousel-item
                v-for="(image, index) in propDetail.images"
                :key="image.id"
                class="slider-item"
              >
                <div class="carousel-delimiter">
                  {{ index + 1 }} / {{ propDetail.images.length }}
                </div>
                <v-btn variant="flat" class="btn-share" @click="sharePropPopup">
                  <v-img
                    src="@/assets/share.png"
                    width="13px"
                    height="13px"
                    max-width="13px"
                    class="image-share"
                    cover
                  ></v-img>
                  <p>Share</p>
                </v-btn>
                <v-img
                  :src="`${this.$apiImg}${image.image_url}`"
                  contain
                  min-height="100%"
                ></v-img>
              </v-carousel-item>
            </v-carousel>
          </div>
        </v-row>
      </v-container>
    </section>

    <section class="pop-up-share" v-if="showSharePopup">
      <v-container>
        <v-row>
          <div class="wrapper">
            <v-img
              class="close-pop-up"
              src="@/assets/close-btn.png"
              @click="sharePropPopup"
            ></v-img>
            <h4>Bagikan Properti di Sosial<br />Mediamu</h4>
            <div class="socmed-wrapper">
              <a @click="shareImageAsset" class="group instagram">
                <v-img
                  src="@/assets/instagram-colored.png"
                  class="socmed-icon"
                ></v-img>
              </a>
              <a
                :href="`https://twitter.com/intent/tweet?text=Default+share+text&url=${currLink}`"
                class="group twitter"
                target="_blank"
              >
                <v-img src="@/assets/twitter.png" class="socmed-icon"></v-img>
              </a>
              <a
                :href="`https://wa.me/?text=${currLink}`"
                class="group whatsapp"
                target="_blank"
              >
                <v-img
                  src="@/assets/whatsapp-logo.png"
                  class="socmed-icon"
                ></v-img>
              </a>
              <a
                :href="`https://www.facebook.com/sharer/sharer.php?u=${currLink}`"
                class="group fb"
                target="_blank"
              >
                <v-img
                  src="@/assets/facebook-colored.png"
                  class="socmed-icon"
                ></v-img>
              </a>
              <!-- <a href="#" class="group">
                <v-img src="@/assets/tiktok.png" class="socmed-icon"></v-img>
              </a> -->
            </div>
            <div class="input-contain">
              <p>atau salin tautan</p>
              <v-text-field
                class="input-template"
                v-model="link"
                density="compact"
                variant="outlined"
                base-color="#818A91"
                bg-color="#fff"
                color="#363636"
                append-inner-icon="mdi-content-copy"
                @click:append-inner="copyLinkToClipboard"
                :rules="[rules.copy]"
              ></v-text-field>
            </div>
          </div>
        </v-row>
      </v-container>
    </section>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      propImg: 3,
      showGalleryPopup: false,
      showSharePopup: false,
      link: "https://hepiproperty.com/property-dijual/",
      rules: {
        copy: (copied) => (copied ? "Tersalin." : true),
      },
      propDetail: [],
      propDetailCreator: [],
      imgLen: 0,
      currentSlide: 0,
      currLink: null,
    };
  },
  methods: {
    toggleGalleryPopup() {
      this.showGalleryPopup = !this.showGalleryPopup;
    },
    sharePropPopup() {
      this.showSharePopup = !this.showSharePopup;
    },
    async copyLinkToClipboard() {
      try {
        await navigator.clipboard.writeText(this.link);
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 2000);
      } catch (error) {
        console.error("Error copying to clipboard:", error);
      }
    },
    async getPropDetail() {
      try {
        const res = await axios.get(
          `${this.$api}/property/detail/slug/` + this.$route.params.slug
        );
        this.propDetail = res.data.data;
        this.propDetailCreator = res.data.data.creator;
        if (res && res.data && res.data.data && res.data.data.images) {
          this.imgLen = res.data.data.images.length;
          console.log(this.imgLen);
        } else {
          console.error("Images property is undefined or null");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async shareImageAsset() {
      if (!this.imgLen) {
        console.error("First image is not available");
        return false;
      }

      try {
        // Get the image URL directly from the property details
        const imageUrl = `${this.$apiImg}${this.propDetail.images[0].image_url}`;

        // Fetch the image blob directly
        const response = await fetch(imageUrl);
        const blob = await response.blob();

        const filesArray = [
          new File([blob], `${this.propDetail.title}.png`, {
            type: "image/png",
            lastModified: new Date().getTime(),
          }),
        ];

        const shareData = {
          title: this.propDetail.title,
          files: filesArray,
          text: `Check out this property: ${window.location.href}`,
        };

        if (navigator.canShare && navigator.canShare(shareData)) {
          await navigator.share(shareData);
          return true;
        } else {
          console.error("Sharing not supported");
          return false;
        }
      } catch (error) {
        console.error("Error sharing image asset:", error);
        return false;
      }
    },
    formatPrice(value) {
      const formattedValue = (value / 1).toFixed(0).replace(".", ",");
      return formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async whatsAppLink() {
      const phoneNumber = this.propDetailCreator.phone_number;
      const currentUrl = encodeURIComponent(window.location.href);
      this.currLink = currentUrl;
      this.link = window.location.href;
      const message = `Halo, saya ingin bertanya tentang properti ini ${currentUrl}`;
      return `https://wa.me/${phoneNumber}?text=${message}`;
    },
  },
  async mounted() {
    await this.getPropDetail();
    await this.whatsAppLink();
  },
};
</script>

<style lang="scss" scoped>
section {
  padding: 50px 0;
  &.detail {
    padding-top: 0;
    .image-galery {
      padding: 2rem 0;
      width: 100%;
      display: flex;
      gap: 20px;

      &.desktop {
        display: flex;
        justify-content: center;

        .prop-img-inav {
          align-items: center;
        }
      }

      &.mobile {
        display: none;
      }

      .left-img {
        position: relative;
        width: 75%;
        display: flex;
        justify-content: center;
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        .prop-img {
          z-index: 1;
          border-radius: 5px;
        }
        .btn-share {
          position: absolute;
          z-index: 2;
          top: 20px;
          right: 20px;
          background-color: $button-color;
          color: $white;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          gap: 5px;
        }

        .image-share {
          margin-right: 5px;
        }
      }

      .right-img {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 25%;

        .right-top {
          display: flex;
          justify-content: center;
          box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
        }

        .prop-img {
          border-radius: 5px;
        }
      }

      .more-image {
        position: relative;
        display: flex;
        justify-content: center;
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);

        cursor: pointer;

        .see-more {
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.25);
          z-index: 2;
          display: flex;
          align-items: center;
          box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          justify-content: center;
          gap: 10px;
          flex-direction: column;

          p {
            font-weight: $font-weight-medium;
            color: $white;
          }
        }
      }
    }

    .property-detail {
      display: flex;
      gap: 50px;
      width: 100%;

      .prop-info {
        width: 65%;

        .general-info {
          display: flex;
          flex-direction: column;
          gap: 20px;
          padding-bottom: 15px;
          border-bottom: 2.5px solid $roman-silver;
          padding-bottom: 2.5rem;
          border-bottom: 1.5px solid $roman-silver;

          .price {
            letter-spacing: 2.5px;
            font-weight: $font-weight-semibold;
            color: $font-color;
          }

          .prop-status {
            display: flex;
            gap: 10px;

            .status {
              padding: 5px 15px;
              background-color: $primary-color;
              border-radius: 4px;

              p {
                font-size: 13px;
                font-weight: $font-weight-medium;
                text-transform: uppercase;
              }
            }
          }

          .prop-name {
            font-weight: $font-weight-medium;
            color: $font-color;
            letter-spacing: 1.5px;
          }

          .location {
            display: flex;
            gap: 10px;
            width: fit-content;
            align-items: center;

            p {
              font-size: 18px;
            }
          }
        }

        .detailed-info {
          display: flex;
          flex-direction: column;
          gap: 30px;

          h4 {
            font-size: 25px;
            margin-top: 2rem;
          }

          .specification {
            padding-bottom: 30px;
            border-bottom: 1px solid $roman-silver;

            .title {
              color: $granite-gray;
              font-size: 18px;
              font-weight: $font-weight-medium;
              margin-bottom: 15px;
            }

            .spec-list {
              display: flex;
              flex-wrap: wrap;
              gap: 20px;
              row-gap: 20px;

              .spec-group {
                display: flex;
                flex-direction: column;
                border-radius: 7px;
                gap: 5px;
                min-width: calc(50% - 20px);

                .spec-name {
                  font-size: 20px;
                  color: $dim-gray;
                }

                .spec-value {
                  font-size: 18px;
                  font-weight: $font-weight-medium;
                  letter-spacing: 0.5px;
                }
              }
            }
          }

          .description {
            .desc-title {
              color: $granite-gray;
              font-size: 18px;
              font-weight: $font-weight-medium;
              margin-bottom: 15px;
            }

            .desc-content {
              color: $granite-gray;
              font-size: 18px;
            }
          }
        }
      }

      .agent-info {
        width: 35%;
        display: flex;
        flex-direction: column;
        gap: 25px;
        border-radius: 5px;
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
        background-color: $white;
        height: fit-content;
        padding: 20px;

        .agent {
          display: flex;
          gap: 20px;

          .agent-pic {
            border-radius: 5px;
          }

          .detail {
            .name {
              font-weight: $font-weight-semibold;
            }

            .to-agent {
              font-size: 13px;
              color: $primary-color;
            }
          }
        }

        .wa-link {
          border: 1.5px solid #33bb47;
          border-radius: 5px;
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #33bb47;
          font-weight: $font-weight-medium;
          gap: 10px;
          padding: 7px;
          width: 100%;
          justify-content: center;
        }
      }
    }
  }

  &.search-result {
    .contain-title {
      margin-bottom: 2rem;
    }

    .wrapper-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;

      .property-card {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        width: calc(25% - 24px);
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
        background-color: $white;
        border-radius: 10px;

        .property-img {
          border-radius: 10px 10px 0 0;
        }

        .mid-section {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding: 20px 15px;

          .price {
            font-size: 20px;
            font-weight: $font-weight-semibold;
            color: $black;
          }

          .type {
            font-size: 12px;
            font-weight: $font-weight-semibold;
            text-transform: uppercase;
            color: $black;
          }

          .property-name {
            color: $black;
            font-weight: $font-weight-medium;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .location {
            display: flex;
            gap: 3px;
            align-items: center;
            color: $roman-silver;
            font-size: 12px;
            font-weight: $font-weight-medium;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .bottom-section {
          padding: 20px 15px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1.5px solid $gainsboro;

          .wrapper {
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: fit-content;

            .top-text {
              display: flex;
              gap: 10px;
              align-items: center;

              .qty {
                font-weight: $font-weight-semibold;
                font-size: 18px;
                color: $roman-silver;
                line-height: 18px;

                span {
                  font-size: 12px;
                }
              }
            }

            .title {
              color: $roman-silver;
              font-weight: $font-weight-medium;
              font-size: 9px;
            }
          }
        }

        .post-detail {
          display: flex;
          gap: 10px;
          padding: 20px 15px;
          align-items: center;
          .group {
            display: flex;
            align-items: center;

            p {
              font-size: 13px;
              color: $roman-silver;
            }
          }

          .poster {
            width: 55%;
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .posted-at {
            width: 45%;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  &.galery-pop-up {
    background: rgba(0, 0, 0, 0.3);
    height: 100vh;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 999;
    .wrapper {
      width: 100%;
      background-color: $white;
      border-radius: 10px;
      padding: 3rem;
      position: relative;

      .carousel {
        height: 500px !important;
      }

      .v-carousel-item {
        position: relative;
        width: 80%;
        margin: auto;
      }

      .v-btn {
        background-color: $button-color !important;
      }

      .close-pop-up {
        width: 30px;
        height: 30px;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 999;
      }

      .btn-share {
        position: absolute;
        z-index: 99999;
        top: 20px;
        right: 20px;
        background-color: $button-color; /* Replace with your desired button color */
        color: $white; /* Replace with your desired text color */
        text-transform: capitalize;
        display: flex;
        align-items: center;
        gap: 5px;

        .image-share {
          margin-right: 5px;
        }
      }

      .carousel-delimiter {
        padding: 10px 25px;
        border-radius: 50px;
        background-color: $gainsboro;
        width: fit-content;
        font-size: 13px;
        font-weight: $font-weight-medium;
        color: $roman-silver;
        position: absolute;
        z-index: 99999;
        bottom: 20px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
  }

  &.pop-up-share {
    background: rgba(0, 0, 0, 0.3);
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    .wrapper {
      width: 600px;
      margin: auto;
      background-color: $white;
      padding: 2rem;
      border-radius: 7px;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      position: relative;

      .close-pop-up {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 10px;
        right: 10px;
      }

      h4 {
        text-align: center;
        font-weight: $font-weight-semibold;
        color: $font-color;
      }

      .socmed-wrapper {
        display: flex;
        justify-content: space-between;

        .group {
          padding: 20px;
          border-radius: 50%;
          &.instagram {
            background: rgba(195, 57, 172, 0.1);
          }

          &.twitter {
            background: rgba(85, 172, 238, 0.1);
          }
          &.whatsapp {
            background: rgba(0, 230, 118, 0.1);
          }
          &.fb {
            background: rgba(25, 118, 210, 0.1);
          }

          .socmed-icon {
            width: 40px;
            height: 40px;
          }
        }
      }

      .input-contain {
        p {
          font-weight: $font-weight-medium;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 1266px) {
  section {
    &.search-result {
      .wrapper-list {
        gap: 2%;
        .property-card {
          width: 32%;
        }
      }
    }

    &.galery-pop-up {
      .wrapper {
        .carousel {
          height: 400px !important;
        }
      }
    }
  }
}

@media screen and (max-width: $bp-md) {
  section {
    &.detail {
      .image-galery,
      .property-detail {
        width: 97%;
        margin: auto;
      }

      .image-galery {
        &.desktop {
          display: none;
        }

        &.mobile {
          display: flex;
          flex-direction: column;

          .left-img {
            width: 100%;

            .prop-img {
              width: 100%;
              height: 350px !important;
              min-height: 350px !important;
            }
          }

          .btn-share {
            top: 10px;
            right: 10px;
            width: 40px !important;
            min-width: 40px !important;
            height: 40px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0;
            border: none;

            .image-share {
              margin: 0;
            }
          }

          .right-img {
            display: flex;
            width: 100%;
            flex-direction: row; /* Horizontal layout */
            gap: 4%;

            .prop-img {
              box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
            }

            .prop-img,
            .more-image {
              height: 150px !important;
              width: 31%;
            }

            .more-image {
              .prop-img {
                width: 100%;
                box-shadow: none;
              }
            }
          }

          .see-more {
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
      }

      .property-detail {
        flex-direction: column;

        .prop-info,
        .agent-info {
          width: 100%;
        }
      }
    }

    &.search-result {
      .contain-title {
        width: 95%;
        display: flex;
        margin-bottom: 2rem !important;
        margin: auto;
      }
      .wrapper-list {
        width: 95%;
        margin: auto;
        gap: 4%;
        row-gap: 2rem;
        .property-card {
          width: 48%;
        }
      }
    }

    &.galery-pop-up {
      .wrapper {
        width: 90%;
        margin: auto;
        padding: 1rem;
        .carousel {
          height: 350px !important;
        }
      }
    }

    &.pop-up-share {
      .wrapper {
        width: 90%;
      }
    }
  }
}

@media screen and (max-width: $bp-sm) {
  section {
    &.detail {
      .image-galery {
        &.mobile {
          .left-img {
            .prop-img {
              height: 230px !important;
              min-height: 230px !important;
            }
          }

          .btn-share {
            width: fit-content;
          }

          .right-img {
            .prop-img,
            .more-image {
              height: 100px !important;
            }
          }
        }
      }

      .property-detail {
        flex-direction: column;

        .prop-info,
        .agent-info {
          width: 100%;
        }

        .prop-info {
          gap: 10px;

          .general-info {
            .price,
            .prop-name {
              font-size: 18px;
              letter-spacing: 1px;
            }

            .prop-status {
              .status {
                p {
                  font-size: 10px;
                }
              }
            }

            .location {
              align-items: flex-start;

              p {
                font-size: 16px;
              }
            }
          }

          .detailed-info {
            h4 {
              font-size: 15px;
            }

            .specification {
              .title {
                font-size: 15px;
              }

              .spec-list {
                gap: 10px;
                row-gap: 10px;
                .spec-group {
                  .spec-name {
                    font-size: 15px;
                  }

                  .spec-value {
                    font-size: 16px;
                  }
                }
              }
            }

            .description {
              .desc-title {
                font-size: 15px;
              }

              .desc-content {
                font-size: 13px;
              }
            }
          }
        }
      }
    }

    &.pop-up-share {
      .wrapper {
        width: 90%;
        padding: 1rem;

        .close-pop-up {
          width: 20px;
          height: 20px;
        }

        h4 {
          font-size: 16px;
        }

        .socmed-wrapper {
          display: flex;
          justify-content: space-between;

          .group {
            padding: 10px;
            border-radius: 50%;

            .socmed-icon {
              width: 20px;
              height: 20px;
            }
          }
        }

        .input-contain {
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $bp-xs) {
  section {
    &.search-result {
      padding-top: 0;
      .wrapper-list {
        width: 95%;
        margin: auto;
        row-gap: 2rem;
        .property-card {
          width: 100%;
        }
      }
    }

    &.galery-pop-up {
      .wrapper {
        width: 90%;
        margin: auto;
        padding: 1rem;
        .carousel {
          height: 200px !important;
        }

        .v-carousel-item {
          width: 90%;
        }

        .close-pop-up {
          width: 20px;
          height: 20px;
        }

        .btn-share {
          padding: 0 5px 0 13px;
          width: fit-content;
          min-width: unset !important;
          top: 10px;
          right: 10px;
          p {
            display: none;
          }
        }

        .carousel-delimiter {
          padding: 5px 10px;
          font-size: 12px;
          bottom: 10px;
        }
      }
    }
  }
}
</style>
